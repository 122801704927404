import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

// Actions
export const constants = {
  SHOW_MODAL: 'Modal/SHOW_MODAL',
  CLOSE_MODAL: 'Modal/CLOSE_MODAL'
};

export const showModal = createAction(constants.SHOW_MODAL);
export const closeModal = createAction(constants.CLOSE_MODAL);

const initialState = fromJS({
  editing: null
});

// Reducers
const modalReducer = handleActions(
  {
    [showModal]: (state, action) => state.set('editing', action.payload),
    [closeModal]: state => state.set('editing', null)
  },
  initialState
);

export default modalReducer;
