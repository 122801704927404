import React from 'react';
import PropTypes from 'prop-types';

// Redux Form
import { Field, reduxForm } from 'redux-form/immutable';

// Components
import { renderTextField } from '../../components/Form/helpers';
import UVCButton from '../../components/Buttons/UVCButton';
import FormActions from '../../components/Auth/FormActions';

// Material UI
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
});

const ResetPasswordForm = ({
  handleSubmit,
  submitting,
  submitted,
  classes
}) => (
  <form onSubmit={handleSubmit} className={classes.form}>
    <Field
      autoFocus
      margin="normal"
      id="password"
      name="password"
      label="Password"
      type="password"
      required
      fullWidth
      component={renderTextField}
    />
    <Field
      margin="normal"
      id="password_confirmation"
      name="password_confirmation"
      label="Password Confirmation"
      type="password"
      required
      fullWidth
      component={renderTextField}
    />
    <FormActions submitting={submitting} centered>
      <UVCButton
        id="login-with-new-password"
        type="submit"
        text="Log In"
        fullWidth
        disabled={submitted}
      />
    </FormActions>
  </form>
);

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  submitted: PropTypes.bool,
  classes: PropTypes.object
};

export default withStyles(styles)(reduxForm({})(ResetPasswordForm));
