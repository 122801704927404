import {
  takeLatest,
  fork,
  put,
  select,
  call,
  takeEvery
} from 'redux-saga/effects';

// Selectors
import { getCurrentDepartmentId } from '../Departments/selectors';

// Reducer Actions
import {
  updateFrequency,
  clearForm,
  deactivateEquipmentTypeRequest,
  deactivateEquipmentTypeFailure
} from './reducer';
import { fetchDepartmentRequest } from '../Departments/reducer';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';
import { closeModal } from '../../components/Modal/reducer';

// Services
import {
  updateProtocolFrequency,
  deactivateEquipmentType
} from '../../services/protocols';

// Redux Form
import { SubmissionError } from 'redux-form/immutable';

function* resetProtocolFormSaga() {
  yield takeLatest('@@redux-form/DESTROY', handleResetProtocolFormSaga);
}

function* handleResetProtocolFormSaga() {
  yield put(clearForm());
}

function* updateFrequencySaga() {
  yield takeLatest(updateFrequency.REQUEST, handleUpdateFrequencySaga);
}

function* handleUpdateFrequencySaga(action) {
  const departmentId = yield select(getCurrentDepartmentId);

  try {
    const { result, error } = yield call(
      updateProtocolFrequency,
      departmentId,
      action.payload
    );

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(updateFrequency.failure(fieldErrors));
    } else {
      yield put(closeModal());
      yield put(fetchDepartmentRequest(departmentId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Protocol updated successfully'
        })
      );
    }
  } finally {
  }
}

function* deactivateEquipmentTypeSaga() {
  yield takeEvery(
    deactivateEquipmentTypeRequest,
    handleDeactivateEquipmentTypeSaga
  );
}

function* handleDeactivateEquipmentTypeSaga(action) {
  const departmentId = yield select(getCurrentDepartmentId);

  try {
    const { result, error } = yield call(
      deactivateEquipmentType,
      departmentId,
      action.payload
    );

    if (error) {
      console.log(error);
      let message = 'Equipment Type ' + error['equipment_type'];
      yield put(
        showSnackbar({
          variant: 'error',
          message: message
        })
      );
      yield put(deactivateEquipmentTypeFailure(action.payload));
    } else {
      yield put(fetchDepartmentRequest(departmentId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Equipment Type Deactivated'
        })
      );
    }
  } finally {
  }
}

function* sagas() {
  yield fork(resetProtocolFormSaga);
  yield fork(updateFrequencySaga);
  yield fork(deactivateEquipmentTypeSaga);
}

export default sagas;
