import { takeLatest, fork, call, select, put } from 'redux-saga/effects';

// Actions
import { updateProfile } from './reducer';
import { updateProfileRequest } from '../../services/profiles';
import { closeModal } from '../../components/Modal/reducer';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';
import { verifyToken } from '../Auth/reducer';

// Selectors
import { getCurrentUserProfile } from './selectors';

// Redux Form
import { SubmissionError } from 'redux-form';

function* updateProfileSaga() {
  yield takeLatest(updateProfile.REQUEST, handleUpdateProfileSaga);
}

function* handleUpdateProfileSaga(action) {
  const userProfile = yield select(getCurrentUserProfile);
  const profileId = userProfile.get('id');

  try {
    const { error } = yield call(
      updateProfileRequest,
      profileId,
      action.payload
    );

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(updateProfile.failure(fieldErrors));
    } else {
      yield put(verifyToken());
      yield put(closeModal());
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Profile updated successfully.'
        })
      );
    }
  } finally {
  }
}

function* sagas() {
  yield fork(updateProfileSaga);
}

export default sagas;
