import React from 'react';
import PropTypes from 'prop-types';

// Router
import { history } from '../../store';

// Redux Form
import { Field, reduxForm } from 'redux-form/immutable';

// Components
import { renderTextField } from '../../components/Form/helpers';
import CancelButton from '../../components/Buttons/CancelButton';
import UVCButton from '../../components/Buttons/UVCButton';
import FormActions from '../../components/Auth/FormActions';

// MUI
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  error: {
    fontSize: '1.4rem',
    marginTop: theme.spacing(1),
    color: theme.palette.error.dark
  }
});

const ResetPasswordRequestForm = ({
  handleSubmit,
  submitting,
  submitted,
  classes,
  error
}) => (
  <form onSubmit={handleSubmit} className={classes.form}>
    <Field
      autoFocus
      margin="normal"
      id="email"
      name="email"
      type="email"
      label="Email"
      required
      fullWidth
      component={renderTextField}
    />
    {error && <strong className={classes.error}>{error.join(', ')}</strong>}
    <FormActions submitting={submitting}>
      <React.Fragment>
        <CancelButton
          id="cancel-password-reset-request"
          onClick={() => {
            history.push('/');
          }}
        />
        <UVCButton
          id="submit-password-reset-request"
          type="submit"
          disabled={submitted}
          text="Reset Password"
        />
      </React.Fragment>
    </FormActions>
  </form>
);

ResetPasswordRequestForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({ form: 'resetPasswordRequestForm' })(
  withStyles(styles)(ResetPasswordRequestForm)
);
