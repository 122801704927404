import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Actions
import { removeEquipmentItemRequest } from './reducer';

// Selectors
import { isAttemptingDeactivation } from './selectors';

// Material UI Components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

class EquipmentItem extends React.Component {
  render() {
    const { equipmentItem, removeItem, isDeactivating } = this.props;
    const equipmentType = equipmentItem.get('equipment_type');
    const equipmentItemId = equipmentItem.get('id');
    const tagId = equipmentItem.get('tag_id');
    const nextDisinfectionDate = equipmentItem.get('next_disinfection_date');
    const equipmentId = equipmentItem.get('equipment_id');

    return (
      <TableRow>
        <TableCell>{equipmentType}</TableCell>
        <TableCell>{tagId}</TableCell>
        <TableCell>{equipmentId}</TableCell>
        <TableCell>{nextDisinfectionDate}</TableCell>
        <TableCell>
          {isDeactivating && <CircularProgress />}
          {!isDeactivating && (
            <IconButton
              id={`remove-equipment-item-${equipmentItemId}`}
              className="remove-equipment-item-icon-button"
              onClick={() => removeItem(equipmentItemId, tagId)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

EquipmentItem.propTypes = {
  equipmentItem: ImmutablePropTypes.map.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  isDeactivating: isAttemptingDeactivation(
    state,
    ownProps.equipmentItem.get('id')
  )
});

const mapDispatchToProps = dispatch => ({
  removeItem: (id, tagId) => {
    let confirmation = window.confirm(
      `Deactivate equipment item with tag ${tagId}?`
    );
    if (confirmation) {
      dispatch(removeEquipmentItemRequest(id));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentItem);
