import React from 'react';

// Redux
import { connect } from 'react-redux';

// Selectors
import { getUserFacilityIds } from '../Auth/selectors';

// Components
import SectionHeader from '../../components/SectionHeader';
import { Usage } from '../../components/Charts';

const Dashboard = ({ facilityIds }) => {
  return (
    <div>
      <SectionHeader title="Dashboard" type="h1" />
      <Usage facilityIds={facilityIds} />
      {/* <Compliance /> */}
      {/* <Frequency /> */}
    </div>
  );
};

const mapStateToProps = state => ({
  facilityIds: getUserFacilityIds(state)
});

export default connect(mapStateToProps)(Dashboard);
