import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { renderTextField } from '../../components/Form/helpers';

// Router
import { Link as RouterLink } from 'react-router-dom';

// Redux Form
import { Field, reduxForm } from 'redux-form/immutable';

// Actions
import { login } from './reducer';

// Components
import UVCButton from '../../components/Buttons/UVCButton';
import FormActions from '../../components/Auth/FormActions';

// MUI Components
import { withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  error: {
    fontSize: '1.4rem',
    marginTop: theme.spacing(1),
    color: theme.palette.error.dark
  },
  rightLink: {
    fontSize: '1.4rem',
    marginTop: theme.spacing(2),
    float: 'right'
  }
});

class LoginForm extends React.Component {
  render() {
    const { classes, handleSubmit, submitting, submitted, error } = this.props;

    return (
      <form className={classes.form} onSubmit={handleSubmit(login)}>
        <Field
          required
          margin="normal"
          id="email"
          name="email"
          label="Email"
          fullWidth
          autoComplete="email"
          autoFocus
          component={renderTextField}
        />
        <Field
          required
          margin="normal"
          id="password"
          name="password"
          type="password"
          label="Password"
          fullWidth
          autoComplete="current-password"
          component={renderTextField}
        />
        {!submitting && error && (
          <strong className={classes.error}>{error.join(', ')}</strong>
        )}
        <FormActions submitting={submitting} centered>
          <UVCButton
            id="login"
            type="submit"
            text="Log In"
            fullWidth
            disabled={submitted}
          />
        </FormActions>
        <div className={classes.rightLink}>
          <Link component={RouterLink} to="/reset_password_request">
            Forgot Password?
          </Link>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  form: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default reduxForm({})(withStyles(styles)(LoginForm));
