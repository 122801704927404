import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  panelListContainer: {
    padding: '1rem 2.4rem'
  },
  heading: {
    fontSize: '1.2rem'
  }
});

const PanelListHeaders = ({ classes }) => (
  <div className={classes.panelListContainer}>
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <Typography color="textSecondary" className={classes.heading}>
          Active Error
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography color="textSecondary" className={classes.heading}>
          Nickname
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color="textSecondary" className={classes.heading}>
          UDI
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color="textSecondary" className={classes.heading}>
          Location
        </Typography>
      </Grid>
    </Grid>
  </div>
);

PanelListHeaders.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PanelListHeaders);
