import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  subheaderInfo: {
    marginBottom: theme.spacing(3)
  }
});

const SubheaderInfo = ({ text, classes }) => (
  <Typography className={classes.subheaderInfo}>{text}</Typography>
);

SubheaderInfo.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SubheaderInfo);
