// Material UI
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const WordWrapTooltip = withStyles(theme => ({
  tooltip: {
    wordBreak: 'break-word'
  }
}))(Tooltip);

export default WordWrapTooltip;
