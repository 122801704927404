import {
  takeLatest,
  fork,
  call,
  put,
  select,
  takeEvery,
  throttle,
  take,
  cancel
} from 'redux-saga/effects';

// Selectors
import { getCurrentDepartmentId } from '../Departments/selectors';
import { getSelectedProtocolId, getProtocolById } from '../Protocols/selectors';

// Services
import { createProtocol, updateProtocol } from '../../services/protocols';
import { searchEquipmentItems } from '../../services/equipmentItems';

import { deactivateEquipmentItem } from '../../services/equipmentItems';

// Reducer actions
import {
  createEquipmentItem,
  removeEquipmentItemRequest,
  removeEquipmentItemSuccess,
  removeEquipmentItemFailure,
  searchEquipmentItemsReset,
  searchEquipmentItemsRequest,
  searchEquipmentItemsSuccess,
  searchEquipmentItemsFailure
} from './reducer';
import { closeModal } from '../../components/Modal/reducer';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';
import { fetchDepartmentRequest } from '../Departments/reducer';
import { removeEquipmentItemAtDepartment } from '../Departments/reducer';

// Redux Form
import { SubmissionError } from 'redux-form';

function* createEquipmentItemSaga() {
  yield takeLatest(createEquipmentItem.REQUEST, handleCreateEquipmentItemSaga);
}

function* handleCreateEquipmentItemSaga(action) {
  const protocolId = yield select(getSelectedProtocolId);
  const protocol = yield select(getProtocolById, protocolId);

  if (protocol) {
    yield call(handleUpdateProtocolSaga, action, protocol.get('id'));
  } else {
    yield call(handleCreateProtocolWithEquipmentSaga, action);
  }
}

function* handleCreateProtocolWithEquipmentSaga(action) {
  const departmentId = yield select(getCurrentDepartmentId);

  try {
    const { result, error } = yield call(
      createProtocol,
      departmentId,
      action.payload
    );

    if (error) {
      const cleanedErrors = castProtocolErrors(error);
      const fieldErrors = new SubmissionError(cleanedErrors);
      yield put(createEquipmentItem.failure(fieldErrors));
    } else {
      yield put(closeModal());
      yield put(fetchDepartmentRequest(departmentId));
      yield put(searchEquipmentItemsReset());
      let itemsCreated = result.length;
      const pluralizedItem = itemsCreated === 1 ? 'Item' : 'Items';
      const message = `${itemsCreated} ${pluralizedItem} added to department.`;
      yield put(
        showSnackbar({
          variant: 'success',
          message: message
        })
      );
    }
  } finally {
  }
}

function* handleUpdateProtocolSaga(action, protocolId) {
  const departmentId = yield select(getCurrentDepartmentId);

  try {
    const { result, error } = yield call(
      updateProtocol,
      departmentId,
      protocolId,
      action.payload
    );

    if (error) {
      const cleanedErrors = castProtocolErrors(error);
      const fieldErrors = new SubmissionError(cleanedErrors);
      yield put(createEquipmentItem.failure(fieldErrors));
    } else {
      yield put(closeModal());
      yield put(fetchDepartmentRequest(departmentId));
      yield put(searchEquipmentItemsReset());
      let itemsCreated = result.length;
      const pluralizedItem = itemsCreated === 1 ? 'Item' : 'Items';
      const message = `${itemsCreated} ${pluralizedItem} added to department.`;
      yield put(
        showSnackbar({
          variant: 'success',
          message: message
        })
      );
    }
  } finally {
  }
}

function castProtocolErrors(error) {
  for (const [key, value] of Object.entries(error)) {
    if (key.match(/.\../g)) {
      let keys = key.split('.');
      error[keys[0]] = {};
      error[keys[0]][keys[1]] = value;
    }
  }

  error['_error'] = error['base'];
  return error;
}

function* deactivateEquipmentItemSaga() {
  yield takeEvery(
    removeEquipmentItemRequest,
    handleDeactivateEquipmentItemSaga
  );
}

function* handleDeactivateEquipmentItemSaga(action) {
  const departmentId = yield select(getCurrentDepartmentId);

  try {
    const { error } = yield call(
      deactivateEquipmentItem,
      departmentId,
      action.payload
    );

    if (error) {
      yield put(
        showSnackbar({
          variant: 'error',
          message: 'Could not remove equipment item'
        })
      );
      yield put(removeEquipmentItemFailure(action.payload));
    } else {
      yield put(removeEquipmentItemSuccess(action.payload));
      yield put(
        removeEquipmentItemAtDepartment({
          departmentId: departmentId,
          equipmentItemId: action.payload
        })
      );
    }
  } finally {
  }
}

function* searchEquipmentItemsSaga() {
  yield throttle(
    500,
    searchEquipmentItemsRequest,
    handleSearchEquipmentItemsSaga
  );
}

function* handleSearchEquipmentItemsSaga(action) {
  const departmentId = yield select(getCurrentDepartmentId);

  if (action.payload === '' || departmentId === null) {
    yield put(searchEquipmentItemsReset());
  } else {
    const searchRequest = yield fork(
      fetchResults,
      departmentId,
      action.payload
    );

    // Cancel search on reset
    yield take(searchEquipmentItemsReset);
    yield cancel(searchRequest);
  }
}

function* fetchResults(departmentId, payload) {
  try {
    const { result, error } = yield call(
      searchEquipmentItems,
      departmentId,
      payload
    );

    if (error) {
      yield put(searchEquipmentItemsFailure(error));
    } else {
      yield put(searchEquipmentItemsSuccess(result));
    }
  } finally {
  }
}

function* sagas() {
  yield fork(createEquipmentItemSaga);
  yield fork(deactivateEquipmentItemSaga);
  yield fork(searchEquipmentItemsSaga);
}

export default sagas;
