import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import PaperSection from '../../PaperSection';

// Containers
import Enclosures from '../../../containers/Enclosures';

const EnclosuresSection = ({ facility }) => (
  <PaperSection title="UV Enclosures" id="enclosures">
    <Enclosures
      facilityId={facility.get('id')}
      enclosureIds={facility.get('enclosures')}
    />
  </PaperSection>
);

EnclosuresSection.propTypes = {
  facility: ImmutablePropTypes.map.isRequired
};

export default EnclosuresSection;
