import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// MUI components
import Dialog from '@material-ui/core/Dialog';

// Actions
import { closeModal } from './reducer';

class Modal extends React.Component {
  componentWillUnmount() {
    this.props.closeModal();
  }

  render() {
    const { isOpen, closeModal } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
      >
        {this.props.render({ handleClose: closeModal })}
      </Dialog>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  render: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
  isOpen: state.modalReducer.get('editing') === ownProps.name
});

export default connect(
  mapStateToProps,
  { closeModal }
)(Modal);
