import React from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '../Modal';
import FormDialog from '../../components/FormDialog';
import OperatorUploadFields from './UploadFields';

const UploadModal = ({ submitAction }) => (
  <Modal
    name="UploadOperatorsModal"
    render={() => (
      <FormDialog
        title="Upload Operators"
        buttonText="Upload"
        form="UploadOperatorsForm"
        onSubmit={submitAction}
      >
        <OperatorUploadFields />
      </FormDialog>
    )}
  />
);

UploadModal.propTypes = {
  submitAction: PropTypes.func.isRequired
};

export default UploadModal;
