import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import { Field } from 'redux-form/immutable';
import SubheaderInfo from '../../components/SubheaderInfo';

// Helpers
import { renderTextField } from '../../components/Form/helpers';

// Material UI Components
import { withStyles, Grid, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = theme => ({
  formRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textField: {
    maxWidth: '16rem'
  },
  helperText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontStyle: 'italic'
  }
});

const FrequencyFields = ({ equipmentType, classes }) => (
  <div>
    <SubheaderInfo text="Updating the frequency of an equipment type will effect all associated equipment, effective on the next cycle." />
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Field
          id="equipment_type_name"
          name="equipment_type_name"
          label="Equipment Type"
          required
          fullWidth
          disabled={equipmentType.get('default') == true}
          component={renderTextField}
        />
        {equipmentType.get('default') === true && (
          <Typography className={classes.helperText}>
            Default Equipment Type names cannot be modified
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        <Field
          id="frequency"
          name="frequency"
          label="Cycle Frequency"
          required
          classes={classes}
          component={renderTextField}
          InputProps={{
            endAdornment: <InputAdornment position="end">Days</InputAdornment>
          }}
        />
      </Grid>
    </Grid>
  </div>
);

FrequencyFields.propTypes = {
  equipmentType: ImmutablePropTypes.map.isRequired
};

export default withStyles(styles)(FrequencyFields);
