import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Actions
import { showModal } from '../Modal/reducer';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// Components
import SectionHeader from '../SectionHeader';
import FacilityInfo from './FacilityInfo';
import Downloads from './Downloads';

// Selectors
import { isUVCAdmin } from '../../containers/Auth/selectors';
import { isReportGenerating } from '../../containers/Facilities/selectors';

const styles = theme => ({
  headerActions: {
    marginLeft: 'auto',
    display: 'flex',
    maxHeight: theme.spacing(8)
  },
  headerButton: {
    display: 'flex',
    alignSelf: 'center'
  },
  progressWrapper: {
    padding: theme.spacing(2)
  }
});

const Header = ({
  facility,
  classes,
  showModal,
  canEditFacility,
  generatingReport,
  modalName
}) => {
  return (
    <React.Fragment>
      <SectionHeader>
        <FacilityInfo facility={facility} />
        <div>
          {canEditFacility && (
            <Tooltip title="Edit Facility" enterDelay={250}>
              <IconButton
                id="edit-facility"
                aria-label="Edit Facility"
                onClick={() => showModal(modalName)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.headerActions}>
          {generatingReport && (
            <div className={classes.progressWrapper}>
              <CircularProgress size={30} />
            </div>
          )}
          <div className={classes.headerButton}>
            <Downloads />
          </div>
        </div>
      </SectionHeader>
    </React.Fragment>
  );
};

Header.propTypes = {
  facility: ImmutablePropTypes.map.isRequired,
  canEditFacility: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  modalName: PropTypes.string.isRequired,
  generatingReport: PropTypes.bool
};

const mapStateToProps = state => ({
  canEditFacility: isUVCAdmin(state),
  generatingReport: isReportGenerating(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Header));
