import Immutable from 'immutable';

import {
  getCurrentFacilityId,
  getCurrentFacility
} from '../Facilities/selectors';

const getFacilitiesReducer = state => state.facilitiesReducer;

export const getEnclosures = state =>
  getFacilitiesReducer(state).getIn(['entities', 'enclosures']);

export const getEnclosureIdsForCurrentFacility = state =>
  getCurrentFacility(state).get('enclosures');

export const getEnclosureById = (state, id) => getEnclosures(state).get(id);

export const getEnclosuresForFacility = (state, id) => {
  let enclosureIds = getFacilitiesReducer(state).getIn([
    'entities',
    'facilities',
    id.toString(),
    'enclosures'
  ]);

  let enclosures = getFacilitiesReducer(state).getIn([
    'entities',
    'enclosures'
  ]);

  if (enclosures === undefined) {
    enclosures = Immutable.Map();
  } else {
    enclosures = enclosures.filter(result =>
      enclosureIds.includes(result.get('id'))
    );
  }

  return enclosures;
};

export const getAvailableSoftwarePackages = state => {
  const currentFacilityId = getCurrentFacilityId(state);
  return getFacilitiesReducer(state).getIn([
    'entities',
    'facilities',
    currentFacilityId,
    'software_packages'
  ]);
};
