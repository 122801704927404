import React, { useState, useCallback } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import EnclosureSummaryGrid from '../../containers/EnclosureLogs/EnclosureSummaryGrid';
import LogsTable from './LogsTable';

// Api Requests
import fetchData from '../../services/apiRequest';

// Material UI
import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import {
  AccordionDetails,
  Grid,
  CircularProgress,
  Typography
} from '@material-ui/core';

const styles = theme => ({
  logsTableSection: {
    paddingLeft: '2rem',
    borderLeft: '4px solid',
    borderColor: theme.palette.grey.light
  },
  notConnected: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem'
  }
});

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    minHeight: '8rem',
    '&$expanded': {
      minHeight: '8rem'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      fontWeight: 'bold'
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      fontWeight: 'bold'
    },
    transition: 'font-weight: 0.25s, background: 0.25s'
  },
  expanded: {}
}))(MuiAccordionSummary);

function LogsPanel({ enclosure, classes }) {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  const loadLogs = useCallback(
    (e, expanded) => {
      if (expanded && loading) {
        const requestUrl = `enclosures/${enclosure.get(
          'id'
        )}/enclosure_logs?limit=100`;
        fetchData(requestUrl).then(response => {
          setLogs(response.result);
          setLoading(false);
        });
      }
    },
    [enclosure, loading]
  );

  return (
    <Accordion onChange={loadLogs}>
      <AccordionSummary>
        <EnclosureSummaryGrid enclosure={enclosure} />
      </AccordionSummary>
      <AccordionDetails>
        {enclosure.get('connection_status') === 'not_yet_connected' && (
          <div className={classes.notConnected}>
            <Typography variant="h3">Enclosure not yet connected</Typography>
          </div>
        )}
        {enclosure.get('connection_status') !== 'not_yet_connected' && (
          <Grid container spacing={1} justify="center">
            <Grid item xs={10}>
              <section className={classes.logsTableSection}>
                {loading && <CircularProgress />}
                {!loading && <LogsTable logs={logs} />}
              </section>
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

LogsPanel.propTypes = {
  enclosure: ImmutablePropTypes.map.isRequired
};

export default withStyles(styles)(LogsPanel);
