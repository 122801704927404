import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* searchEquipmentItems(departmentId, search) {
  let path = `departments/${departmentId}/equipment_items?search=${search}`;

  return yield call(fetchData, path, {
    method: 'GET'
  });
}

export function* deactivateEquipmentItem(departmentId, itemId) {
  let path = `departments/${departmentId}/equipment_items/${itemId}`;

  return yield call(fetchData, path, {
    method: 'PATCH',
    body: { active: false }
  });
}
