import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import WordWrapTooltip from '../Tooltips/WordWrapTooltip';

// Material UI
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  facilityInfo: {
    maxWidth: '50%'
  },
  truncateNoWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const fullAddress = facility => {
  return `${facility.get('city')} ${facility.get('state')} ${facility.get(
    'zip'
  )}`;
};

function FacilityInfo({ facility, classes }) {
  return (
    <div className={classes.facilityInfo}>
      <WordWrapTooltip title={facility.get('name')} enterDelay={250}>
        <Typography
          variant="h1"
          component="h1"
          className={classes.truncateNoWrap}
        >
          {facility.get('name')}
        </Typography>
      </WordWrapTooltip>
      <WordWrapTooltip title={facility.get('address_1')} enterDelay={250}>
        <Typography className={classes.truncateNoWrap}>
          {facility.get('address_1')} {facility.get('address_2')}
        </Typography>
      </WordWrapTooltip>
      <WordWrapTooltip title={fullAddress(facility)} enterDelay={250}>
        <Typography className={classes.truncateNoWrap}>
          {facility.get('city')} {facility.get('state')} {facility.get('zip')}
        </Typography>
      </WordWrapTooltip>
    </div>
  );
}

FacilityInfo.propTypes = {
  facility: ImmutablePropTypes.map.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FacilityInfo);
