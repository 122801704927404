import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Field } from 'redux-form/immutable';
import PasswordFields from './PasswordFields';
import ResetPassword from './ResetPassword';

// Selectors
import { isUVCAdmin } from '../../containers/Auth/selectors';
import { getAvailableSoftwarePackages } from './selectors';

// Helpers
import {
  renderTextField,
  renderSelectField,
  renderCheckbox
} from '../../components/Form/helpers';

// Material UI Components
import {
  withStyles,
  FormGroup,
  FormControlLabel,
  Grid,
  MenuItem
} from '@material-ui/core';

const configurationTypes = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 }
];

const styles = theme => ({
  textField: {},
  error: {
    color: theme.palette.error.main
  }
});

const EnclosureFields = ({
  classes,
  isUVCAdmin,
  newEnclosure,
  availableSoftwarePackages
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Field
        margin="dense"
        id="nickname"
        name="nickname"
        label="UVE Nickname"
        fullWidth
        autoFocus
        classes={classes}
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        margin="dense"
        id="udi"
        name="udi"
        label="UDI"
        disabled={!isUVCAdmin}
        required
        fullWidth
        classes={classes}
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        margin="dense"
        id="location"
        name="location"
        label="Location Description"
        classes={classes}
        multiline
        rowsMax={4}
        required
        fullWidth
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        margin="dense"
        id="configuration_type"
        name="configuration_type"
        label="Configuration Type"
        classes={classes}
        disabled={!isUVCAdmin}
        required
        fullWidth
        component={renderSelectField}
      >
        {configurationTypes.map(type => (
          <MenuItem key={type.value} value={type.value}>
            {type.name}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    {isUVCAdmin && newEnclosure && (
      <Grid item xs={6}>
        <Field
          margin="dense"
          id="enclosure_software_package_id"
          name="enclosure_software_package_id"
          label="Expected Software Version"
          classes={classes}
          disabled={!isUVCAdmin}
          required
          fullWidth
          format={(value, name) => (value == null ? ' ' : value)}
          component={renderSelectField}
        >
          <MenuItem value={' '}>None</MenuItem>
          {availableSoftwarePackages.map(software_package => (
            <MenuItem
              key={software_package.get('id')}
              value={software_package.get('id')}
            >
              {software_package.get('version')}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    )}
    {isUVCAdmin && (
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            label="Display Due Date on Indicator Tags"
            control={
              <Field
                id="display_due_date_on_tags"
                name="display_due_date_on_tags"
                component={renderCheckbox}
                type="checkbox"
              />
            }
          />
        </FormGroup>
      </Grid>
    )}
    <Grid item xs={12}>
      {isUVCAdmin && newEnclosure && <PasswordFields />}
      {isUVCAdmin && !newEnclosure && <ResetPassword />}
    </Grid>
  </Grid>
);

EnclosureFields.propTypes = {
  classes: PropTypes.object.isRequired,
  isUVCAdmin: PropTypes.bool.isRequired,
  newEnclosure: PropTypes.bool
};

const mapStateToProps = state => ({
  isUVCAdmin: isUVCAdmin(state),
  availableSoftwarePackages: getAvailableSoftwarePackages(state)
});

export default connect(mapStateToProps)(withStyles(styles)(EnclosureFields));
