import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Field } from 'redux-form/immutable';

// Helpers
import {
  renderTextField,
  renderSelectField
} from '../../components/Form/helpers';

// Selectors
import { getRoleOptions } from './selectors';

// Material UI components
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  textField: {
    minWidth: 350,
    margin: theme.spacing(1)
  },
  selectField: {
    minWidth: 150,
    margin: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.dark
  }
});

class UserFields extends React.Component {
  render() {
    const { classes, roles } = this.props;

    return (
      <React.Fragment>
        <Field
          autoFocus
          margin="dense"
          id="email"
          name="email"
          label="Email"
          type="email"
          classes={classes}
          required
          component={renderTextField}
        />
        <Field
          margin="dense"
          name="role_name"
          label="Role"
          classes={classes}
          required
          component={renderSelectField}
        >
          {roles.map(role => (
            <MenuItem key={role.value} value={role.value}>
              {role.name}
            </MenuItem>
          ))}
        </Field>
      </React.Fragment>
    );
  }
}

UserFields.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  roles: getRoleOptions(state)
});

export default connect(mapStateToProps)(withStyles(styles)(UserFields));
