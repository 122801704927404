import React from 'react';

// Material UI Components
import Link from '@material-ui/core/Link';

const OperatorsTemplateLink = () => (
  <Link href="/operators_csv_template.csv">Operators CSV Upload Template</Link>
);

export default OperatorsTemplateLink;
