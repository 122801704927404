import React, { useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Selectors
import {
  getFacilities,
  getLoadedFacilities,
  getLoadingFacilities
} from '../Facilities/selectors';

// Components
import FacilitySection from './FacilitySection';
import CenterLoadingIcon from '../../components/CenterLoadingIcon';

// Material UI
import Typography from '@material-ui/core/Typography';

// Actions
import { fetchFacilitiesRequest } from '../../containers/Facilities/reducer';

const EnclosureLogs = ({ loaded, loading, facilities, fetchFacilities }) => {
  useEffect(() => {
    fetchFacilities();
  }, []);

  return (
    <div>
      <Typography variant="h1" component="h1" gutterBottom>
        Enclosure Logs
      </Typography>
      {loading && <CenterLoadingIcon />}
      {loaded && facilities && (
        <React.Fragment>
          {facilities.valueSeq().map(facility => (
            <FacilitySection key={facility.get('id')} facility={facility} />
          ))}
        </React.Fragment>
      )}
    </div>
  );
};

EnclosureLogs.propTypes = {
  facilities: ImmutablePropTypes.map.isRequired
};

const mapStateToProps = state => ({
  facilities: getFacilities(state),
  loading: getLoadingFacilities(state),
  loaded: getLoadedFacilities(state)
});

const mapDispatchToProps = dispatch => ({
  fetchFacilities: () => dispatch(fetchFacilitiesRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(EnclosureLogs);
