import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

const headerKeys = ['access-token', 'client', 'expiry', 'token-type', 'uid'];

export function* authenticate(formData) {
  return yield call(fetchData, 'auth/sign_in', {
    withoutVersion: true,
    method: 'POST',
    body: formData
  });
}

export function* refreshToken() {
  return yield call(fetchData, 'auth/validate_token', {
    withoutVersion: true,
    method: 'GET'
  });
}

export function* confirmInvitation(data) {
  let user = data
    .set('profile_attributes', data.get('profile'))
    .delete('profile');

  return yield call(fetchData, `invitation`, {
    method: 'PUT',
    body: {
      user: user
    }
  });
}

export function* signOut() {
  return yield call(fetchData, 'auth/sign_out', {
    withoutVersion: true,
    method: 'DELETE',
    body: getAuthHeaders()
  });
}

export function getAuthHeaders() {
  return headerKeys.reduce(
    (map, obj) => ({
      ...map,
      [obj]: localStorage.getItem(obj)
    }),
    {}
  );
}

export function extractAuthHeaders(headers) {
  return headerKeys.reduce(
    (map, obj) => ({
      ...map,
      [obj]: headers.get(obj)
    }),
    {}
  );
}

export function removeAuthHeaders() {
  return headerKeys.forEach(function(key) {
    localStorage.removeItem(key);
  });
}

export function setAuthHeaders(headers) {
  if (headers['access-token']) {
    headerKeys.forEach(function(key) {
      localStorage.setItem(key, headers[key]);
    });
    return true;
  }
  return false;
}

export function* sendPasswordResetRequest(data) {
  return yield call(fetchData, 'auth/password', {
    withoutVersion: true,
    method: 'POST',
    body: {
      email: data.get('email'),
      redirect_url: '/reset_password'
    }
  });
}

export function* sendResetPassword(data) {
  return yield call(fetchData, 'auth/password', {
    withoutVersion: true,
    method: 'PUT',
    body: {
      password: data.get('password'),
      password_confirmation: data.get('password_confirmation')
    }
  });
}
