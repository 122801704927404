import React from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '../Modal';
import FormDialog from '../FormDialog';
import NewOperatorFields from './NewOperatorFields';

const NewOperatorModal = ({ submitAction }) => (
  <Modal
    name="NewOperatorModal"
    render={() => (
      <FormDialog
        title="New Operator"
        buttonText="Add Operator"
        form="NewOperatorForm"
        onSubmit={submitAction}
      >
        <NewOperatorFields />
      </FormDialog>
    )}
  />
);

NewOperatorModal.propTypes = {
  submitAction: PropTypes.func.isRequired
};

export default NewOperatorModal;
