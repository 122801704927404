import React from 'react';
import { connect } from 'react-redux';

// Components
import UVCButton from '../Buttons/UVCButton';

// Actions
import { change } from 'redux-form';

// Material UI Components
import {
  withStyles,
  Grid,
  TextField,
  Checkbox,
  Typography
} from '@material-ui/core';

export const renderTextField = ({
  input,
  label,
  classes,
  select,
  children,
  onChange,
  placeholder,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    select={!!select}
    error={!!error}
    label={label}
    className={classes ? classes.textField : ''}
    helperText={error}
    children={children}
    onChange={onChange}
    inputProps={{ maxLength: 255 }}
    {...input}
    {...custom}
  />
);

export const renderSelectField = props => {
  let selectClass =
    props.classes && props.classes.selectField ? props.classes.selectField : '';

  return renderTextField({
    ...props,
    ...{ classes: { textField: selectClass } },
    ...{ select: true }
  });
};

export const renderCheckbox = ({ input, label }) => (
  <Checkbox
    label={label}
    checked={input.value ? true : false}
    onChange={input.onChange}
  />
);

const styles = theme => ({
  fileUpload: {
    display: 'flex'
  },
  fileName: {
    lineHeight: '3.6rem',
    paddingLeft: theme.spacing(2)
  },
  uploadErrors: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    color: theme.palette.error.dark
  }
});

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: null
    };
  }

  handleChange = e => {
    const {
      input: { onChange }
    } = this.props;
    onChange(e.target.files[0]);

    if (e.target.files[0]) {
      this.setState({ fileName: e.target.files[0].name });
    } else {
      this.props.resetUpload();
      this.setState({ fileName: '' });
    }
  };

  render() {
    const {
      label,
      classes,
      meta: { error }
    } = this.props;
    const { fileName } = this.state;

    return (
      <React.Fragment>
        <Grid container className={classes.fileUpload} alignItems="center">
          <Grid item xs={5}>
            <UVCButton text={label} id="file-upload-button" component="label">
              <input
                type="file"
                hidden
                id="file-upload"
                accept=".csv"
                onChange={this.handleChange}
              />
            </UVCButton>
          </Grid>
          <Grid item xs={7}>
            <Typography noWrap>{fileName}</Typography>
          </Grid>
        </Grid>
        {error && (
          <Typography className={classes.uploadErrors}>{error}</Typography>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetUpload: () =>
    dispatch(change(ownProps.meta.form, ownProps.input.name, null))
});

const renderFileUpload = withStyles(styles)(
  connect(null, mapDispatchToProps)(FileUpload)
);

export { renderFileUpload };
