import { createMuiTheme } from '@material-ui/core/styles';

import Colors from './colors';

/* These customization are based on great MUI class-based CSS design rule */
/* Consider /node_modules/material-ui/Input/InputLabel.d.ts as an example */

export default createMuiTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: [
      '"Source Sans Pro"',
      'sans-serif',
      'Roboto',
      'Helvetica',
      'Arial'
    ].join(','),
    h1: {
      fontSize: '4rem',
      letterSpacing: '0.022rem',
      lineHeight: 'normal'
    },
    h2: {
      fontSize: '2.4rem',
      letterSpacing: '0.013rem'
    },
    h3: {
      fontSize: '2rem',
      letterSpacing: '0.022rem',
      lineHeight: '1.5'
    },
    body1: {
      fontSize: '1.4rem',
      letterSpacing: '0.016rem',
      lineHeight: '1.8rem'
    },
    caption: {
      fontSize: '1.4rem',
      letterSpacing: '0.016rem',
      color: Colors.textSecondary
    }
  },
  palette: {
    primary: {
      main: Colors.purple,
      dark: Colors.blue
    },
    secondary: {
      main: Colors.blue
    },
    grey: {
      light: Colors.faintGray
    },
    text: {
      primary: Colors.textPrimary,
      secondary: Colors.textSecondary
    },
    background: {
      main: Colors.background
    },
    success: {
      main: Colors.success
    },
    error: {
      main: Colors.error,
      dark: Colors.error
    },
    action: {
      hover: 'rgba(27,142,203,0.08)'
    }
  },
  buttonHighlightColor: 'rgba(0, 0, 0, 0.05)',
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: '1.4rem'
      }
    }
  }
});
