import {
  throttle,
  select,
  call,
  put,
  fork,
  takeEvery,
  takeLatest,
  take,
  cancel
} from 'redux-saga/effects';

// Selectors
import { getCurrentFacilityId } from '../Facilities/selectors';

// Actions
import {
  searchOperatorsRequest,
  searchOperatorsReset,
  searchOperatorsFailure,
  searchOperatorsSuccess,
  getAllOperators,
  displayAllOperators,
  deactivateOperatorRequest,
  deactivateOperatorSuccess,
  deactivateOperatorFailure,
  uploadOperators,
  createOperator,
  updateOperatorDepartment,
  updateOperatorDepartmentFinished
} from './reducer';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';
import { reloadFacilityRequest } from '../Facilities/reducer';
import { closeModal } from '../../components/Modal/reducer';

// Services
import {
  searchOperators,
  deactivateOperator,
  uploadOperatorsFile,
  createNewOperator,
  updateOperator
} from '../../services/operators';

// Redux Form
import { SubmissionError } from 'redux-form';

function* searchOperatorsSaga() {
  yield throttle(500, searchOperatorsRequest, handleSearchOperatorsSaga);
}

function* handleSearchOperatorsSaga(action) {
  if (action.payload === '') {
    yield put(searchOperatorsReset());
  } else {
    const facilityId = yield select(getCurrentFacilityId);
    const searchRequest = yield fork(fetchResults, facilityId, action.payload);

    // Cancel search on reset
    yield take(searchOperatorsReset);
    yield cancel(searchRequest);
  }
}

function* fetchResults(facilityId, payload) {
  try {
    const { result, error } = yield call(searchOperators, facilityId, payload);

    if (error) {
      yield put(searchOperatorsFailure(error));
    } else {
      yield put(searchOperatorsSuccess(result));
    }
  } finally {
  }
}

function* getAllOperatorsSaga() {
  yield takeEvery(getAllOperators, handleGetAllOperatorsSaga);
}

function* handleGetAllOperatorsSaga() {
  const facilityId = yield select(getCurrentFacilityId);

  try {
    const { result, error } = yield call(searchOperators, facilityId);

    if (error) {
      yield put(searchOperatorsFailure(error));
    } else {
      yield put(searchOperatorsSuccess(result));
      yield put(displayAllOperators());
    }
  } finally {
  }
}

function* deactivateOperatorSaga() {
  yield takeEvery(deactivateOperatorRequest, handleDeactivateOperatorSaga);
}

function* handleDeactivateOperatorSaga(action) {
  const facilityId = yield select(getCurrentFacilityId);

  try {
    const { error } = yield call(
      deactivateOperator,
      facilityId,
      action.payload
    );

    if (error) {
      yield put(
        showSnackbar({
          variant: 'error',
          message: 'Could not deactivate operator'
        })
      );
      yield put(deactivateOperatorFailure(action.payload));
    } else {
      yield put(deactivateOperatorSuccess(action.payload));
      yield put(reloadFacilityRequest(facilityId));
    }
  } finally {
  }
}

function* uploadOperatorsSaga() {
  yield takeLatest(uploadOperators.REQUEST, handleUploadOperatorsSaga);
}

function* handleUploadOperatorsSaga(action) {
  const facilityId = yield select(getCurrentFacilityId);

  try {
    const { result, error } = yield call(
      uploadOperatorsFile,
      facilityId,
      action.payload
    );

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(uploadOperators.failure(fieldErrors));
    } else {
      yield put(closeModal());
      yield put(reloadFacilityRequest(facilityId));
      yield put(searchOperatorsReset());
      let operatorsCreated = result;
      const pluralizedOperators =
        operatorsCreated === 1 ? 'Operator' : 'Operators';
      const message = `${operatorsCreated} ${pluralizedOperators} added to facility`;
      yield put(
        showSnackbar({
          variant: 'success',
          message: message
        })
      );
    }
  } finally {
  }
}

function* createOperatorSaga() {
  yield takeLatest(createOperator.REQUEST, handleCreateOperatorSaga);
}

function* handleCreateOperatorSaga(action) {
  const facilityId = yield select(getCurrentFacilityId);

  try {
    const { error } = yield call(createNewOperator, facilityId, action.payload);

    if (error) {
      error['department_id'] = error['department'];
      const fieldErrors = new SubmissionError(error);

      yield put(createOperator.failure(fieldErrors));
    } else {
      yield put(closeModal());
      yield put(reloadFacilityRequest(facilityId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'New operator added to facility.'
        })
      );
    }
  } finally {
  }
}

function* updateOperatorDepartmentSaga() {
  yield takeLatest(
    updateOperatorDepartment,
    handleUpdateOperatorDepartmentSaga
  );
}

function* handleUpdateOperatorDepartmentSaga(action) {
  let operatorId = action.payload.operatorId;
  let facilityId = yield select(getCurrentFacilityId);

  try {
    const { result, error } = yield call(
      updateOperator,
      facilityId,
      action.payload
    );

    if (error) {
      let errorDetails = Object.values(error).join(' ');
      let message = 'Could not update Operator Department: ' + errorDetails;
      yield put(showSnackbar({ variant: 'error', message: message }));
      yield put(updateOperatorDepartmentFinished(operatorId));
    } else {
      yield put(updateOperatorDepartmentFinished(operatorId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Operator Department Updated!'
        })
      );
    }
  } finally {
  }
}

function* sagas() {
  yield fork(searchOperatorsSaga);
  yield fork(getAllOperatorsSaga);
  yield fork(deactivateOperatorSaga);
  yield fork(uploadOperatorsSaga);
  yield fork(createOperatorSaga);
  yield fork(updateOperatorDepartmentSaga);
}

export default sagas;
