import React from 'react';

// Material UI Components
import Link from '@material-ui/core/Link';

const EquipmentItemsTemplateLink = () => (
  <Link href="/equipment_items_csv_template.csv">
    Equipment Items CSV Upload Template
  </Link>
);

export default EquipmentItemsTemplateLink;
