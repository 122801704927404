export default {
  background: '#F4F4F4',

  blue: '#1B8ECB',
  purple: '#5A1F71',

  primary: '#F23C2D',
  secondary: '#A1008B',
  tertiary: '#0099FF',

  darkGray: '#525252',
  faintGray: '#9D9EA5',

  textPrimary: '#262626',
  textSecondary: '#4b4b4b',

  error: '#d32323',
  success: '#0ea03a',

  white: '#ffffff'
};
