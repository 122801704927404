export const getFacilitiesReducer = state => state.facilitiesReducer;

export const getFacilities = state =>
  getFacilitiesReducer(state).getIn(['entities', 'facilities']);

export const getCurrentFacilityId = state =>
  getFacilitiesReducer(state).get('currentFacilityId');

export const getCurrentFacility = state =>
  getFacilitiesReducer(state).getIn([
    'entities',
    'facilities',
    getCurrentFacilityId(state)
  ]);

export const isFacilityLoading = state =>
  getFacilitiesReducer(state).get('loadingFacility');

export const isFacilityLoaded = state =>
  getFacilitiesReducer(state).get('loadedFacility');

export const errorLoadingFacility = state =>
  getFacilitiesReducer(state).get('errorLoadingFacility');

export const getLoadingFacilities = state =>
  getFacilitiesReducer(state).get('loadingFacilities');

export const getLoadedFacilities = state =>
  getFacilitiesReducer(state).get('loadedFacilities');

export const isReportGenerating = state =>
  state.disinfectionReportsReducer.get('isGenerating') ||
  state.runCycleReportsReducer.get('isGenerating');
