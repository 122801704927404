import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import CardLink from '../CardLink';

// Material UI
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Cards = ({ departments }) => (
  <Grid container spacing={2}>
    {departments.valueSeq().map(department => (
      <Grid item xs={12} sm={6} key={department.get('id')}>
        <CardLink to={`/departments/${department.get('id')}`}>
          <Typography noWrap>{department.get('title')}</Typography>
        </CardLink>
      </Grid>
    ))}
  </Grid>
);

Cards.propTypes = {
  departments: ImmutablePropTypes.map
};

export default Cards;
