import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

export const constants = {
  SET_SELECTED_EQUIPMENT_TYPE_ID:
    'containers/Protocols/SET_SELECTED_EQUIPMENT_TYPE_ID',
  SET_SELECTED_PROTOCOL_ID: 'containers/Protocols/SET_SELECTED_PROTOCOL_ID',
  SET_CUSTOM_EQUIPMENT_TYPE: 'containers/Protocols/SET_CUSTOM_EQUIPMENT_TYPE',
  CLEAR_FORM: 'containers/Protocols/CLEAR_FORM',
  UPDATE_FREQUENCY: 'containers/Protocols/UPDATE_FREQUENCY',
  DEACTIVATE_EQUIPMENT_TYPE_REQUEST:
    'containers/Protocols/DEACTIVATE_EQUIPMENT_TYPE_REQUEST',
  DEACTIVATE_EQUIPMENT_TYPE_SUCCESS:
    'containers/Protocols/DEACTIVATE_EQUIPMENT_TYPE_SUCCESS',
  DEACTIVATE_EQUIPMENT_TYPE_FAILURE:
    'containers/Protocols/DEACTIVATE_EQUIPMENT_TYPE_FAILURE'
};

export const setSelectedEquipmentTypeId = createAction(
  constants.SET_SELECTED_EQUIPMENT_TYPE_ID
);
export const setSelectedProtocolId = createAction(
  constants.SET_SELECTED_PROTOCOL_ID
);
export const setCustomEquipmentType = createAction(
  constants.SET_CUSTOM_EQUIPMENT_TYPE
);
export const clearForm = createAction(constants.CLEAR_FORM);

export const deactivateEquipmentTypeRequest = createAction(
  constants.DEACTIVATE_EQUIPMENT_TYPE_REQUEST
);
export const deactivateEquipmentTypeSuccess = createAction(
  constants.DEACTIVATE_EQUIPMENT_TYPE_SUCCESS
);
export const deactivateEquipmentTypeFailure = createAction(
  constants.DEACTIVATE_EQUIPMENT_TYPE_FAILURE
);

export const updateFrequency = createFormAction(constants.UPDATE_FREQUENCY);

const initialState = fromJS({
  selectedEquipmentTypeId: null,
  selectedProtocolId: null,
  customEquipmentType: false,
  editing: [],
  attemptingDeactivationFor: []
});

const protocolsReducer = handleActions(
  {
    [setSelectedEquipmentTypeId]: (state, action) =>
      state.set('selectedEquipmentTypeId', action.payload),

    [setSelectedProtocolId]: (state, action) =>
      state.set('selectedProtocolId', action.payload),

    [setCustomEquipmentType]: (state, action) =>
      state.set('customEquipmentType', action.payload),

    [clearForm]: state =>
      state
        .set('selectedEquipmentTypeId', null)
        .set('selectedProtocolId', null)
        .set('customEquipmentType', false),

    [deactivateEquipmentTypeRequest]: (state, action) => {
      let newList = state.get('attemptingDeactivationFor').push(action.payload);
      return state.set('attemptingDeactivationFor', newList);
    },

    [deactivateEquipmentTypeFailure]: (state, action) => {
      let newList = state
        .get('attemptingDeactivationFor')
        .filterNot(id => id === action.payload);
      return state.set('attemptingDeactivationFor', newList);
    }
  },
  initialState
);

export default protocolsReducer;
