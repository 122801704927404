import React from 'react';

// Components
import { Field } from 'redux-form/immutable';
import { renderFileUpload, renderCheckbox } from '../Form/helpers';
import OperatorsTemplateLink from './TemplateLink';

// Material UI Components
import { withStyles, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
  helperText: {
    marginBottom: theme.spacing(2)
  }
});

const OperatorUploadFields = ({ classes }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography className={classes.helperText}>
        Select a CSV file to upload. Please use the <OperatorsTemplateLink /> as
        a template.
      </Typography>
      <Field
        margin="dense"
        id="operators_upload"
        name="operators_upload"
        label="Choose CSV File"
        component={renderFileUpload}
      />
    </Grid>
    <Grid item xs={12}>
      <FormGroup>
        <FormControlLabel
          label="Overwrite Duplicate Badge Ids"
          control={
            <Field
              margin="dense"
              id="deactivate_duplicate_badges"
              name="deactivate_duplicate_badges"
              component={renderCheckbox}
              type="checkbox"
            />
          }
        />
      </FormGroup>
      <Typography className={classes.helperText}>
        Overwriting a duplicate Badge ID will deactivate the previously existing
        record and make the new one active. No other Badge IDs will be affected.
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(styles)(OperatorUploadFields);
