import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

// Actions
export const constants = {
  LOGIN: 'containers/Auth/LOGIN',
  LOGIN_FAILURE: 'containers/Auth/LOGIN_FAILURE',
  LOGOUT: 'containers/Auth/LOGOUT',
  UNAUTHENTICATED: 'containers/Auth/UNAUTHENTICATED',
  VERIFY_TOKEN: 'containers/Auth/VERIFY_TOKEN',
  ACCEPT_INVITATION: 'containers/Auth/ACCEPT_INVITATION',
  REQUEST_PASSWORD_RESET: 'containers/Auth/REQUEST_PASSWORD_RESET',
  RESET_PASSWORD: 'containers/Auth/RESET_PASSWORD',
  SET_TEMPORARY_AUTH_HEADERS: 'containers/Auth/SET_TEMPORARY_AUTH_HEADERS'
};

export const login = createFormAction(constants.LOGIN);
export const logout = createAction(constants.LOGOUT);
export const unauthenticated = createAction(constants.UNAUTHENTICATED);
export const verifyToken = createAction(constants.VERIFY_TOKEN);
export const acceptInvitation = createFormAction(constants.ACCEPT_INVITATION);
export const requestPasswordReset = createFormAction(
  constants.REQUEST_PASSWORD_RESET
);
export const resetPassword = createFormAction(constants.RESET_PASSWORD);
export const setTemporaryAuthHeaders = createAction(
  constants.SET_TEMPORARY_AUTH_HEADERS
);

const initialState = fromJS({
  userInfo: {
    data: {}
  },
  loggedIn: false,
  authenticationAttempted: false,
  temporaryAuthHeaders: {}
});

const authReducer = handleActions(
  {
    [login.REQUEST]: state => state.set('loggedIn', false),

    [logout]: () => initialState.set('authenticationAttempted', true),

    [login.SUCCESS]: (state, action) =>
      state
        .set('userInfo', fromJS(action.payload))
        .set('loggedIn', true)
        .set('authenticationAttempted', true),

    [login.FAILURE]: (state, action) =>
      state.set('loggedIn', false).set('authenticationAttempted', true),

    [setTemporaryAuthHeaders]: (state, action) =>
      state.set('temporaryAuthHeaders', action.payload)
  },
  initialState
);

export default authReducer;
