import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

// Actions
export const constants = {
  UPDATE_PROFILE: 'containers/Profiles/UPDATE_PROFILE'
};

export const updateProfile = createFormAction(constants.UPDATE_PROFILE);

const initialState = fromJS({});

// Reducer
const profilesReducer = handleActions({}, initialState);

export default profilesReducer;
