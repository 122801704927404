import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import RootErrorBoundary from './containers/Errors/RootErrorBoundary';

import store from './store';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

import './index.css';

import { verifyToken } from './containers/Auth/reducer';

// Time ago setup
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
JavascriptTimeAgo.locale(en);

store.dispatch(verifyToken());

ReactDOM.render(
  <RootErrorBoundary>
    <Provider store={store}>
      <Routes />
    </Provider>
  </RootErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.unregister();
