import React from 'react';
import { connect } from 'react-redux';

// Components
import Modal from '../Modal';
import FormDialog from '../FormDialog';
import ReportFields from '../../containers/RunCycleReports/Fields';

// Actions
import { showModal } from '../Modal/reducer';
import { downloadRunCycleReport } from '../../containers/RunCycleReports/reducer';
import { downloadDisinfectionReport } from '../../containers/DisinfectionReports/reducer';

// Material UI
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  helperText: {
    marginBottom: theme.spacing(2)
  }
});

const DownloadsMenu = ({
  anchorEl,
  handleMenuClose,
  isMenuOpen,
  classes,
  showModal,
  downloadReport
}) => (
  <React.Fragment>
    <Modal
      name="RunCycleReportModal"
      render={() => (
        <FormDialog
          title="Run-Cycle Report"
          buttonText="Generate Report"
          form="RunCycleReportForm"
          initialValues={{ start_date: null, end_date: null }}
          onSubmit={downloadRunCycleReport}
        >
          <Typography className={classes.helperText}>
            Select dates below to download a CSV report of facility run-cycle
            data. All times are displayed in UTC. It is expected that run-cycles
            with errors or missing equipment tags will contain less data than
            successful cycles.
          </Typography>
          <ReportFields form="RunCycleReportForm" />
        </FormDialog>
      )}
    />
    <Menu
      id="downloads-menu"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          return showModal('RunCycleReportModal');
        }}
      >
        Run-Cycle Report
      </MenuItem>
      <MenuItem
        onClick={() => {
          downloadReport();
          return handleMenuClose();
        }}
      >
        <div>Process Report</div>
      </MenuItem>
    </Menu>
  </React.Fragment>
);

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName)),
  downloadReport: () => dispatch(downloadDisinfectionReport())
});

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(DownloadsMenu)
);
