import { createAction, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

export const constants = {
  DOWNLOAD_DISINFECTION_REPORT:
    'containers/DisinfectionReports/DOWNLOAD_DISINFECTION_REPORT',
  DISINFECTION_REPORT_DOWNLOADED:
    'containers/DisinfectionReports/DISINFECTION_REPORT_DOWNLOADED'
};

export const downloadDisinfectionReport = createAction(
  constants.DOWNLOAD_DISINFECTION_REPORT
);

export const disinfectionReportDownloaded = createAction(
  constants.DISINFECTION_REPORT_DOWNLOADED
);

const initialState = fromJS({
  isGenerating: false
});

const disinfectionReportsReducer = handleActions(
  {
    [downloadDisinfectionReport]: state => state.set('isGenerating', true),

    [disinfectionReportDownloaded]: state => state.set('isGenerating', false)
  },
  initialState
);

export default disinfectionReportsReducer;
