import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Actions
import { showModal } from '../../components/Modal/reducer';
import { createDepartment } from './reducer';

// Selectors
import { getDepartmentsAtCurrentFacility } from './selectors';

// Components
import FormDialog from '../../components/FormDialog';
import DepartmentFields from './Fields';
import Modal from '../../components/Modal';
import PaperActions from '../../components/PaperActions';
import DepartmentCards from '../../components/Departments/Cards';

class Departments extends React.Component {
  modalName = `NewDepartmentModal-${this.props.facilityId}`;
  newDepartmentForm = `newDepartmentForm-${this.props.facilityId}`;

  render() {
    const { departments, showModal, facilityId } = this.props;

    return (
      <div>
        <Modal
          name={this.modalName}
          render={() => (
            <FormDialog
              title="New Department"
              buttonText="Add Department"
              form={this.newDepartmentForm}
              initialValues={{ facility_id: facilityId }}
              onSubmit={createDepartment}
            >
              <DepartmentFields />
            </FormDialog>
          )}
        />
        <DepartmentCards departments={departments} />
        <PaperActions
          buttonId="add-department"
          primaryButtonText="Add Department"
          handleClick={() => showModal(this.modalName)}
        />
      </div>
    );
  }
}

Departments.propTypes = {
  departments: ImmutablePropTypes.map.isRequired,
  facilityId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  departments: getDepartmentsAtCurrentFacility(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName))
});

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
