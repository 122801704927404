import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Logo
import logo from '../../components/Images/uvc_logo.svg';

const styles = theme => ({
  errorPage: {
    textAlign: 'center',
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(16)
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: '8rem'
  }
});

const ErrorComponent = ({ classes }) => {
  return (
    <div className={classes.errorPage}>
      <header className={classes.logoWrapper}>
        <img src={logo} className={classes.logo} alt="UV-Concepts" />
      </header>
      <Typography variant="h1" component="h1" gutterBottom>
        Something went wrong
      </Typography>
      <Typography>
        Try refreshing this page. If the problem persists, contact a UVC
        Administrator
      </Typography>
    </div>
  );
};

export default withStyles(styles)(ErrorComponent);
