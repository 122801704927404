import { takeLatest, fork, take, cancel, call, put } from 'redux-saga/effects';

// Reducer actions
import {
  fetchFacilityRequest,
  fetchFacilityError,
  fetchFacilitySuccess,
  fetchFacilitiesRequest,
  fetchFacilitiesError,
  fetchFacilitiesSuccess,
  unloadFacility,
  setCurrentFacilityId,
  reloadFacilityRequest,
  reloadFacilitiesRequest,
  updateFacility,
  createFacility
} from './reducer';

// Component Actions
import { showSnackbar } from '../../components/CustomSnackbar/reducer';
import { closeModal } from '../../components/Modal/reducer';

// Services
import {
  fetchFacility,
  fetchFacilities,
  updateExistingFacility,
  createNewFacility
} from '../../services/facilities';

// Redux Form
import { SubmissionError } from 'redux-form';

// Normalizers
import { facilityNormalizer, facilitiesNormalizer } from './Normalizer';

// Event listeners
function* getFacilitySaga() {
  yield takeLatest(fetchFacilityRequest, handleGetFacilitySaga);
}

function* reloadFacilitySaga() {
  yield takeLatest(reloadFacilityRequest, handleGetFacilitySaga);
}

function* fetchFacilitiesSaga() {
  yield takeLatest(fetchFacilitiesRequest, handleGetFacilitiesSaga);
}

function* reloadFacilitiesSaga() {
  yield takeLatest(reloadFacilitiesRequest, handleGetFacilitiesSaga);
}

function* createFacilitySaga() {
  yield takeLatest(createFacility.REQUEST, handleCreateFacilitySaga);
}

function* updateFacilitySaga() {
  yield takeLatest(updateFacility.REQUEST, handleUpdateFacilitySaga);
}

// Handlers
function* handleGetFacilitySaga(action) {
  const fetchRequest = yield fork(handleFetchFacility, action.payload);

  yield take(unloadFacility);
  yield cancel(fetchRequest);
}

function* handleFetchFacility(payload) {
  try {
    const { result, error } = yield call(fetchFacility, payload);

    if (error) {
      yield put(fetchFacilityError(error));
      yield put(showSnackbar({ variant: 'error', message: error }));
    } else {
      yield put(fetchFacilitySuccess(facilityNormalizer(result)));
      yield put(setCurrentFacilityId(result.id.toString()));
    }
  } finally {
  }
}

function* handleGetFacilitiesSaga() {
  try {
    const { result, error } = yield call(fetchFacilities);

    if (error) {
      yield put(fetchFacilitiesError(error));
      yield put(showSnackbar({ variant: 'error', message: error }));
    } else {
      yield put(fetchFacilitiesSuccess(facilitiesNormalizer(result)));
    }
  } finally {
  }
}

function* handleCreateFacilitySaga(action) {
  try {
    const { error } = yield call(createNewFacility, action.payload);

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(createFacility.failure(fieldErrors));
    } else {
      yield put(closeModal());
      yield put(fetchFacilitiesRequest());
      yield put(
        showSnackbar({ variant: 'success', message: 'New Facility Created!' })
      );
    }
  } finally {
  }
}

function* handleUpdateFacilitySaga(action) {
  const facilityId = action.payload.get('id');

  try {
    const { error } = yield call(updateExistingFacility, action.payload);

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(updateFacility.failure(fieldErrors));
    } else {
      yield put(reloadFacilityRequest(facilityId));
      yield put(closeModal());
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Facility Updated!'
        })
      );
    }
  } finally {
  }
}

function* sagas() {
  yield fork(getFacilitySaga);
  yield fork(reloadFacilitySaga);
  yield fork(fetchFacilitiesSaga);
  yield fork(reloadFacilitiesSaga);
  yield fork(createFacilitySaga);
  yield fork(updateFacilitySaga);
}

export default sagas;
