import React from 'react';
import { connect } from 'react-redux';

// Date Utils
import format from 'date-fns/format';

// Redux Form
import { change } from 'redux-form';

// Material UI Pickers
import { KeyboardDatePicker } from '@material-ui/pickers';

// Date Utils
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

// Material UI Pickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const DatePicker = ({ label, form, name, changeDate }) => {
  const [selectedDate, setSelectedDate] = React.useState(null);

  function handleDateChange(date, value) {
    const emptyDate = value == null ? true : value.match(/_{2}\/_{2}\/_{4}/);
    if (emptyDate) {
      setSelectedDate('');
      setSelectedDate(null);
    } else {
      setSelectedDate(date);
    }

    if (emptyDate) {
      changeDate('');
      changeDate(null);
    } else if (date.toString() === 'Invalid Date') {
      changeDate(date);
    } else {
      const formattedDate = format(date, 'yyyy-MM-dd');
      changeDate(formattedDate);
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        label={label}
        value={selectedDate}
        onChange={handleDateChange}
        placeholder={'MM/DD/YYYY'}
        format={'MM/dd/yyyy'}
      />
    </MuiPickersUtilsProvider>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeDate: date => dispatch(change(ownProps.form, ownProps.name, date))
});

export default connect(
  null,
  mapDispatchToProps
)(DatePicker);
