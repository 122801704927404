import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import EnclosureErrorIcon from '../../components/Enclosures/EnclosureErrorIcon';

// Material UI
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  errorIcon: {
    marginLeft: '1.5rem'
  }
});

const EnclosureSummaryGrid = ({ enclosure, classes }) => (
  <Grid container spacing={1}>
    <Grid item xs={1}>
      <EnclosureErrorIcon
        enclosure={enclosure}
        color="primary"
        className={classes.errorIcon}
      />
    </Grid>
    <Grid item xs={5}>
      {enclosure.get('nickname')}
    </Grid>
    <Grid item xs={3}>
      {enclosure.get('udi')}
    </Grid>
    <Grid item xs={3}>
      {enclosure.get('location')}
    </Grid>
  </Grid>
);

EnclosureSummaryGrid.propTypes = {
  enclosure: ImmutablePropTypes.map.isRequired
};

export default withStyles(styles)(EnclosureSummaryGrid);
