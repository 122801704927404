import { getAuthHeaders, extractAuthHeaders, setAuthHeaders } from './auth';
import store from '../store';

// Selectors
import { getLoggedIn } from '../containers/Auth/selectors';

// Reducers
import { unauthenticated } from '../containers/Auth/reducer';
import { showSnackbar } from '../components/CustomSnackbar/reducer';

const defaultHeader = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

const serverErrorMsg =
  'A server error occurred. We have been notified of the problem.';

const apiVersion = 'v1';

function isBlob(response) {
  return response.headers
    .get('content-type')
    .match(/(text\/csv)|(application\/pdf)/);
}

function parseReponse(response) {
  if (isBlob(response)) {
    return response.blob();
  } else {
    return response.json();
  }
}

function getFilenameFromResponse(response) {
  let disposition = response.headers.get('Content-Disposition');
  let filename = disposition.substring(
    disposition.lastIndexOf('=') + 2,
    disposition.lastIndexOf('"')
  );
  return filename;
}

function buildResult(response, result) {
  if (isBlob(response)) {
    return {
      result: result,
      filename: getFilenameFromResponse(response)
    };
  } else {
    return { result };
  }
}

async function fetchData(url, opts = {}) {
  const options = opts;

  if (options.isFormData) {
    options.headers = { ...(await getAuthHeaders()) };
  } else {
    options.headers = {
      ...defaultHeader,
      ...options.headers,
      ...(await getAuthHeaders())
    };
  }

  if (options.body && !options.isFormData) {
    options.body = JSON.stringify(options.body);
  }

  let baseUrl = options.withoutVersion
    ? `${process.env.REACT_APP_API_HOST}/api/admin`
    : `${process.env.REACT_APP_API_HOST}/api/admin/${apiVersion}`;

  const fetchRequest = new Request(`${baseUrl}/${url}`, options);

  const isSignedIn = () => getLoggedIn(store.getState());

  return fetch(fetchRequest)
    .then(async response => {
      // Reset authorization data
      await setAuthHeaders(extractAuthHeaders(response.headers));
      if (response.status >= 500) {
        throw new Error(serverErrorMsg);
      }
      if (response.status === 404) {
        return { error: 'Not found' };
      }
      if (response.status === 403) {
        return { error: 'You are not authorized to view this page.' };
      }
      if (response.status === 401 && isSignedIn()) {
        store.dispatch(unauthenticated());
        return {
          error: 'Your session has expired. Please sign in again to continue.'
        };
      }

      return parseReponse(response)
        .then(result => {
          if (response.ok) {
            return buildResult(response, result);
          }
          return { error: result.errors };
        })
        .catch(e => {
          console.log(e);
          return { error: { _error: 'Request was not successful.' } };
        });
    })
    .catch(error => {
      let message = '';

      if (error.response) {
        message = error.response;
      } else if (error.toString().match(/Failed to fetch/)) {
        message = 'Could not connect to server';
      } else {
        message = serverErrorMsg;
      }

      store.dispatch(showSnackbar({ variant: 'error', message: message }));
      return { error: [message] };
    });
}

export default fetchData;
