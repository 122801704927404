import React from 'react';

// Components
import LogsPanel from './LogsPanel';
import PanelListHeaders from './PanelListHeaders';

export default function EnclosuresList({ enclosures }) {
  return (
    <React.Fragment>
      <PanelListHeaders />
      {enclosures.valueSeq().map(enclosure => (
        <LogsPanel key={enclosure.get('id')} enclosure={enclosure} />
      ))}
    </React.Fragment>
  );
}
