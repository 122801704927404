import Immutable from 'immutable';

import { getCurrentFacility } from '../Facilities/selectors';

const getFacilitiesReducer = state => state.facilitiesReducer;

export const getUsersAtCurrentFacility = state => {
  let users = getFacilitiesReducer(state).getIn(['entities', 'users']);

  if (users === undefined) {
    users = Immutable.List();
  }

  return users;
};

export const getUserByIdAtCurrentFacility = (state, id) =>
  getUsersAtCurrentFacility(state).get(id);

export const isInvitationPending = (state, id) =>
  getUserByIdAtCurrentFacility(state, id).get('invitation_pending');

export const getUserRoleByIdAtCurrentFacility = (state, id) => {
  let facilityId = getCurrentFacility(state).get('id');
  let facilityRoles = getFacilitiesReducer(state)
    .getIn(['entities', 'users', id.toString(), 'roles'])
    .filter(
      role => role.get('facility_id').toString() === facilityId.toString()
    );
  return facilityRoles.getIn([0, 'name']);
};

export const isUpdating = (state, userId) =>
  state.users.get('updating').includes(userId);

export const getRoleOptions = (state, includeBlank = false) => {
  // TODO: Read roles from state, once they're wired in from the server

  const roles = [
    { name: 'User', value: 'user' },
    { name: 'Facility Admin', value: 'facility_admin' }
  ];

  if (includeBlank) {
    roles.push({ name: 'None (remove user)', value: '' });
  }

  return roles;
};

export const getRolesForDisplay = state => {
  return {
    user: 'User',
    facility_admin: 'Facility Admin',
    uvc_admin: 'UVC Admin'
  };
};
