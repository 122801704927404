import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  customButton: {
    '&:hover': {
      backgroundColor: theme.buttonHighlightColor,
      '& span': {
        fontWeight: 'bold'
      }
    },
    '&:focus': {
      backgroundColor: theme.buttonHighlightColor,
      '& span': {
        fontWeight: 'bold'
      }
    },
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    letterSpacing: '0.125rem',
    textTransform: 'none',
    '& span': {
      transition: 'font-weight 0.25s'
    }
  },
  buttonText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
});

const TextButton = ({
  text,
  classes,
  id,
  children,
  iconLeft,
  ...buttonProps
}) => (
  <Button
    id={id}
    className={classes.customButton}
    disableRipple
    disableFocusRipple
    {...buttonProps}
  >
    {iconLeft &&
      React.cloneElement(iconLeft, {
        className: classes.leftIcon
      })}
    <div className={classes.buttonText}>{text}</div>
    {children}
  </Button>
);

TextButton.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconLeft: PropTypes.node
};

export default withStyles(styles)(TextButton);
