import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Selectors
import { getEnclosuresForFacility } from '../Enclosures/selectors';

// Components
import PaperSection from '../../components/PaperSection';
import EnclosuresList from '../../components/EnclosureLogs/EnclosuresList';

// Material UI
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  noEnclosures: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem'
  }
});

const FacilitySection = ({ facility, enclosures, classes }) => (
  <PaperSection
    title={facility.get('name')}
    id={`facility-${facility.get('id')}-logs`}
  >
    {enclosures.size === 0 && (
      <div className={classes.noEnclosures}>
        <Typography variant="h3">No enclosures at this facility</Typography>
      </div>
    )}
    {enclosures.size > 0 && <EnclosuresList enclosures={enclosures} />}
  </PaperSection>
);

FacilitySection.propTypes = {
  facility: ImmutablePropTypes.map.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  enclosures: getEnclosuresForFacility(state, ownProps.facility.get('id'))
});

export default withStyles(styles)(connect(mapStateToProps)(FacilitySection));
