import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import CardLink from '../CardLink';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  heading: {
    fontWeight: 'bold'
  },
  address: {
    marginLeft: theme.spacing(2)
  }
});

const FacilityCard = ({ facility, classes }) => (
  <CardLink to={`/facilities/${facility.get('id')}`}>
    <div>
      <Typography noWrap className={classes.heading} component="p" variant="h3">
        {facility.get('name')}
      </Typography>
      <Typography noWrap className={classes.address}>
        {facility.get('address_1')} {facility.get('address_2')}
      </Typography>
      <Typography noWrap className={classes.address}>
        {facility.get('city')} {facility.get('state')} {facility.get('zip')}
      </Typography>
    </div>
  </CardLink>
);

FacilityCard.propTypes = {
  facility: ImmutablePropTypes.map.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FacilityCard);
