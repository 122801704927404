import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

export const constants = {
  FETCH_DEPARTMENT_REQUEST: 'containers/Departments/FETCH_DEPARTMENT_REQUEST',
  FETCH_DEPARTMENT_ERROR: 'containers/Departments/FETCH_DEPARTMENT_ERROR',
  FETCH_DEPARTMENT_SUCCESS: 'containers/Departments/FETCH_DEPARTMENT_SUCCESS',
  CREATE_DEPARTMENT: 'containers/Departments/CREATE_DEPARTMENT',
  UPDATE_DEPARTMENT: 'containers/Departments/UPDATE_DEPARTMENT',
  UNLOAD_DEPARTMENT: 'containers/Departments/UNLOAD_DEPARTMENT',
  UPDATE_DEPARTMENT_PROTOCOL_FREQUENCY:
    'containers/Departments/UPDATE_PROTOCOL_FREQUENCY',
  SET_CURRENT_DEPARTMENT_ID: 'containers/Departments/SET_CURRENT_DEPARTMENT_ID',
  REMOVE_EQUIPMENT_ITEM_AT_DEPARTMENT:
    'containers/Departments/REMOVE_EQUIPMENT_ITEM_AT_DEPARTMENT'
};

// Actions
export const fetchDepartmentRequest = createAction(
  constants.FETCH_DEPARTMENT_REQUEST
);

export const fetchDepartmentError = createAction(
  constants.FETCH_DEPARTMENT_ERROR
);

export const fetchDepartmentSuccess = createAction(
  constants.FETCH_DEPARTMENT_SUCCESS
);

export const unloadDepartment = createAction(constants.UNLOAD_DEPARTMENT);

export const updateDepartmentProtocolFrequency = createAction(
  constants.UPDATE_DEPARTMENT_PROTOCOL_FREQUENCY
);

export const setCurrentDepartmentId = createAction(
  constants.SET_CURRENT_DEPARTMENT_ID
);

export const removeEquipmentItemAtDepartment = createAction(
  constants.REMOVE_EQUIPMENT_ITEM_AT_DEPARTMENT
);

// Form Actions
export const createDepartment = createFormAction(constants.CREATE_DEPARTMENT);
export const updateDepartment = createFormAction(constants.UPDATE_DEPARTMENT);

const initialState = fromJS({
  entities: {
    departments: {},
    equipment_types: {},
    protocols: {}
  },
  loadingDepartment: true,
  loadedDepartment: false,
  errorLoadingDepartment: false,
  currentDepartmentId: null
});

const departmentsReducer = handleActions(
  {
    [fetchDepartmentRequest]: state =>
      state
        .set('errorLoadingDepartment', false)
        .set('loadingDepartment', true)
        .set('loadedDepartment', false),

    [fetchDepartmentSuccess]: (state, action) =>
      state
        .set('entities', fromJS(action.payload).get('entities'))
        .set('errorLoadingDepartment', false)
        .set('loadingDepartment', false)
        .set('loadedDepartment', true),

    [fetchDepartmentError]: (state, action) =>
      state
        .set('errorLoadingDepartment', true)
        .set('loadingDepartment', false)
        .set('loadedDepartment', false),

    [unloadDepartment]: state =>
      state
        .set('errorLoadingDepartment', false)
        .set('loadingDepartment', true)
        .set('loadedDepartment', false)
        .set('currentDepartmentId', null),

    [updateDepartmentProtocolFrequency]: (state, action) => {
      let protocolPath = [
        'entities',
        'protocols',
        action.payload.id.toString(),
        'frequency'
      ];
      return state.setIn(protocolPath, action.payload.frequency);
    },

    [setCurrentDepartmentId]: (state, action) =>
      state.set('currentDepartmentId', action.payload),

    [removeEquipmentItemAtDepartment]: (state, action) =>
      state.removeIn([
        'entities',
        'equipmentItems',
        action.payload.equipmentItemId.toString()
      ])
  },
  initialState
);

export default departmentsReducer;
