import React from 'react';
import { connect } from 'react-redux';

// Router
import { Redirect } from 'react-router';

// Actions
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

class InvalidPasswordResetToken extends React.Component {
  componentDidMount() {
    this.props.snackbar({
      variant: 'error',
      message:
        'Invalid Password Reset token. Your token may be expired or was already used.'
    });
  }

  render() {
    return <Redirect to="/login" />;
  }
}

const mapDispatchToProps = dispatch => ({
  snackbar: snackbarProps => dispatch(showSnackbar(snackbarProps))
});

export default connect(
  null,
  mapDispatchToProps
)(InvalidPasswordResetToken);
