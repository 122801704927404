import React from 'react';
import PropTypes from 'prop-types';

// MUI Components
import { withStyles, CardActionArea } from '@material-ui/core';

import { Card, CardContent } from '@material-ui/core';

// Functional Components
import { Link } from 'react-router-dom';

const styles = theme => ({
  card: {
    borderLeft: '4px solid',
    borderColor: theme.palette.grey.light
  }
});

class CardLink extends React.Component {
  state = {
    raised: false
  };

  renderLink = React.forwardRef((itemProps, ref) => (
    <Link
      innerRef={ref}
      to={this.props.to}
      onClick={this.props.onClick}
      {...itemProps}
    />
  ));

  raise = () => this.setState({ raised: true });
  unraise = () => this.setState({ raised: false });

  render() {
    const { children, classes } = this.props;
    return (
      <Card
        onFocus={this.raise}
        onBlur={this.unraise}
        onMouseOver={this.raise}
        onMouseOut={this.unraise}
        className={classes.card}
        raised={this.state.raised}
      >
        <CardActionArea component={this.renderLink}>
          <CardContent>{children && children}</CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

CardLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(CardLink);
