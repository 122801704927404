import { getCurrentFacilityId } from '../Facilities/selectors';

export const getLoggedIn = state => state.authReducer.get('loggedIn');
export const getAuthenticationAttempted = state =>
  state.authReducer.get('authenticationAttempted');

export const getUserInfo = state =>
  state.authReducer.getIn(['userInfo', 'data']);

export const getUserRoles = state => getUserInfo(state).get('roles');

export const isUVCAdmin = state => userHasRole(state, 'uvc_admin');

export const getNotificationsCount = state =>
  getUserInfo(state).get('notifications_count');

export const isFacilityAdmin = state => {
  let facilityAdminRoles = getUserRoles(state).filter(
    role =>
      role.get('name') === 'facility_admin' &&
      role.get('facility_id').toString() ===
        getCurrentFacilityId(state).toString()
  );
  return !(facilityAdminRoles === undefined || facilityAdminRoles.size === 0);
};

export const userHasRole = (state, roleName) => {
  if (getUserRoles(state)) {
    return getUserRoles(state)
      .map(role => role.get('name'))
      .includes(roleName);
  } else {
    return false;
  }
};

export const getUserFacilityIds = state =>
  getUserRoles(state).map(role => role.get('facility_id'));

export const userHasSingleFacility = state => {
  return !isUVCAdmin(state) && getUserFacilityIds(state).size === 1;
};

export const canViewFacility = (state, facilityId) => {
  if (isUVCAdmin(state)) {
    return true;
  } else {
    return getUserRoles(state)
      .map(role => role.get('facility_id').toString())
      .includes(facilityId);
  }
};

export const getTemporaryAuthHeaders = state =>
  state.authReducer.get('temporaryAuthHeaders');
