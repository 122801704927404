const getOperatorsReducer = state => state.operatorsReducer;

export const hasSearched = state =>
  getOperatorsReducer(state).get('hasSearched');

export const isSearching = state =>
  getOperatorsReducer(state).get('isSearching');

export const getSearchResults = state =>
  getOperatorsReducer(state).get('searchResults');

export const resultsFound = state =>
  getOperatorsReducer(state).get('resultsFound');

export const emptyResults = state => {
  const searchResults = getSearchResults(state);
  return searchResults.size === 0;
};

export const isAttemptingDeactivation = (state, id) =>
  getOperatorsReducer(state)
    .get('attemptingDeactivationFor')
    .includes(id);

export const isDisplayingAllOperators = state =>
  getOperatorsReducer(state).get('displayingAllOperators');

export const countOperatorSearchResults = state => {
  return getSearchResults(state).count();
};

export const isUpdatingDepartment = (state, id) =>
  getOperatorsReducer(state)
    .get('attemptingDepartmentUpdateFor')
    .includes(id);
