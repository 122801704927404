import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core';

// Functional Components
import { Link } from 'react-router-dom';

// Logo
import logo from '../Images/uvc_logo.svg';

const styles = theme => ({
  logoContainer: {
    flexGrow: 1,
    display: 'flex'
  },
  logo: {
    display: 'block',
    height: '6rem',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  }
});

const HeaderLogo = ({ classes }) => (
  <div className={classes.logoContainer}>
    <Link to="/my_facilities">
      <img src={logo} alt="UV Concepts" className={classes.logo} />
    </Link>
  </div>
);

HeaderLogo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HeaderLogo);
