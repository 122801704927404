import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Actions
import { deactivateOperatorRequest } from '../../containers/Operators/reducer';

// Selectors
import { isAttemptingDeactivation } from '../../containers/Operators/selectors';

// Components
import OperatorDepartmentForm from './DepartmentForm';

// Material UI Components
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

const OperatorRow = ({ operator, removeOperator, isDeactivating }) => (
  <TableRow>
    <TableCell>
      {operator.get('last_name')}, {operator.get('first_name')}
    </TableCell>
    <TableCell>{operator.get('badge_id')}</TableCell>
    <TableCell>
      <OperatorDepartmentForm
        form={`operatorDepartmentForm-${operator.get('id')}`}
        initialValues={{ department_id: operator.get('department_id') }}
        operatorId={operator.get('id')}
      />
    </TableCell>
    <TableCell>
      {isDeactivating && <CircularProgress />}
      {!isDeactivating && (
        <IconButton
          id={`remove-operator-${operator.get('id')}`}
          className="remove-operator-button"
          onClick={() => removeOperator(operator)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </TableCell>
  </TableRow>
);

OperatorRow.propTypes = {
  operator: ImmutablePropTypes.map.isRequired,
  removeOperator: PropTypes.func.isRequired,
  isDeactivating: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  isDeactivating: isAttemptingDeactivation(state, ownProps.operator.get('id'))
});

const mapDispatchToProps = dispatch => ({
  removeOperator: operator => {
    let confirmation = window.confirm(
      `Deactivate operator: ${operator.get('first_name')} ${operator.get(
        'last_name'
      )}?`
    );
    if (confirmation) {
      dispatch(deactivateOperatorRequest(operator.get('id')));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorRow);
