import { takeLatest, fork, call, put } from 'redux-saga/effects';

import { createEnclosure, updateEnclosure } from './reducer';

import {
  createNewEnclosure,
  updateExistingEnclosure
} from '../../services/enclosures';

import { SubmissionError } from 'redux-form';
import { closeModal } from '../../components/Modal/reducer';
import { reloadFacilityRequest } from '../Facilities/reducer';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

function* createEnclosureSaga() {
  yield takeLatest(createEnclosure.REQUEST, handleCreateEnclosureSaga);
}

function* handleCreateEnclosureSaga(action) {
  const facilityId = action.payload.get('facility_id');

  try {
    const { error } = yield call(createNewEnclosure, action.payload);

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(createEnclosure.failure(fieldErrors));
    } else {
      yield put(closeModal());
      yield put(reloadFacilityRequest(facilityId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'New enclosure added to facility.'
        })
      );
    }
  } finally {
  }
}

function* updateEnclosureSaga() {
  yield takeLatest(updateEnclosure.REQUEST, handleUpdateEnclosureSaga);
}

function* handleUpdateEnclosureSaga(action) {
  const facilityId = action.payload.get('facility_id');

  try {
    const response = yield call(updateExistingEnclosure, action.payload);
    const { error } = response;

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(updateEnclosure.failure(fieldErrors));
    } else if (response) {
      yield put(closeModal());
      yield put(reloadFacilityRequest(facilityId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Enclosure updated successfully.'
        })
      );
    }
  } finally {
  }
}

function* sagas() {
  yield fork(createEnclosureSaga);
  yield fork(updateEnclosureSaga);
}

export default sagas;
