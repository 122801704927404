import { normalize, schema } from 'normalizr';

// Schema
const equipmentItemSchema = new schema.Entity('equipmentItems');
const protocolSchema = new schema.Entity('protocols');
const departmentSchema = new schema.Entity('departments', {
  equipment_items: [equipmentItemSchema],
  protocols: [protocolSchema]
});

export const departmentNormalizer = departmentResult =>
  normalize(departmentResult, departmentSchema);
