import { createAction, handleActions } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

import { fromJS } from 'immutable';

// Actions
export const constants = {
  SEARCH_OPERATORS_REQUEST: 'containers/Operators/SEARCH_OPERATORS_REQUEST',
  SEARCH_OPERATORS_RESET: 'containers/Operators/SEARCH_OPERATORS_RESET',
  SEARCH_OPERATORS_FAILURE: 'containers/Operators/SEARCH_OPERATORS_FAILURE',
  SEARCH_OPERATORS_SUCCESS: 'containers/Operators/SEARCH_OPERATORS_SUCCESS',
  GET_ALL_OPERATORS: 'containers/Operators/GET_ALL_OPERATORS',
  DISPLAY_ALL_OPERATORS: 'containers/Operators/DISPLAY_ALL_OPERATORS',
  DEACTIVATE_OPERATOR_REQUEST:
    'containers/Operators/DEACTIVATE_OPERATOR_REQUEST',
  DEACTIVATE_OPERATOR_SUCCESS:
    'containers/Operators/DEACTIVATE_OPERATOR_SUCCESS',
  DEACTIVATE_OPERATOR_FAILURE:
    'containers/Operators/DEACTIVATE_OPERATOR_FAILURE',
  UPLOAD_OPERATORS: 'containers/Operators/UPLOAD_OPERATORS',
  CREATE_OPERATOR: 'containers/Operators/CREATE_OPERATOR',
  UPDATE_OPERATOR_DEPARTMENT: 'containers/Operators/UPDATE_OPERATOR_DEPARTMENT',
  UPDATE_OPERATOR_DEPARTMENT_FINISHED:
    'containers/Operators/UPDATE_OPERATOR_DEPARTMENT_FINISHED'
};

export const searchOperatorsRequest = createAction(
  constants.SEARCH_OPERATORS_REQUEST
);
export const searchOperatorsReset = createAction(
  constants.SEARCH_OPERATORS_RESET
);
export const searchOperatorsFailure = createAction(
  constants.SEARCH_OPERATORS_FAILURE
);
export const searchOperatorsSuccess = createAction(
  constants.SEARCH_OPERATORS_SUCCESS
);
export const getAllOperators = createAction(constants.GET_ALL_OPERATORS);
export const displayAllOperators = createAction(
  constants.DISPLAY_ALL_OPERATORS
);
export const deactivateOperatorRequest = createAction(
  constants.DEACTIVATE_OPERATOR_REQUEST
);
export const deactivateOperatorSuccess = createAction(
  constants.DEACTIVATE_OPERATOR_SUCCESS
);
export const deactivateOperatorFailure = createAction(
  constants.DEACTIVATE_OPERATOR_FAILURE
);

export const uploadOperators = createFormAction(constants.UPLOAD_OPERATORS);

export const createOperator = createFormAction(constants.CREATE_OPERATOR);

export const updateOperatorDepartment = createAction(
  constants.UPDATE_OPERATOR_DEPARTMENT
);
export const updateOperatorDepartmentFinished = createAction(
  constants.UPDATE_OPERATOR_DEPARTMENT_FINISHED
);

const initialState = fromJS({
  hasSearched: false,
  isSearching: false,
  resultsFound: false,
  searchError: null,
  searchResults: [],
  attemptingDeactivationFor: [],
  deactivationError: null,
  displayingAllOperators: false,
  attemptingDepartmentUpdateFor: []
});

const operatorsReducer = handleActions(
  {
    [searchOperatorsRequest]: state =>
      state
        .set('hasSearched', true)
        .set('isSearching', true)
        .set('resultsFound', false)
        .set('searchError', null)
        .set('displayingAllOperators', false)
        .set('searchResults', fromJS([])),

    [searchOperatorsSuccess]: (state, action) =>
      state
        .set('hasSearched', true)
        .set('isSearching', false)
        .set('resultsFound', true)
        .set('searchError', null)
        .set('searchResults', fromJS(action.payload)),

    [searchOperatorsFailure]: (state, action) =>
      state
        .set('hasSearched', true)
        .set('isSearching', false)
        .set('resultsFound', true)
        .set('searchError', action.payload)
        .set('displayingAllOperators', false)
        .set('searchResults', fromJS([])),

    [searchOperatorsReset]: state =>
      state
        .set('hasSearched', false)
        .set('isSearching', false)
        .set('resultsFound', false)
        .set('searchError', null)
        .set('displayingAllOperators', false)
        .set('searchResults', fromJS([])),

    [displayAllOperators]: state => state.set('displayingAllOperators', true),

    [deactivateOperatorRequest]: (state, action) => {
      let newList = state.get('attemptingDeactivationFor').push(action.payload);
      return state.set('attemptingDeactivationFor', newList);
    },

    [deactivateOperatorSuccess]: (state, action) => {
      let newResults = state
        .get('searchResults')
        .filterNot(result => result.get('id') === action.payload);
      return state.set('searchResults', newResults);
    },

    [deactivateOperatorFailure]: (state, action) => {
      let newList = state
        .get('attemptingDeactivationFor')
        .filterNot(id => id === action.payload);
      return state.set('attemptingDeactivationFor', newList);
    },

    [updateOperatorDepartment]: (state, action) => {
      let updateList = state
        .get('attemptingDepartmentUpdateFor')
        .push(action.payload.operatorId);

      return state.set('attemptingDepartmentUpdateFor', updateList);
    },

    [updateOperatorDepartmentFinished]: (state, action) => {
      let operatorId = action.payload;
      let newList = state
        .get('attemptingDepartmentUpdateFor')
        .filterNot(id => id === operatorId);
      return state.set('attemptingDepartmentUpdateFor', newList);
    }
  },
  initialState
);

export default operatorsReducer;
