import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { showModal } from '../../components/Modal/reducer';
import { inviteUser } from './reducer';

// Selectors
import { isUVCAdmin, isFacilityAdmin } from '../Auth/selectors';
import { getUsersAtCurrentFacility } from './selectors';

// Components
import UserRow from './UserRow';
import FormDialog from '../../components/FormDialog';
import UserFields from './Fields';
import Modal from '../../components/Modal';
import PaperActions from '../../components/PaperActions';

// Material UI Components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

class Users extends React.Component {
  modalName = `NewUserModal-${this.props.facilityId}`;
  newUserForm = `newUserForm-${this.props.facilityId}`;

  render() {
    const { users, facilityId, showModal, canAddUser } = this.props;

    return (
      <div>
        {canAddUser && (
          <Modal
            name={this.modalName}
            render={() => (
              <FormDialog
                title="New User"
                buttonText="Add User"
                form={this.newUserForm}
                initialValues={{ facility_id: facilityId, role_name: 'user' }}
                onSubmit={inviteUser}
              >
                <UserFields />
              </FormDialog>
            )}
          />
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.valueSeq().map((user, index) => (
              <UserRow key={index} facilityId={facilityId} user={user} />
            ))}
          </TableBody>
        </Table>
        {canAddUser && (
          <PaperActions
            buttonId="add-user"
            primaryButtonText="Add User"
            handleClick={() => showModal(this.modalName)}
          />
        )}
      </div>
    );
  }
}

Users.propTypes = {
  facilityId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  canAddUser: isUVCAdmin(state) || isFacilityAdmin(state),
  users: getUsersAtCurrentFacility(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName))
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
