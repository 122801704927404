import { takeLatest, fork, call, put, select } from 'redux-saga/effects';

import { getUserRoleByIdAtCurrentFacility } from './selectors';
import { changeRole, changeRoleSuccess, inviteUser } from './reducer';
import { updateRole, sendUserInvitation } from '../../services/users';
import { reloadFacilityRequest } from '../Facilities/reducer';
import { SubmissionError } from 'redux-form';
import { closeModal } from '../../components/Modal/reducer';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

function* roleChangeSaga() {
  yield takeLatest(changeRole, handleRoleChangeSaga);
}

function* handleRoleChangeSaga(action) {
  const userId = action.payload.userId;

  try {
    const currentRole = yield select(getUserRoleByIdAtCurrentFacility, userId);
    const newRole = action.payload.roleName;

    if (currentRole !== newRole) {
      const { result, error } = yield call(updateRole, action.payload);

      if (error) {
        yield put(showSnackbar({ variant: 'error', message: error }));
      } else {
        yield put(changeRoleSuccess(result));
        yield put(reloadFacilityRequest(action.payload.facilityId));

        yield put(
          showSnackbar({ variant: 'success', message: 'User Role Updated!' })
        );
      }
    } else {
      yield put(changeRoleSuccess({ id: userId }));
    }
  } finally {
  }
}

function* inviteUserSaga() {
  yield takeLatest(inviteUser.REQUEST, handleInviteUserSaga);
}

function* handleInviteUserSaga(action) {
  const facilityId = action.payload.get('facility_id');

  try {
    const response = yield call(sendUserInvitation, action.payload);
    const { result, error } = response;

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(inviteUser.failure(fieldErrors));
    } else if (result) {
      yield put(closeModal());
      yield put(reloadFacilityRequest(facilityId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'New user added to facility.'
        })
      );
    }
  } finally {
  }
}

function* sagas() {
  yield fork(roleChangeSaga);
  yield fork(inviteUserSaga);
}

export default sagas;
