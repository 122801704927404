import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import EquipmentItem from './EquipmentItem';

// Material UI Components
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const EquipmentItemsTable = ({ equipmentItems }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Equipment Type</TableCell>
        <TableCell>Indicator Tag ID</TableCell>
        <TableCell>Equipment ID</TableCell>
        <TableCell>Next Cycle Date</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {equipmentItems.map(item => (
        <EquipmentItem key={item.get('id')} equipmentItem={item} />
      ))}
    </TableBody>
  </Table>
);

EquipmentItemsTable.propTypes = {
  equipmentItems: ImmutablePropTypes.list.isRequired
};

export default EquipmentItemsTable;
