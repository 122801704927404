import React from 'react';

// Components
import PasswordFields from './PasswordFields';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  buttonLink: {
    '&:focus': {
      textDecoration: 'underline'
    }
  }
});

class ResetPassword extends React.Component {
  state = {
    resettingPassword: false
  };

  handleShowPasswordFields = () => {
    this.setState({
      resettingPassword: true
    });
  };

  render() {
    const { classes } = this.props;
    const { resettingPassword } = this.state;

    return (
      <React.Fragment>
        {!resettingPassword && (
          <Link
            className={classes.buttonLink}
            type="button"
            component="button"
            variant="body1"
            onClick={this.handleShowPasswordFields}
          >
            Reset Password
          </Link>
        )}
        {resettingPassword && <PasswordFields reset />}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ResetPassword);
