import { takeLatest, fork, select, call, put } from 'redux-saga/effects';

import { downloadRunCycleReport, runCycleReportDownloaded } from './reducer';

import { requestRunCycleReport } from '../../services/runCycleReports';

// Selectors
import { getCurrentFacilityId } from '../Facilities/selectors';

// Actions
import { closeModal } from '../../components/Modal/reducer';

// File Download
import { saveAs } from 'file-saver';

// Redux Form
import { SubmissionError } from 'redux-form';

function* downloadRunCycleReportSaga() {
  yield takeLatest(
    downloadRunCycleReport.REQUEST,
    attemptDownloadRunCycleReport
  );
}

function* attemptDownloadRunCycleReport(action) {
  const validDates = yield call(validateReportDates, action.payload);

  if (validDates) {
    yield call(handleDownloadRunCycleReport, action);
  } else {
    const errors = new SubmissionError({
      _errors: 'Can not submit with invalid dates'
    });
    yield put(downloadRunCycleReport.failure(errors));
  }
}

function validateReportDates(values) {
  if (
    (values.get('start_date') &&
      values.get('start_date').toString() === 'Invalid Date') ||
    (values.get('end_date') &&
      values.get('end_date').toString() === 'Invalid Date')
  ) {
    return false;
  } else {
    return true;
  }
}

function* handleDownloadRunCycleReport(action) {
  const facilityId = yield select(getCurrentFacilityId);

  try {
    const response = yield call(
      requestRunCycleReport,
      facilityId,
      action.payload.get('start_date'),
      action.payload.get('end_date')
    );

    if (response.error) {
      yield put(downloadRunCycleReport.failure(response.error));
    } else {
      yield put(closeModal());
      yield call(saveAs, response.result, response.filename);
    }
  } finally {
    yield put(runCycleReportDownloaded);
  }
}

function* sagas() {
  yield fork(downloadRunCycleReportSaga);
}

export default sagas;
