import { normalize, schema } from 'normalizr';

// Schema
const enclosureSchema = new schema.Entity('enclosures');
const departmentSchema = new schema.Entity('departments');
const userSchema = new schema.Entity('users');
const operatorSchema = new schema.Entity('operators');
const softwarePackageSchema = new schema.Entity('software_packages');
const facilitySchema = new schema.Entity('facilities', {
  enclosures: [enclosureSchema],
  departments: [departmentSchema],
  users: [userSchema],
  operators: [operatorSchema],
  softwarePackages: [softwarePackageSchema]
});

export const facilityNormalizer = result => normalize(result, facilitySchema);

const facilityListSchema = [facilitySchema];
export const facilitiesNormalizer = result =>
  normalize(result, facilityListSchema);
