import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Redux Form
import { reduxForm } from 'redux-form/immutable';

// Actions
import { closeModal } from '../Modal/reducer';

// Components
import UVCButton from '../Buttons/UVCButton';
import CancelButton from '../Buttons/CancelButton';

// Material UI Components
import { withStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  cancel: {
    marginRight: theme.spacing(2)
  },
  formDialog: {
    minWidth: '450px'
  },
  error: {
    fontSize: '1.4rem',
    marginTop: theme.spacing(1),
    color: theme.palette.error.dark
  }
});

const FormDialog = ({
  title,
  buttonText,
  form,
  handleSubmit,
  submitting,
  children,
  classes,
  closeModal,
  error
}) => (
  <form onSubmit={handleSubmit} className={classes.formDialog}>
    <DialogTitle disableTypography={true}>
      <Typography variant="h2" component="h2">
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent>
      {children}
      {error && <strong className={classes.error}>{error.join(', ')}</strong>}
    </DialogContent>
    <DialogActions>
      {submitting && (
        <div>
          <CircularProgress />
        </div>
      )}
      {!submitting && (
        <React.Fragment>
          <UVCButton id="form-dialog-submit" type="submit" text={buttonText} />
          <CancelButton id="close-modal" onClick={closeModal} />
        </React.Fragment>
      )}
    </DialogActions>
  </form>
);

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal())
});

export default withStyles(styles)(
  reduxForm({ enableReinitialize: true })(
    connect(
      null,
      mapDispatchToProps
    )(FormDialog)
  )
);
