import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* fetchFacilities() {
  return yield call(fetchData, 'facilities');
}

export function* fetchFacility(facilityId) {
  return yield call(fetchData, `facilities/${facilityId}`);
}

export function* updateExistingFacility(data) {
  const facilityId = data.get('id');

  return yield call(fetchData, `facilities/${facilityId}`, {
    method: 'PATCH',
    body: data
  });
}

export function* createNewFacility(data) {
  return yield call(fetchData, `facilities`, {
    method: 'POST',
    body: data
  });
}
