import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Router
import { Redirect } from 'react-router';

// Query string helpers
import queryString from 'query-string';

// Actions
import { setTemporaryAuthHeaders, resetPassword } from './reducer';

// Components
import CenteredForm from '../../components/Auth/CenteredForm';
import ResetPasswordForm from './ResetPasswordForm';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = { token: '' };
  }

  componentWillMount() {
    const params = queryString.parse(this.props.location.search);

    this.setState({
      token: params.token
    });

    let tempHeaders = {
      'access-token': params['access-token'],
      client: params.client,
      expiry: params.expiry,
      uid: params.uid,
      'token-type': 'Bearer'
    };

    this.props.setTemporaryAuthHeaders(tempHeaders);
  }

  render() {
    const { token } = this.state;

    return (
      <CenteredForm title="Reset Password">
        {token && (
          <ResetPasswordForm
            form="resetPasswordForm"
            onSubmit={resetPassword}
            initialValues={{ reset_password_token: token }}
          />
        )}
        {!token && <Redirect to="/reset_password_request" />}
      </CenteredForm>
    );
  }
}

ResetPassword.propTypes = {
  setTemporaryAuthHeaders: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  setTemporaryAuthHeaders: headers => dispatch(setTemporaryAuthHeaders(headers))
});

export default connect(
  null,
  mapDispatchToProps
)(ResetPassword);
