import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Field } from 'redux-form/immutable';

// Helpers
import { renderTextField } from '../../components/Form/helpers';

// Material UI components
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  textField: {
    minWidth: 350
  }
});

class DepartmentFields extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Field
          autoFocus
          margin="dense"
          id="title"
          name="title"
          label="Department Title"
          required
          fullWidth
          classes={classes}
          component={renderTextField}
        />
      </React.Fragment>
    );
  }
}

DepartmentFields.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DepartmentFields);
