import React from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    width: 400,
    marginRight: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  iconContainer: {
    padding: 10
  }
});

const SearchInput = ({ label, fieldId, onSearch, classes }) => (
  <Paper className={classes.root}>
    <InputBase
      placeholder={label}
      id={fieldId}
      onChange={onSearch}
      className={classes.input}
    />
    <div className={classes.iconContainer}>
      <SearchIcon color="disabled" />
    </div>
  </Paper>
);

SearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default withStyles(styles)(SearchInput);
