import {
  getDepartments,
  getCurrentDepartmentId
} from '../Departments/selectors';

const getDepartmentEntities = state => state.departmentsReducer.get('entities');

export const getEquipmentItemById = (state, id) =>
  getDepartmentEntities(state).getIn([
    'data',
    'entities',
    'equipmentItems',
    id.toString()
  ]);

export const getEquipmentItemIdsForCurrentDepartment = state => {
  return getDepartments(state).getIn([
    getCurrentDepartmentId(state),
    'equipment_items'
  ]);
};

export const getEquipmentItemsReducer = state => state.equipmentItemsReducer;

export const hasSearched = state =>
  getEquipmentItemsReducer(state).get('hasSearched');

export const isSearching = state =>
  getEquipmentItemsReducer(state).get('isSearching');

export const getSearchResults = state =>
  getEquipmentItemsReducer(state).get('searchResults');

export const resultsFound = state =>
  getEquipmentItemsReducer(state).get('resultsFound');

export const emptyResults = state => {
  const searchResults = getSearchResults(state);
  return searchResults.size === 0;
};

export const countEquipmentItemSearchResults = state =>
  getSearchResults(state).count();

export const isAttemptingDeactivation = (state, id) => {
  const deactivationList = getEquipmentItemsReducer(state).get(
    'attemptingDeactivationFor'
  );
  return deactivationList.includes(id);
};

export const getItemCountForProtocol = (state, id) => {
  const equipmentItems = getDepartmentEntities(state).get('equipmentItems');
  let count = 0;

  if (equipmentItems) {
    equipmentItems.forEach(item => {
      if (item.get('protocol_id') === id) {
        count += 1;
      }
    });
  }

  return count;
};
