import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Selectors
import { getLoggedIn } from './containers/Auth/selectors';

// MUI components
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

// Theme
import Theme from './styles/theme';

// Layout components
import ErrorBoundary from './containers/Errors/ErrorBoundary';
import Header from './components/Header';
import CustomSnackbar from './components/CustomSnackbar';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  scrollbarContent: {
    height: 'calc(100vh - 48px - 8px) !important',
    marginTop: 48 + theme.spacing(1),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px - 8px) !important',
      marginTop: 64 + theme.spacing(1)
    }
  },
  messageBox: {
    height: '100%'
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
    marginTop: theme.spacing(4)
  },
  loggedInContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(10),
    overflow: 'auto'
  }
});

function App(props) {
  const { classes, loggedIn, children } = props;

  const contentClass = loggedIn ? classes.loggedInContent : classes.content;

  return (
    <MuiThemeProvider theme={Theme}>
      <ErrorBoundary>
        <div className={classes.root}>
          <CssBaseline />
          {loggedIn && <Header />}
          <ErrorBoundary>
            <main className={contentClass}>{children}</main>
            <CustomSnackbar />
          </ErrorBoundary>
        </div>
      </ErrorBoundary>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  loggedIn: PropTypes.bool
};

const mapStateToProps = state => ({
  loggedIn: getLoggedIn(state)
});

export default withStyles(styles)(connect(mapStateToProps)(App));
