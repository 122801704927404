import React from 'react';

// Helpers
import { renderTextField } from '../../components/Form/helpers';

// Redux Form
import { Field } from 'redux-form/immutable';

// Phone Number Formatting
import { AsYouType } from 'libphonenumber-js';

// Material UI
import Grid from '@material-ui/core/Grid';

const normalizePhone = value => {
  if (value) {
    return new AsYouType('US').input(value);
  } else {
    return value;
  }
};

const ProfileFields = () => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Field
        margin="dense"
        id="first_name"
        name="first_name"
        label="First Name"
        autoFocus
        fullWidth
        required
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        margin="dense"
        id="last_name"
        name="last_name"
        label="Last Name"
        fullWidth
        required
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        margin="dense"
        id="title"
        name="title"
        label="Title"
        fullWidth
        required
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        margin="dense"
        id="employee_id"
        name="employee_id"
        label="Employee ID"
        fullWidth
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        margin="dense"
        id="department"
        name="department"
        label="Department"
        fullWidth
        required
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        margin="dense"
        id="phone_number"
        name="phone_number"
        label="Phone Number"
        type="tel"
        fullWidth
        required
        component={renderTextField}
        format={normalizePhone}
        normalize={normalizePhone}
      />
    </Grid>
  </Grid>
);

export default ProfileFields;
