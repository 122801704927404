import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Components
import CenterLoadingIcon from '../../components/CenterLoadingIcon';
import OperatorsTable from '../../components/Operators/OperatorsTable';

// Selectors
import {
  hasSearched,
  isSearching,
  resultsFound,
  emptyResults,
  getSearchResults,
  countOperatorSearchResults,
  isDisplayingAllOperators
} from './selectors';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';

const styles = theme => ({
  placeholder: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(2),
    height: '40rem',
    overflow: 'auto'
  },
  resultsCount: {
    marginTop: theme.spacing(1)
  }
});

const Results = ({
  operators,
  hasSearched,
  searching,
  resultsFound,
  emptyResults,
  classes,
  resultsCount,
  displayingAll
}) => (
  <React.Fragment>
    {hasSearched && resultsFound && !emptyResults && displayingAll && (
      <Typography className={classes.resultsCount}>
        Displaying all Operators
      </Typography>
    )}
    {hasSearched && resultsFound && !emptyResults && !displayingAll && (
      <Typography
        className={classes.resultsCount}
      >{`Displaying ${resultsCount} search ${
        resultsCount === 1 ? 'result' : 'results'
      }:`}</Typography>
    )}
    <Collapse in={!hasSearched}>
      <div className={classes.placeholder}>
        <Typography variant="h3">Operator results will display here</Typography>
      </div>
    </Collapse>
    <Collapse in={hasSearched}>
      <div className={classes.results} id="operators-results-container">
        <Collapse in={searching}>
          <CenterLoadingIcon />
        </Collapse>
        <Collapse in={resultsFound && !searching}>
          {resultsFound && emptyResults && !searching && (
            <div className={classes.placeholder}>
              <Typography variant="h3">No Operators Found</Typography>
            </div>
          )}
          {resultsFound && !emptyResults && !searching && (
            <OperatorsTable operators={operators} />
          )}
        </Collapse>
      </div>
    </Collapse>
  </React.Fragment>
);

Results.propTypes = {
  operators: ImmutablePropTypes.list.isRequired,
  hasSearched: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired,
  resultsFound: PropTypes.bool.isRequired,
  emptyResults: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  searching: isSearching(state),
  hasSearched: hasSearched(state),
  resultsFound: resultsFound(state),
  emptyResults: emptyResults(state),
  operators: getSearchResults(state),
  resultsCount: countOperatorSearchResults(state),
  displayingAll: isDisplayingAllOperators(state)
});

export default withStyles(styles)(connect(mapStateToProps)(Results));
