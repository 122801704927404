import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* createNewEnclosure(data) {
  return yield call(fetchData, 'enclosures', {
    method: 'POST',
    body: { enclosure: data }
  });
}

export function* updateExistingEnclosure(data) {
  const enclosureId = data.get('id');

  return yield call(fetchData, `enclosures/${enclosureId}`, {
    method: 'PATCH',
    body: { enclosure: data }
  });
}
