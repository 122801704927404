// Redux Saga
import { fork, takeLatest, call, put, select } from 'redux-saga/effects';

// Actions
import { SubmissionError } from 'redux-form';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

// Reducer actions
import {
  requestPasswordReset,
  resetPassword,
  verifyToken,
  setTemporaryAuthHeaders
} from './reducer';

// Services
import {
  sendPasswordResetRequest,
  sendResetPassword,
  removeAuthHeaders,
  getAuthHeaders,
  setAuthHeaders
} from '../../services/auth';

// Selectors
import { getTemporaryAuthHeaders } from './selectors';

// Router
import { history } from '../../store';

function* requestPasswordResetSaga() {
  yield takeLatest(requestPasswordReset.REQUEST, handlePasswordResetRequest);
}

function* handlePasswordResetRequest(action) {
  try {
    const { error } = yield call(sendPasswordResetRequest, action.payload);

    if (error) {
      var formattedErrors = castError(error);
      const fieldErrors = new SubmissionError(formattedErrors);
      yield put(requestPasswordReset.failure(fieldErrors));
    } else {
      history.push('/');
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Password reset email sent!'
        })
      );
    }
  } finally {
  }
}

function castError(err) {
  let errors = {};

  if (typeof err == 'string' && err.match(/Not found/)) {
    errors['email'] = ['Email not found'];
  } else {
    errors['_error'] = err;
  }

  return errors;
}

function* resetPasswordSaga() {
  yield takeLatest(resetPassword.REQUEST, handleResetPassword);
}

function* handleResetPassword(action) {
  try {
    let tempHeaders = yield select(getTemporaryAuthHeaders);
    yield call(setAuthHeaders, tempHeaders);
    const { error } = yield call(sendResetPassword, action.payload);

    if (error) {
      yield call(moveAuthHeadersToStore);
      const fieldErrors = new SubmissionError(error);
      yield put(resetPassword.failure(fieldErrors));
    } else {
      yield put(verifyToken());
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Password changed successfully'
        })
      );
    }
  } finally {
  }
}

function* moveAuthHeadersToStore() {
  let headers = yield call(getAuthHeaders);
  yield put(setTemporaryAuthHeaders(headers));
  yield call(removeAuthHeaders);
}

function* sagas() {
  yield fork(requestPasswordResetSaga);
  yield fork(resetPasswordSaga);
}

export default sagas;
