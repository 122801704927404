import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Material UI
import { withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WifiOffIcon from '@material-ui/icons/WifiOff';

const styles = theme => {};

const EnclosureErrorIcon = ({ enclosure, color, className }) => (
  <React.Fragment>
    {!enclosure.get('ok?') &&
      enclosure.get('connection_status') === 'connected' && (
        <ErrorIcon color={color} className={className} />
      )}
    {enclosure.get('connection_status') === 'lost_connection' && (
      <WifiOffIcon color={color} className={className} />
    )}
  </React.Fragment>
);

EnclosureErrorIcon.propTypes = {
  enclosure: ImmutablePropTypes.map.isRequired,
  color: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(styles)(EnclosureErrorIcon);
