import React from 'react';

// Components
import DownloadsMenu from './DownloadsMenu';
import UVCButton from '../Buttons/UVCButton';

// Material UI
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

class Downloads extends React.Component {
  state = {
    menuAnchorEl: null
  };

  handleReportsMenuOpen = event => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  render() {
    const { menuAnchorEl } = this.state;
    const isMenuOpen = Boolean(menuAnchorEl);

    return (
      <div>
        <UVCButton
          text="Reports"
          id="download-options-button"
          aria-label="Reports"
          aria-haspopup="true"
          aria-owns={isMenuOpen ? 'reports-menu' : undefined}
          customIcon={<CloudDownloadIcon />}
          onClick={this.handleReportsMenuOpen}
        />
        <DownloadsMenu
          anchorEl={menuAnchorEl}
          handleMenuClose={this.handleMenuClose}
          isMenuOpen={isMenuOpen}
        />
      </div>
    );
  }
}

Downloads.propTypes = {};

export default Downloads;
