import { getCurrentDepartmentId } from '../Departments/selectors';

const getDepartmentEntities = state => state.departmentsReducer.get('entities');

const getEquipmentTypesForCurrentDepartment = state => {
  let departmentId = getCurrentDepartmentId(state);
  return getDepartmentEntities(state).getIn([
    'departments',
    departmentId,
    'equipment_types'
  ]);
};

export const getEquipmentTypeOptionsForCurrentDepartment = state => {
  let equipmentTypes = getEquipmentTypesForCurrentDepartment(state).toJS();

  equipmentTypes.push({ id: 'custom', name: 'New Equipment Type' });

  return equipmentTypes;
};

export const getProtocols = state =>
  getDepartmentEntities(state).get('protocols');

export const getProtocolById = (state, id) => {
  if (id === undefined) {
    return undefined;
  } else {
    return getProtocols(state).get(id.toString());
  }
};

const getProtocolsReducer = state => state.protocolsReducer;

export const getCustomEquipmentType = state =>
  getProtocolsReducer(state).get('customEquipmentType');
export const getSelectedEquipmentTypeId = state =>
  getProtocolsReducer(state).get('selectedEquipmentTypeId');

export const getProtocolIdsForCurrentDepartment = state => {
  let departmentId = getCurrentDepartmentId(state);
  return getDepartmentEntities(state).getIn([
    'departments',
    departmentId,
    'protocols'
  ]);
};

export const getSelectedProtocolId = state => {
  let protocolIds = getProtocolIdsForCurrentDepartment(state);
  return protocolIds
    .filter(
      protocolId =>
        getProtocolById(state, protocolId).get('equipment_type_id') ===
        getSelectedEquipmentTypeId(state)
    )
    .get(0);
};

export const getEquipmentTypeById = (state, id) => {
  return getEquipmentTypesForCurrentDepartment(state).find(
    equipmentType => equipmentType.get('id') === id
  );
};

export const isAttemptingDeactivation = (state, id) =>
  getProtocolsReducer(state)
    .get('attemptingDeactivationFor')
    .includes(id);
