import { takeLatest, fork, call, put, take, cancel } from 'redux-saga/effects';

// Reducer actions
import {
  fetchDepartmentRequest,
  fetchDepartmentError,
  fetchDepartmentSuccess,
  createDepartment,
  unloadDepartment,
  updateDepartment,
  setCurrentDepartmentId
} from './reducer';
import { closeModal } from '../../components/Modal/reducer';
import { reloadFacilityRequest } from '../Facilities/reducer';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

// Services
import {
  fetchDepartment,
  createNewDepartment,
  updateExistingDepartment
} from '../../services/departments';

// Redux Form
import { SubmissionError } from 'redux-form';

// Normalizers
import { departmentNormalizer } from './Normalizer';

function* getDepartmentSaga() {
  yield takeLatest(fetchDepartmentRequest, handleGetDepartmentSaga);
}

function* handleGetDepartmentSaga(action) {
  const fetchRequest = yield fork(handleFetchDepartment, action.payload);

  yield take(unloadDepartment);
  yield cancel(fetchRequest);
}

function* handleFetchDepartment(payload) {
  try {
    const { result, error } = yield call(fetchDepartment, payload);

    if (error) {
      yield put(fetchDepartmentError(error));
      yield put(showSnackbar({ variant: 'error', message: error }));
    } else {
      yield put(fetchDepartmentSuccess(departmentNormalizer(result)));
      yield put(setCurrentDepartmentId(result.id.toString()));
    }
  } finally {
  }
}

function* createDepartmentSaga() {
  yield takeLatest(createDepartment.REQUEST, handleCreateDepartmentSaga);
}

function* handleCreateDepartmentSaga(action) {
  const facilityId = action.payload.get('facility_id');

  try {
    const { error } = yield call(createNewDepartment, action.payload);

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(createDepartment.failure(fieldErrors));
    } else {
      yield put(closeModal());
      yield put(reloadFacilityRequest(facilityId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'New department added to facility.'
        })
      );
    }
  } finally {
  }
}

function* updateDepartmentSaga() {
  yield takeLatest(updateDepartment.REQUEST, handleUpdateDepartmentSaga);
}

function* handleUpdateDepartmentSaga(action) {
  const facilityId = action.payload.get('facility_id');
  const departmentId = action.payload.get('id');

  try {
    const { error } = yield call(updateExistingDepartment, action.payload);

    if (error) {
      const fieldErrors = new SubmissionError(error);
      yield put(updateDepartment.failure(fieldErrors));
    } else {
      yield put(reloadFacilityRequest(facilityId));
      yield put(closeModal());
      yield put(unloadDepartment());
      yield put(fetchDepartmentRequest(departmentId));
      yield put(
        showSnackbar({
          variant: 'success',
          message: 'Department updated successfully.'
        })
      );
    }
  } finally {
  }
}

function* sagas() {
  yield fork(getDepartmentSaga);
  yield fork(createDepartmentSaga);
  yield fork(updateDepartmentSaga);
}

export default sagas;
