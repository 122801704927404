import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* searchOperators(facilityId, search = null) {
  let path = `facilities/${facilityId}/operators`;

  if (search) {
    path += `?search=${search}`;
  }

  return yield call(fetchData, path, {
    method: 'GET'
  });
}

export function* deactivateOperator(facilityId, operatorId) {
  let path = `facilities/${facilityId}/operators/${operatorId}`;

  return yield call(fetchData, path, {
    method: 'PATCH',
    body: { active: false }
  });
}

export function* uploadOperatorsFile(facilityId, data) {
  if (!data.get('operators_upload')) {
    return { error: { operators_upload: 'Please select a file for upload' } };
  } else {
    let postData = new FormData();

    postData.append('operator[operators_upload]', data.get('operators_upload'));

    if (data.get('deactivate_duplicate_badges')) {
      postData.append(
        'operator[deactivate_duplicate_badges]',
        data.get('deactivate_duplicate_badges')
      );
    }

    return yield call(fetchData, `facilities/${facilityId}/operators`, {
      method: 'POST',
      body: postData,
      isFormData: true
    });
  }
}

export function* createNewOperator(facilityId, data) {
  let path = `facilities/${facilityId}/operators`;

  return yield call(fetchData, path, {
    method: 'POST',
    body: data
  });
}

export function* updateOperator(facilityId, data) {
  let path = `facilities/${facilityId}/operators/${data.operatorId}`;

  return yield call(fetchData, path, {
    method: 'PATCH',
    body: { operator: { department_id: data.departmentId } }
  });
}
