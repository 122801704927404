import React from 'react';
import PropTypes from 'prop-types';

// Components
import HeaderLogo from './headerLogo';
import UserMenu from './UserMenu';
import Notifications from './Notifications';

// Functional Components
import { Link } from 'react-router-dom';

// Material UI Components
import { withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white'
  },
  right: {
    float: 'right'
  }
});

class Header extends React.Component {
  state = {
    menuAnchorEl: null
  };

  handleUserMenuOpen = event => {
    this.setState({ menuAnchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { menuAnchorEl } = this.state;
    const isMenuOpen = Boolean(menuAnchorEl);

    return (
      <React.Fragment>
        <AppBar position="absolute" className={classes.appBar}>
          <Toolbar>
            <HeaderLogo className={classes.logo} />
            <Tooltip title="My Facilities" enterDelay={250}>
              <IconButton
                component={Link}
                to="/my_facilities"
                id="facility-icon-link"
                aria-label="My Facilities"
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications" enterDelay={250}>
              <IconButton
                component={Link}
                to="/enclosure_logs"
                id="enclosure-logs-link"
                aria-label="Notifications"
              >
                <Notifications />
              </IconButton>
            </Tooltip>
            <Tooltip title="Account Actions" enterDelay={250}>
              <IconButton
                id="account-actions-button"
                aria-owns={isMenuOpen ? 'user-menu' : undefined}
                aria-haspopup="true"
                aria-label="Account Actions"
                onClick={this.handleUserMenuOpen}
                className={classes.right}
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <UserMenu
          anchorEl={menuAnchorEl}
          handleMenuClose={this.handleMenuClose}
          isMenuOpen={isMenuOpen}
        />
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
