import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Components
import EnclosureFields from './Fields';
import SoftwareFields from './SoftwareFields';
import Modal from '../../components/Modal';
import FormDialog from '../../components/FormDialog';
import EnclosureWarning from '../../components/Enclosures/EnclosureWarning';
import ReactTimeAgo from 'react-time-ago';
import {
  canonical // '1 second ago', '2 minutes ago', …
} from 'javascript-time-ago/gradation';

// Selectors
import { getEnclosureById } from './selectors';
import { isUVCAdmin, isFacilityAdmin } from '../Auth/selectors';

// Actions
import { showModal } from '../../components/Modal/reducer';
import { updateEnclosure } from './reducer';

// Material UI Components
import {
  Paper,
  Typography,
  Grid,
  withStyles,
  IconButton,
  Divider,
  Link
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  secondaryInfo: {
    padding: theme.spacing(2)
  },
  infoKey: {
    textAlign: 'right',
    paddingRight: theme.spacing(2)
  },
  nameAndVersion: {
    flexGrow: 1,
    alignItems: 'center'
  },
  versionLink: {
    marginLeft: theme.spacing(1)
  },
  warningIcon: {
    marginRight: theme.spacing(1)
  }
});

class Enclosure extends React.Component {
  editModalName = `EditEnclosureModal-${this.props.enclosureId}`;
  softwareModalName = `EnclosureSoftwareModal-${this.props.enclosureId}`;

  render() {
    const {
      classes,
      enclosure,
      editForm,
      softwareForm,
      showModal,
      canEditEnclosure,
      isUVCAdmin
    } = this.props;
    const nickname = enclosure.get('nickname');
    const udi = enclosure.get('udi');
    const lastCheckIn = enclosure.get('last_check_in');

    return (
      <React.Fragment>
        {
          <Modal
            name={this.editModalName}
            render={() => (
              <FormDialog
                title="Edit Enclosure"
                buttonText="Save"
                form={editForm}
                initialValues={enclosure}
                onSubmit={updateEnclosure}
              >
                <EnclosureFields />
              </FormDialog>
            )}
          />
        }
        {isUVCAdmin && (
          <Modal
            name={this.softwareModalName}
            render={() => (
              <FormDialog
                title={
                  (enclosure.get('nickname') || enclosure.get('udi')) +
                  ' - Software Information'
                }
                buttonText="Save"
                form={softwareForm}
                initialValues={enclosure}
                onSubmit={updateEnclosure}
              >
                <SoftwareFields enclosure={enclosure} />
              </FormDialog>
            )}
          />
        )}
        <Paper className={classes.paper}>
          <Grid container alignItems="flex-end" justify="space-between">
            <Grid item>
              <Grid
                container
                className={classes.nameAndVersion}
                alignItems="baseline"
              >
                <Grid item>
                  <Typography variant="h3">{nickname || udi}</Typography>
                </Grid>
                {isUVCAdmin && (
                  <Grid item className={classes.versionLink}>
                    <Link
                      component="button"
                      onClick={() => showModal(this.softwareModalName)}
                    >
                      {enclosure.get('software_version') || 'Version Unknown'}
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              {canEditEnclosure && (
                <IconButton
                  className="edit-enclosure-icon-button"
                  onClick={() => showModal(this.editModalName)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {enclosure.get('ok?') === false &&
            enclosure.get('connection_status') !== 'not_yet_connected' && (
              <EnclosureWarning enclosure={enclosure} />
            )}
          <Divider />
          <Grid container className={classes.secondaryInfo}>
            {[
              {
                'Last checked in': lastCheckIn ? (
                  <ReactTimeAgo
                    date={lastCheckIn}
                    timeStyle={{ gradation: canonical }}
                  />
                ) : (
                  'Enclosure not yet connected'
                ),
                Location: enclosure.get('location')
              }
            ].map(section => (
              <Grid
                item
                xs={4}
                container
                direction="column"
                spacing={0}
                key={`${udi}-column-1`}
              >
                {Object.keys(section).map((key, index) => (
                  <Grid item container key={index}>
                    <Grid item xs={4}>
                      <Typography className={classes.infoKey}>
                        {key}:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{section[key]}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
            {[
              {
                'Due date on tags': enclosure.get('display_due_date_on_tags')
                  ? 'Yes'
                  : 'No'
              },
              {
                UDI: enclosure.get('udi'),
                'Configuration Type': enclosure.get('configuration_type'),
                OS: enclosure.get('operating_system')
              }
            ].map((section, index) => (
              <Grid
                item
                xs={4}
                container
                direction="column"
                key={`${udi}-section-${index}`}
              >
                {Object.keys(section).map((key, index) => (
                  <Grid item container key={index}>
                    <Grid item xs={6}>
                      <Typography className={classes.infoKey}>
                        {key}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{section[key]}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

Enclosure.propTypes = {
  enclosureId: PropTypes.string.isRequired,
  editForm: PropTypes.string.isRequired,
  softwareForm: PropTypes.string.isRequired,
  enclosure: ImmutablePropTypes.map.isRequired,
  showModal: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  enclosure: getEnclosureById(state, ownProps.enclosureId),
  canEditEnclosure: isUVCAdmin(state) || isFacilityAdmin(state),
  isUVCAdmin: isUVCAdmin(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Enclosure));
