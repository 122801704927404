import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import {
  searchOperatorsRequest,
  searchOperatorsReset,
  getAllOperators
} from './reducer';

// Selectors
import { isDisplayingAllOperators } from './selectors';

// Components
import SearchInput from '../../components/Search/SearchInput';
import TextButton from '../../components/Buttons/TextButton';

// Material UI Components
import { withStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const styles = theme => ({
  searchContainer: {
    display: 'flex'
  }
});

const OperatorsSearch = ({
  searchOperators,
  displayingAll,
  toggleAllOperators,
  totalCount,
  classes
}) => (
  <div className={classes.searchContainer}>
    <SearchInput
      label="Search Operators"
      fieldId="operators-search"
      onSearch={searchOperators}
    />
    <TextButton
      id="operators-count-button"
      text={`Total Count: ${totalCount}`}
      onClick={() => toggleAllOperators(displayingAll)}
    >
      {displayingAll ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </TextButton>
  </div>
);

OperatorsSearch.propTypes = {
  searchOperators: PropTypes.func.isRequired,
  toggleAllOperators: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  displayingAll: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  displayingAll: isDisplayingAllOperators(state)
});

const mapDispatchToProps = dispatch => ({
  searchOperators: e => dispatch(searchOperatorsRequest(e.target.value)),
  toggleAllOperators: currentlyShowing => {
    if (currentlyShowing) {
      return dispatch(searchOperatorsReset());
    } else {
      document.getElementById('operators-search').value = '';
      return dispatch(getAllOperators());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OperatorsSearch));
