import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Selectors
import {
  hasSearched,
  isSearching,
  resultsFound,
  emptyResults,
  countEquipmentItemSearchResults
} from './selectors';

// Components
import CenterLoadingIcon from '../../components/CenterLoadingIcon';
import EquipmentItemsTable from './EquipmentItemsTable';

// Selectors
import { getSearchResults } from './selectors';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';

const styles = theme => ({
  placeholder: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(2),
    height: '40rem',
    overflow: 'auto'
  },
  resultsCount: {
    marginTop: theme.spacing(1)
  }
});

class Results extends React.Component {
  render() {
    const {
      equipmentItems,
      hasSearched,
      searching,
      resultsFound,
      emptyResults,
      classes,
      resultsCount
    } = this.props;

    return (
      <React.Fragment>
        {hasSearched && resultsFound && !emptyResults && (
          <Typography
            className={classes.resultsCount}
          >{`Displaying ${resultsCount} search ${
            resultsCount === 1 ? 'result' : 'results'
          }:`}</Typography>
        )}
        <Collapse in={!hasSearched}>
          <div className={classes.placeholder}>
            <Typography variant="h3">
              Equipment Item results will display here
            </Typography>
          </div>
        </Collapse>
        <Collapse in={hasSearched}>
          <div
            className={classes.results}
            id="equipment-items-results-container"
          >
            <Collapse in={searching}>
              <CenterLoadingIcon />
            </Collapse>
            <Collapse in={resultsFound && !searching}>
              {resultsFound && emptyResults && !searching && (
                <div className={classes.placeholder}>
                  <Typography variant="h3">No Equipment Found</Typography>
                </div>
              )}
              {resultsFound && !emptyResults && !searching && (
                <EquipmentItemsTable equipmentItems={equipmentItems} />
              )}
            </Collapse>
          </div>
        </Collapse>
      </React.Fragment>
    );
  }
}

Results.propTypes = {
  equipmentItems: ImmutablePropTypes.list.isRequired,
  hasSearched: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired,
  resultsFound: PropTypes.bool.isRequired,
  emptyResults: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  hasSearched: hasSearched(state),
  searching: isSearching(state),
  resultsFound: resultsFound(state),
  emptyResults: emptyResults(state),
  equipmentItems: getSearchResults(state),
  resultsCount: countEquipmentItemSearchResults(state)
});

export default withStyles(styles)(connect(mapStateToProps)(Results));
