import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Colors from '../../styles/colors';

import EnclosureErrorIcon from '../../components/Enclosures/EnclosureErrorIcon';

// Material UI
import { withStyles, Paper, Typography, Link } from '@material-ui/core';

const enclosureStatusMessage = enclosure => {
  switch (enclosure.get('connection_status')) {
    case 'not_yet_connected':
      return 'UVE not yet connected.';
    case 'lost_connection':
      return 'UVE is not connecting to the cloud.';
    case 'connected':
      return 'UVE is not operational.';
  }
};

const styles = theme => ({
  errorContainer: {
    backgroundColor: theme.palette.error.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'inline-flex',
    alignItems: 'center'
  },
  errorIcon: {
    color: Colors.white
  },
  errorMessage: {
    marginLeft: theme.spacing(1),
    color: Colors.white,
    fontWeight: 'bold'
  },
  telephoneLink: {
    color: Colors.white
  }
});

const EnclosureWarning = ({ enclosure, classes }) => (
  <Paper className={classes.errorContainer}>
    <EnclosureErrorIcon enclosure={enclosure} className={classes.errorIcon} />

    <Typography className={classes.errorMessage}>
      {`${enclosureStatusMessage(enclosure)} Please contact UV-C Support at `}
      <Link href="tel:17203101101" className={classes.telephoneLink}>
        720-310-1101
      </Link>
      .
    </Typography>
  </Paper>
);

EnclosureWarning.propTypes = {
  enclosure: ImmutablePropTypes.map.isRequired
};

export default withStyles(styles)(EnclosureWarning);
