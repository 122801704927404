import { createFormAction } from 'redux-form-saga';
import { createAction, handleActions } from 'redux-actions';

import { fromJS } from 'immutable';

// Actions
export const constants = {
  CREATE_EQUIPMENT_ITEM: 'containers/EquipmentItems/CREATE_EQUIPMENT_ITEM',
  REMOVE_EQUIPMENT_ITEM_REQUEST:
    'containers/EquipmentItems/REMOVE_EQUIPMENT_ITEM_REQUEST',
  REMOVE_EQUIPMENT_ITEM_SUCCESS:
    'containers/EquipmentItems/REMOVE_EQUIPMENT_ITEM_SUCCESS',
  REMOVE_EQUIPMENT_ITEM_FAILURE:
    'containers/EquipmentItems/REMOVE_EQUIPMENT_ITEM_FAILURE',
  SEARCH_EQUIPMENT_ITEMS_RESET:
    'containers/EquipmentItems/SEARCH_EQUIPMENT_ITEMS_RESET',
  SEARCH_EQUIPMENT_ITEMS_REQUEST:
    'containers/EquipmentItems/SEARCH_EQUIPMENT_ITEMS_REQUEST',
  SEARCH_EQUIPMENT_ITEMS_SUCCESS:
    'containers/EquipmentItems/SEARCH_EQUIPMENT_ITEMS_SUCCESS',
  SEARCH_EQUIPMENT_ITEMS_FAILURE:
    'containers/EquipmentItems/SEARCH_EQUIPMENT_ITEMS_FAILURE'
};

export const createEquipmentItem = createFormAction(
  constants.CREATE_EQUIPMENT_ITEM
);

export const removeEquipmentItemRequest = createAction(
  constants.REMOVE_EQUIPMENT_ITEM_REQUEST
);
export const removeEquipmentItemSuccess = createAction(
  constants.REMOVE_EQUIPMENT_ITEM_SUCCESS
);
export const removeEquipmentItemFailure = createAction(
  constants.REMOVE_EQUIPMENT_ITEM_FAILURE
);
export const searchEquipmentItemsReset = createAction(
  constants.SEARCH_EQUIPMENT_ITEMS_RESET
);
export const searchEquipmentItemsRequest = createAction(
  constants.SEARCH_EQUIPMENT_ITEMS_REQUEST
);
export const searchEquipmentItemsSuccess = createAction(
  constants.SEARCH_EQUIPMENT_ITEMS_SUCCESS
);
export const searchEquipmentItemsFailure = createAction(
  constants.SEARCH_EQUIPMENT_ITEMS_FAILURE
);

const initialState = fromJS({
  attemptingDeactivationFor: [],
  deactivationError: null,
  hasSearched: false,
  isSearching: false,
  resultsFound: false,
  searchError: null,
  searchResults: []
});

const equipmentItemsReducer = handleActions(
  {
    [removeEquipmentItemRequest]: (state, action) => {
      let newList = state.get('attemptingDeactivationFor').push(action.payload);
      return state.set('attemptingDeactivationFor', newList);
    },

    [removeEquipmentItemSuccess]: (state, action) => {
      let newResults = state
        .get('searchResults')
        .filterNot(result => result.get('id') === action.payload);
      return state.set('searchResults', newResults);
    },

    [removeEquipmentItemFailure]: (state, action) => {
      let newList = state
        .get('attemptingDeactivationFor')
        .filterNot(id => id === action.paylod);
      return state.set('attemptingDeactivationFor', newList);
    },

    [searchEquipmentItemsReset]: state =>
      state
        .set('hasSearched', false)
        .set('isSearching', false)
        .set('resultsFound', false)
        .set('searchError', null)
        .set('searchResults', fromJS([])),

    [searchEquipmentItemsRequest]: state =>
      state
        .set('hasSearched', true)
        .set('isSearching', true)
        .set('resultsFound', false)
        .set('searchError', null)
        .set('searchResults', fromJS([])),

    [searchEquipmentItemsSuccess]: (state, action) =>
      state
        .set('hasSearched', true)
        .set('isSearching', false)
        .set('resultsFound', true)
        .set('searchError', null)
        .set('searchResults', fromJS(action.payload)),

    [searchEquipmentItemsFailure]: (state, action) =>
      state
        .set('hasSearched', true)
        .set('isSearching', false)
        .set('resultsFound', true)
        .set('searchError', action.payload)
        .set('searchResults', fromJS([]))
  },
  initialState
);

export default equipmentItemsReducer;
