import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

// Actions
export const constants = {
  EDIT_FACILITY: 'containers/Facilities/EDIT_FACILITY',
  UNSET_CURRENT_FACILITY: 'containers/Facilities/UNSET_CURRENT_FACILITY',
  UNLOAD_FACILITY: 'containers/Facilities/UNLOAD_FACILITY',
  FETCH_FACILITY_REQUEST: 'containers/Facilities/FETCH_FACILITY_REQUEST',
  FETCH_FACILITY_ERROR: 'containers/Facilities/FETCH_FACILITY_ERROR',
  FETCH_FACILITY_SUCCESS: 'containers/Facilities/FETCH_FACILITY_SUCCESS',
  RELOAD_FACILITY_REQUEST: 'containers/Facilities/RELOAD_FACILITY_REQUEST',
  FETCH_FACILITIES_REQUEST: 'containers/Facilities/FETCH_FACILITIES_REQUEST',
  FETCH_FACILITIES_ERROR: 'containers/Facilities/FETCH_FACILITIES_ERROR',
  FETCH_FACILITIES_SUCCESS: 'containers/Facilities/FETCH_FACILITIES_SUCCESS',
  RELOAD_FACILITIES_REQUEST: 'containers/Facilities/RELOAD_FACILITIES_REQUEST',
  SET_CURRENT_FACILITY_ID: 'containers/Facilities/SET_CURRENT_FACILITY_ID',
  CREATE_FACILITY: 'containers/Facilities/CREATE_FACILITY',
  UPDATE_FACILITY: 'containers/Facilities/UPDATE_FACILITY'
};

export const editFacility = createAction(constants.EDIT_FACILITY);
export const fetchFacilityRequest = createAction(
  constants.FETCH_FACILITY_REQUEST
);
export const fetchFacilityError = createAction(constants.FETCH_FACILITY_ERROR);
export const fetchFacilitySuccess = createAction(
  constants.FETCH_FACILITY_SUCCESS
);
export const reloadFacilityRequest = createAction(
  constants.RELOAD_FACILITY_REQUEST
);

export const fetchFacilitiesRequest = createAction(
  constants.FETCH_FACILITIES_REQUEST
);
export const fetchFacilitiesError = createAction(
  constants.FETCH_FACILITIES_ERROR
);
export const fetchFacilitiesSuccess = createAction(
  constants.FETCH_FACILITIES_SUCCESS
);
export const reloadFacilitiesRequest = createAction(
  constants.RELOAD_FACILITIES_REQUEST
);

export const unloadFacility = createAction(constants.UNLOAD_FACILITY);
export const setCurrentFacilityId = createAction(
  constants.SET_CURRENT_FACILITY_ID
);

// Form actions
export const createFacility = createFormAction(constants.CREATE_FACILITY);
export const updateFacility = createFormAction(constants.UPDATE_FACILITY);

const initialState = fromJS({
  entities: {
    facilities: {},
    enclosures: {},
    users: {},
    departments: {},
    operators: {},
    softwarePackages: {}
  },
  loadingFacility: true,
  loadedFacility: false,
  errorLoadingFacility: false,
  loadingFacilities: true,
  loadedFacilities: false,
  errorLoadingFacilities: false,
  editing: null,
  currentFacilityId: null
});

// Reducers
const facilitiesReducer = handleActions(
  {
    [editFacility]: (state, action) => state.set('editing', action.payload),

    [fetchFacilityRequest]: state =>
      state
        .set('errorLoadingFacility', false)
        .set('loadingFacility', true)
        .set('loadedFacility', false),

    [fetchFacilityError]: state =>
      state
        .set('errorLoadingFacility', true)
        .set('loadingFacility', false)
        .set('loadedFacility', false),

    [fetchFacilitySuccess]: (state, action) => {
      return state
        .merge(fromJS(action.payload))
        .set('errorLoadingFacility', false)
        .set('loadingFacility', false)
        .set('loadedFacility', true);
    },

    [reloadFacilityRequest]: state => state.set('errorLoadingFacility', false),

    [fetchFacilitiesRequest]: state =>
      state
        .set('loadingFacilities', true)
        .set('loadedFacilities', false)
        .set('errorLoadingFacilities', false),

    [fetchFacilitiesError]: state =>
      state
        .set('loadingFacilities', false)
        .set('loadedFacilities', false)
        .set('errorLoadingFacilities', true),

    [fetchFacilitiesSuccess]: (state, action) =>
      state
        .set('loadingFacilities', false)
        .set('loadedFacilities', true)
        .set('errorLoadingFacilities', false)
        .set('entities', fromJS(action.payload).get('entities')),

    [reloadFacilitiesRequest]: state =>
      state.set('errorLoadingFacilities', false),

    [unloadFacility]: state =>
      state
        .set('errorLoadingFacility', false)
        .set('loadingFacility', true)
        .set('loadedFacility', false)
        .set('currentFacilityId', null),

    [setCurrentFacilityId]: (state, action) => {
      return state.set('currentFacilityId', action.payload);
    }
  },
  initialState
);

export default facilitiesReducer;
