import React from 'react';
import PropTypes from 'prop-types';

import UVCButton from '../Buttons/UVCButton';

// Material UI Components
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  paperActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  }
});

const PaperActions = ({
  primaryButtonText,
  handleClick,
  buttonId,
  classes
}) => (
  <div className={classes.paperActions}>
    <UVCButton
      id={buttonId}
      addIcon
      text={primaryButtonText}
      onClick={handleClick}
    />
  </div>
);

PaperActions.propTypes = {
  primaryButtonText: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PaperActions);
