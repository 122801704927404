import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Actions
import { resendInvitation } from './reducer';

// Selectors
import {
  getUserRoleByIdAtCurrentFacility,
  getRolesForDisplay
} from './selectors';
import { isUVCAdmin, isFacilityAdmin } from '../Auth/selectors';

// Components
import UserRoleForm from './RoleForm';
import EmailButton from '../../components/Buttons/EmailButton';

// Material UI Components
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  pendingIndicator: {
    fontStyle: 'italic',
    color: theme.palette.primary.dark
  }
});

class UserRow extends React.Component {
  render() {
    const {
      user,
      resendInvitation,
      canEditUserRole,
      facilityId,
      currentFacilityRole,
      userRoles,
      classes
    } = this.props;

    const userId = user.get('id').toString();
    const invitationPending = user.get('invitation_pending');

    return (
      <TableRow key={userId}>
        {invitationPending && (
          <TableCell>
            {canEditUserRole && (
              <EmailButton
                onClick={() => resendInvitation(userId, facilityId)}
                text="Resend Invitation"
              ></EmailButton>
            )}
          </TableCell>
        )}
        {!invitationPending && <TableCell>{user.get('name')}</TableCell>}
        <TableCell>{user.get('email')}</TableCell>
        <TableCell>
          {canEditUserRole && (
            <UserRoleForm
              form={`userRoleForm-${userId}`}
              initialValues={{ role_name: currentFacilityRole }}
              facilityId={facilityId}
              userId={userId}
            />
          )}
          {!canEditUserRole && userRoles[currentFacilityRole]}
        </TableCell>
        <TableCell>
          {invitationPending && (
            <Typography className={classes.pendingIndicator}>
              Pending
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

UserRow.propTypes = {
  facilityId: PropTypes.number.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  canEditUserRole: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  currentFacilityRole: getUserRoleByIdAtCurrentFacility(
    state,
    ownProps.user.get('id')
  ),
  canEditUserRole: isUVCAdmin(state) || isFacilityAdmin(state),
  userRoles: getRolesForDisplay(state)
});

const mapDispatchToProps = dispatch => ({
  resendInvitation: (id, facilityId) => {
    dispatch(resendInvitation(id, facilityId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserRow));
