import React from 'react';
import PropTypes from 'prop-types';

// Redux Form
import { Field, reduxForm } from 'redux-form/immutable';
import { createTextMask } from 'redux-form-input-masks';

// Actions
import { acceptInvitation } from './reducer';

// Helpers
import { renderTextField } from '../../components/Form/helpers';

// Components
import UVCButton from '../../components/Buttons/UVCButton';

// MUI Components
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const phoneMask = createTextMask({
  pattern: '(999) 999-9999'
});

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  progress: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  helperText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const AcceptInvitationForm = ({
  handleSubmit,
  submitting,
  submitted,
  classes
}) => (
  <form onSubmit={handleSubmit(acceptInvitation)} className={classes.form}>
    <Typography className={classes.helperText}>
      Please provide the following information to complete your account setup
      and log in.
    </Typography>
    <Typography component="h4" variant="h6">
      Profile
    </Typography>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Field
          margin="dense"
          id="first_name"
          name="profile.first_name"
          label="First Name"
          required
          fullWidth
          autoFocus
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          margin="dense"
          id="last_name"
          name="profile.last_name"
          label="Last Name"
          required
          fullWidth
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          margin="dense"
          id="title"
          name="profile.title"
          label="Title"
          fullWidth
          required
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          margin="dense"
          id="employee_id"
          name="profile.employee_id"
          label="Employee ID"
          fullWidth
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          margin="dense"
          id="department"
          name="profile.department"
          label="Department"
          fullWidth
          required
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          margin="dense"
          id="phone_number"
          name="profile.phone_number"
          label="Phone Number"
          fullWidth
          required
          component={renderTextField}
          {...phoneMask}
        />
      </Grid>
    </Grid>
    <Divider className={classes.divider} />
    <Typography component="h4" variant="h6">
      Password
    </Typography>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Field
          margin="dense"
          id="password"
          name="password"
          label="Password"
          type="password"
          required
          fullWidth
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          margin="dense"
          id="password_confirmation"
          name="password_confirmation"
          label="Password Confirmation"
          type="password"
          required
          fullWidth
          component={renderTextField}
        />
      </Grid>
    </Grid>
    {!submitting && (
      <div className={classes.submit}>
        <UVCButton
          id="create-account"
          type="submit"
          text="Create Account"
          fullWidth
          disabled={submitted}
        />
      </div>
    )}
    {submitting && (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    )}
  </form>
);

AcceptInvitationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired
};

export default withStyles(styles)(reduxForm({})(AcceptInvitationForm));
