import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Modal from '../Modal';
import FormDialog from '../FormDialog';
import ProfileFields from '../../containers/Profiles/Fields';

// Actions
import { logout } from '../../containers/Auth/reducer';
import { showModal } from '../Modal/reducer';
import { updateProfile } from '../../containers/Profiles/reducer';

// Selectors
import { getCurrentUserProfile } from '../../containers/Profiles/selectors';

// Material UI Components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const UserMenu = ({
  anchorEl,
  handleMenuClose,
  isMenuOpen,
  signOutUser,
  showModal,
  profile
}) => (
  <React.Fragment>
    <Modal
      name="EditProfileModal"
      render={() => (
        <FormDialog
          title="Edit Profile"
          buttonText="Save Profile"
          form="editProfileForm"
          initialValues={profile}
          onSubmit={updateProfile}
        >
          <ProfileFields />
        </FormDialog>
      )}
    />
    <Menu
      id="user-menu"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          return showModal('EditProfileModal');
        }}
      >
        My Profile
      </MenuItem>
      <MenuItem onClick={signOutUser}>Sign Out</MenuItem>
    </Menu>
  </React.Fragment>
);

UserMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleMenuClose: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  profile: getCurrentUserProfile(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName)),
  signOutUser: () => {
    dispatch(logout());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenu);
