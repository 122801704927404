import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

// Actions
export const constants = {
  SHOW_SNACKBAR: 'Snackbar/SHOW_SNACKBAR',
  CLOSE_SNACKBAR: 'Snackbar/CLOSE_SNACKBAR'
};

export const showSnackbar = createAction(constants.SHOW_SNACKBAR);
export const closeSnackbar = createAction(constants.CLOSE_SNACKBAR);

const initialState = fromJS({
  isOpen: false,
  variant: 'info',
  message: ''
});

// Reducers
const snackbarReducer = handleActions(
  {
    [showSnackbar]: (state, action) =>
      state
        .set('variant', fromJS(action.payload).get('variant'))
        .set('message', fromJS(action.payload).get('message'))
        .set('isOpen', true),
    [closeSnackbar]: state => state.set('isOpen', false)
  },
  initialState
);

export default snackbarReducer;
