import { fork, all } from 'redux-saga/effects';
import formActionSaga from 'redux-form-saga';

import authSagas from './containers/Auth/sagas';
import facilitySagas from './containers/Facilities/sagas';
import userSagas from './containers/Users/sagas';
import enclosureSagas from './containers/Enclosures/sagas';
import departmentSagas from './containers/Departments/sagas';
import equipmentItemSagas from './containers/EquipmentItems/sagas';
import protocolSagas from './containers/Protocols/sagas';
import operatorsSagas from './containers/Operators/sagas';
import profilesSagas from './containers/Profiles/sagas';
import runCycleReportSagas from './containers/RunCycleReports/sagas';
import disinfectionReportSagas from './containers/DisinfectionReports/sagas';
import resetPasswordSagas from './containers/Auth/resetPasswordSagas';

const sagas = [
  authSagas,
  facilitySagas,
  userSagas,
  enclosureSagas,
  departmentSagas,
  equipmentItemSagas,
  protocolSagas,
  operatorsSagas,
  profilesSagas,
  runCycleReportSagas,
  disinfectionReportSagas,
  resetPasswordSagas,
  formActionSaga
];

function* globalSagas() {
  const globalSagasForks = sagas.map(saga => fork(saga));
  yield all([...globalSagasForks]);
}

export default globalSagas;
