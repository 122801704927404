import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Actions
import { updateOperatorDepartment } from '../../containers/Operators/reducer';

// Redux Form
import { reduxForm } from 'redux-form/immutable';
import { Field } from 'redux-form/immutable';

// Selectors
import { getDepartmentsAtCurrentFacility } from '../../containers/Departments/selectors';
import { isUpdatingDepartment } from '../../containers/Operators/selectors';

// Helpers
import { renderSelectField } from '../../components/Form/helpers';

// Material UI
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const OperatorDepartmentForm = ({
  updateDepartment,
  updating,
  departments,
  initialValues
}) => (
  <form>
    {updating && <CircularProgress />}
    {!updating && (
      <Field
        margin="dense"
        name="department_id"
        label=""
        component={renderSelectField}
        format={(value, name) => (value == null ? ' ' : value)}
        onChange={updateDepartment}
      >
        {initialValues.get('department_id') == null && (
          <MenuItem key={0} value={' '} disabled>
            Unknown
          </MenuItem>
        )}
        {departments.valueSeq().map(department => (
          <MenuItem key={department.get('id')} value={department.get('id')}>
            {department.get('title')}
          </MenuItem>
        ))}
      </Field>
    )}
  </form>
);

OperatorDepartmentForm.propTypes = {
  initialValues: ImmutablePropTypes.map.isRequired,
  updating: PropTypes.bool.isRequired,
  form: PropTypes.string.isRequired,
  operatorId: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  departments: getDepartmentsAtCurrentFacility(state),
  updating: isUpdatingDepartment(state, ownProps.operatorId)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateDepartment: event => {
    let operatorAttributes = {
      departmentId: event.target.value,
      operatorId: ownProps.operatorId
    };

    return dispatch(updateOperatorDepartment(operatorAttributes));
  }
});

export default reduxForm({})(
  connect(mapStateToProps, mapDispatchToProps)(OperatorDepartmentForm)
);
