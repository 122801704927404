import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* requestRunCycleReport(
  facilityId,
  startDate = null,
  endDate = null
) {
  let path = `facilities/${facilityId}/run_cycle_reports?format=csv`;

  if (startDate) {
    path += `&start_date=${startDate}`;
  }

  if (endDate) {
    path += `&end_date=${endDate}`;
  }

  return yield call(fetchData, path, { responseType: 'blob' });
}
