import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { Field } from 'redux-form/immutable';
import {
  renderSelectField,
  renderTextField,
  renderCheckbox,
  renderFileUpload
} from '../../components/Form/helpers';
import EquipmentItemsTemplateLink from '../EquipmentItems/TemplateLink';

// Selectors
import {
  getEquipmentTypeOptionsForCurrentDepartment,
  getSelectedProtocolId,
  getProtocolById,
  getCustomEquipmentType,
  getSelectedEquipmentTypeId
} from './selectors';

// Actions
import { setCustomEquipmentType, setSelectedEquipmentTypeId } from './reducer';

// Material UI Components
import { withStyles } from '@material-ui/core';
import {
  MenuItem,
  Grid,
  FormGroup,
  FormControlLabel,
  Typography,
  Popover,
  InputAdornment
} from '@material-ui/core';

const styles = theme => ({
  selectField: {
    minWidth: 150
  },
  helperText: {
    marginBottom: theme.spacing(2)
  },
  tooltipText: {
    margin: theme.spacing(1)
  }
});

class ProtocolFields extends React.Component {
  state = {
    anchorEl: null
  };

  handleChange = e => {
    if (e.target.value === 'custom') {
      this.props.setCustomEquipmentType(true);
      this.props.setSelectedEquipmentTypeId('custom');
    } else {
      this.props.setCustomEquipmentType(false);
      this.props.setSelectedEquipmentTypeId(e.target.value);
    }
  };

  handleClickDisabledFrequency = e => {
    if (this.props.protocol !== undefined) {
      this.setState({
        anchorEl: e.currentTarget
      });
    }
  };

  handleCloseFrequencyTooltip = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const {
      equipmentTypes,
      equipmentTypeId,
      protocol,
      customEquipmentType,
      upload,
      classes
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Field
            margin="dense"
            id="equipment_type_id"
            name="equipment_type_id"
            label="Choose Equipment Type"
            placeholder="Select Equipment Type"
            classes={classes}
            required
            fullWidth
            onChange={this.handleChange}
            component={renderSelectField}
          >
            {equipmentTypes.map((equipmentType, index) => (
              <MenuItem key={index} value={equipmentType.id}>
                {equipmentType.name}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        {equipmentTypeId && equipmentTypeId !== 'custom' && (
          <Grid item xs={4}>
            <Field
              margin="dense"
              id="frequency"
              name="frequency"
              label="Cycle Frequency"
              required
              onClick={this.handleClickDisabledFrequency}
              disabled={protocol !== undefined}
              component={renderTextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Days</InputAdornment>
                )
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <Popover
              id="simple-popper"
              open={open}
              anchorEl={anchorEl}
              onClose={this.handleCloseFrequencyTooltip}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Typography className={classes.tooltipText}>
                To update the frequency of this equipment type, please navigate
                to the Protocol Frequency section on the department detail view.
              </Typography>
            </Popover>
          </Grid>
        )}
        {customEquipmentType && (
          <React.Fragment>
            <Grid item xs={8}>
              <Field
                margin="dense"
                id="custom_equipment_type"
                name="equipment_type.name"
                label="Equipment Type Name"
                fullWidth
                required
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                margin="dense"
                id="frequency"
                name="frequency"
                label="Cycle Frequency"
                required
                component={renderTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Days</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </React.Fragment>
        )}
        {(customEquipmentType || equipmentTypeId) && (
          <React.Fragment>
            {upload ? (
              <Grid item xs={12}>
                <Typography className={classes.helperText}>
                  Select a CSV file to upload. Please use the{' '}
                  <EquipmentItemsTemplateLink />.
                </Typography>
                <Typography className={classes.helperText}>
                  All Indicator Tag IDs should be listed in the first column of
                  the spreadsheet, and are 12 characters long composed of A-F &
                  0-9.
                </Typography>
                <Typography className={classes.helperText}>
                  You may also enter an optional Equipment ID in the second
                  column for an existing ID your hospital uses to identify that
                  piece of equipment.
                </Typography>
                <Field
                  margin="dense"
                  id="equipment_items_upload"
                  name="equipment_items_upload"
                  label="Choose CSV File"
                  component={renderFileUpload}
                />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs={6}>
                  <Field
                    margin="dense"
                    id="tag_id"
                    name="tag_id"
                    label="Indicator Tag ID"
                    required
                    fullWidth
                    component={renderTextField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    margin="dense"
                    id="equipment_id"
                    name="equipment_id"
                    label="Equipment ID"
                    fullWidth
                    component={renderTextField}
                  />
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  label="Overwrite Duplicate Indicator Tag IDs"
                  control={
                    <Field
                      margin="dense"
                      id="deactivate_duplicate_tags"
                      name="deactivate_duplicate_tags"
                      component={renderCheckbox}
                      type="checkbox"
                    />
                  }
                />
              </FormGroup>
              <Typography className={classes.helperText}>
                Overwriting a duplicate Indicator Tag ID will deactivate the
                previously existing record and make the new one active. No other
                Indicator Tag IDs will be affected.
              </Typography>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

ProtocolFields.propTypes = {
  upload: PropTypes.bool
};

const mapStateToProps = state => ({
  equipmentTypes: getEquipmentTypeOptionsForCurrentDepartment(state),
  customEquipmentType: getCustomEquipmentType(state),
  equipmentTypeId: getSelectedEquipmentTypeId(state),
  protocol: getProtocolById(state, getSelectedProtocolId(state))
});

const mapDispatchToProps = dispatch => ({
  setCustomEquipmentType: value => {
    dispatch(setCustomEquipmentType(value));
  },
  setSelectedEquipmentTypeId: value => {
    dispatch(setSelectedEquipmentTypeId(value));
  }
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ProtocolFields)
);
