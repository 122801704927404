import React from 'react';

// Helpers
import { renderTextField } from '../../components/Form/helpers';

// Redux Form
import { Field } from 'redux-form/immutable';

// Material UI
import Grid from '@material-ui/core/Grid';

const Fields = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Field
        required
        autoFocus
        margin="dense"
        id="facilityName"
        name="name"
        label="Facility Name"
        fullWidth
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        required
        margin="dense"
        id="address_1"
        name="address_1"
        label="Address line 1"
        fullWidth
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        margin="dense"
        id="address_2"
        name="address_2"
        label="Address line 2"
        fullWidth
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        required
        margin="dense"
        id="city"
        name="city"
        label="City"
        fullWidth
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        required
        margin="dense"
        id="state"
        name="state"
        label="State/Province/Region"
        fullWidth
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Field
        required
        margin="dense"
        id="zip"
        name="zip"
        label="Zip / Postal code"
        fullWidth
        component={renderTextField}
      />
    </Grid>
  </Grid>
);

export default Fields;
