import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* updateRole(data) {
  return yield call(
    fetchData,
    `facilities/${data.facilityId}/users/${data.userId}`,
    {
      method: 'PATCH',
      body: { role_name: data.roleName }
    }
  );
}

export function* sendUserInvitation(data) {
  const facilityId = data.get('facility_id');
  return yield call(fetchData, `facilities/${facilityId}/users`, {
    method: 'POST',
    body: data
  });
}
