import React from 'react';
import { connect } from 'react-redux';

// Actions
import { showModal } from '../../components/Modal/reducer';
import { createEquipmentItem } from './reducer';

// Selectors

import {
  getSelectedProtocolId,
  getProtocolById,
  getSelectedEquipmentTypeId
} from '../Protocols/selectors';

// Components
import Modal from '../../components/Modal';
import EquipmentItemsSearch from './Search';
import ProtocolFields from '../Protocols/Fields';
import PaperActions from '../../components/PaperActions';
import FormDialog from '../../components/FormDialog';

class EquipmentItems extends React.Component {
  modalName = 'NewEquipmentModal';
  uploadModalName = 'UploadEquipmentModal';
  formName = 'NewEquipmentForm';
  uploadFormName = 'UploadEquipmentForm';

  render() {
    const { showModal, protocol, equipmentTypeId } = this.props;

    const protocolValues = protocol
      ? {
          frequency: protocol.get('frequency'),
          equipment_type_id: protocol.get('equipment_type_id')
        }
      : {
          equipment_type_id: equipmentTypeId
        };

    return (
      <div>
        <Modal
          name={this.modalName}
          render={() => (
            <FormDialog
              title="New Equipment"
              buttonText="Add Equipment"
              form={this.formName}
              initialValues={protocolValues}
              onSubmit={createEquipmentItem}
            >
              <ProtocolFields />
            </FormDialog>
          )}
        />
        <Modal
          name={this.uploadModalName}
          render={() => (
            <FormDialog
              title="Upload Equipment"
              buttonText="Upload"
              form={this.uploadFormName}
              initialValues={protocolValues}
              onSubmit={createEquipmentItem}
            >
              <ProtocolFields upload />
            </FormDialog>
          )}
        />
        <EquipmentItemsSearch />
        <PaperActions
          buttonId="add-equipment-item"
          primaryButtonText="Add Equipment"
          handleClick={() => showModal(this.modalName)}
        />
      </div>
    );
  }
}

EquipmentItems.propTypes = {};

const mapStateToProps = state => ({
  protocol: getProtocolById(state, getSelectedProtocolId(state)),
  equipmentTypeId: getSelectedEquipmentTypeId(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentItems);
