import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Field } from 'redux-form/immutable';

// Helpers
import { renderTextField } from '../../components/Form/helpers';

// Material UI Components
import Grid from '@material-ui/core/Grid';

const PasswordFields = ({ reset }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Field
        margin="dense"
        type="password"
        id="password"
        name="password"
        label="Password"
        fullWidth
        required
        autoFocus={reset}
        component={renderTextField}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        margin="dense"
        type="password"
        id="password-confirmation"
        name="password_confirmation"
        label="Password Confirmation"
        fullWidth
        required
        component={renderTextField}
      />
    </Grid>
  </Grid>
);

PasswordFields.propTypes = {
  reset: PropTypes.bool
};

export default PasswordFields;
