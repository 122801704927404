import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Selectors
import {
  getEquipmentTypeById,
  getProtocolById,
  isAttemptingDeactivation
} from './selectors';
import { getItemCountForProtocol } from '../EquipmentItems/selectors';

// Components
import FrequencyFields from './FrequencyFields';
import Modal from '../../components/Modal';
import FormDialog from '../../components/FormDialog';

// Actions
import { updateFrequency, deactivateEquipmentTypeRequest } from './reducer';
import { showModal } from '../../components/Modal/reducer';

// Material UI Components
import {
  withStyles,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  progress: {
    marginLeft: theme.spacing(1)
  }
});

class SummaryRow extends React.Component {
  modalName = `EditFrequencyModal-${this.props.protocolId}`;
  form = `frequencyForm-${this.props.protocolId}`;

  render() {
    const {
      protocol,
      totalItemCount,
      equipmentType,
      showModal,
      isDeactivating,
      archiveEquipmentType,
      classes
    } = this.props;
    const equipmentTypeName = equipmentType.get('name');

    return (
      <React.Fragment>
        <Modal
          name={this.modalName}
          render={() => (
            <FormDialog
              title={`Edit Cycle Frequency - ${equipmentTypeName}`}
              buttonText="Save"
              form={this.form}
              initialValues={protocol}
              onSubmit={updateFrequency}
            >
              <FrequencyFields equipmentType={equipmentType} />
            </FormDialog>
          )}
        />
        <TableRow>
          <TableCell>{equipmentTypeName}</TableCell>
          <TableCell align="center">
            {totalItemCount(protocol.get('id'))}
          </TableCell>
          <TableCell align="center">{protocol.get('frequency')}</TableCell>
          <TableCell>
            <IconButton
              className="edit-protocol-icon-button"
              onClick={() => showModal(this.modalName)}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            {isDeactivating && (
              <div className={classes.progress}>
                <CircularProgress />
              </div>
            )}
            {equipmentType.get('default') !== true && !isDeactivating && (
              <IconButton
                id={`archive-equipment-type-${equipmentType.get('id')}`}
                className="archive-equipment-type-button"
                onClick={() => archiveEquipmentType(equipmentType)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

SummaryRow.propTypes = {
  protocolId: PropTypes.string.isRequired,
  protocol: ImmutablePropTypes.map.isRequired,
  totalItemCount: PropTypes.func.isRequired,
  equipmentType: ImmutablePropTypes.map.isRequired,
  isDeactivating: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  totalItemCount: id => getItemCountForProtocol(state, id),
  equipmentType: getEquipmentTypeById(
    state,
    getProtocolById(state, ownProps.protocolId).get('equipment_type_id')
  ),
  protocol: getProtocolById(state, ownProps.protocolId),
  isDeactivating: isAttemptingDeactivation(
    state,
    getProtocolById(state, ownProps.protocolId).get('equipment_type_id')
  )
});

const mapDispatchToProps = dispatch => ({
  archiveEquipmentType: equipmentType => {
    let confirmation = window.confirm(
      `Remove Equipment Type: ${equipmentType.get('name')}?`
    );
    if (confirmation) {
      dispatch(deactivateEquipmentTypeRequest(equipmentType.get('id')));
    }
  },
  showModal: modalName => dispatch(showModal(modalName))
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SummaryRow)
);
