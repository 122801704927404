import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import OperatorSearch from './Search';
import SearchResults from './Results';
import UploadOperatorsModal from '../../components/Operators/UploadModal';
import NewOperatorModal from '../../components/Operators/NewOperatorModal';
import PaperActions from '../../components/PaperActions';

// Actions
import { showModal } from '../../components/Modal/reducer';

// Actions
import {
  uploadOperators,
  createOperator,
  searchOperatorsReset
} from './reducer';

// Material UI Components
import Typography from '@material-ui/core/Typography';

const Operators = ({ operatorsCount, resetSearchResults, showModal }) => {
  useEffect(() => {
    return () => resetSearchResults();
  }, []);

  return (
    <React.Fragment>
      <UploadOperatorsModal submitAction={uploadOperators} />
      <NewOperatorModal submitAction={createOperator} />

      <Typography variant="caption">
        Search by First Name, Last Name or Badge ID:
      </Typography>

      <OperatorSearch totalCount={operatorsCount} />
      <SearchResults />

      <PaperActions
        buttonId="add-operator"
        primaryButtonText="Add Operator"
        handleClick={() => showModal('NewOperatorModal')}
      />
    </React.Fragment>
  );
};

Operators.propTypes = {
  facilityId: PropTypes.number.isRequired,
  operatorsCount: PropTypes.number.isRequired
};

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName)),
  resetSearchResults: () => dispatch(searchOperatorsReset())
});

export default connect(null, mapDispatchToProps)(Operators);
