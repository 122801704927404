import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Components
import { Field } from 'redux-form/immutable';
import {
  renderTextField,
  renderCheckbox,
  renderSelectField
} from '../Form/helpers';

// Selectors
import { getDepartmentsAtCurrentFacility } from '../../containers/Departments/selectors';

// Material UI Components
import {
  withStyles,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  MenuItem
} from '@material-ui/core';

const styles = theme => ({
  helperText: {
    marginBottom: theme.spacing(2)
  }
});

const NewOperatorFields = ({ classes, departments }) => (
  <div>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          margin="dense"
          id="first_name"
          name="first_name"
          label="First Name"
          required
          fullWidth
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          margin="dense"
          id="last_name"
          name="last_name"
          label="Last Name"
          required
          fullWidth
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          margin="dense"
          id="badge_id"
          name="badge_id"
          label="Badge ID"
          required
          fullWidth
          component={renderTextField}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          margin="dense"
          id="department_id"
          name="department_id"
          label="Select Department"
          required
          fullWidth
          component={renderSelectField}
        >
          {departments.valueSeq().map(department => (
            <MenuItem key={department.get('id')} value={department.get('id')}>
              {department.get('title')}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            label="Overwrite Duplicate Badge Ids"
            control={
              <Field
                id="deactivate_duplicate_badges"
                name="deactivate_duplicate_badges"
                component={renderCheckbox}
                type="checkbox"
              />
            }
          />
        </FormGroup>
        <Typography className={classes.helperText}>
          Overwriting a duplicate Badge ID will deactivate the previously
          existing record and make the new one active. No other Badge IDs will
          be affected.
        </Typography>
      </Grid>
    </Grid>
  </div>
);

NewOperatorFields.propTypes = {
  departments: ImmutablePropTypes.map.isRequired
};

const mapStateToProps = state => ({
  departments: getDepartmentsAtCurrentFacility(state)
});

export default withStyles(styles)(connect(mapStateToProps)(NewOperatorFields));
