import React from 'react';
import PropTypes from 'prop-types';

// Router
import { Link } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  breadcrumbs: {
    display: 'flex',
    paddingBottom: theme.spacing(1)
  },
  separator: {
    display: 'inline',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  breadcrumbLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:focus': {
      textDecoration: 'underline'
    }
  },
  breadcrumbItem: {
    maxWidth: '25rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

function Breadcrumbs({ items, classes }) {
  const itemLength = items.length;

  return (
    <div className={classes.breadcrumbs}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div className={classes.breadcrumbItem}>
            <Link to={item.to} className={classes.breadcrumbLink}>
              {item.text}
            </Link>
          </div>
          <div className={classes.separator}>
            {index + 1 < itemLength && '>'}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withStyles(styles)(Breadcrumbs);
