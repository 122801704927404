import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Usage = ({ facilityIds }) => {
  return (
    <div>
      Usage
      {facilityIds.map(id => (
        <p>{id}</p>
      ))}
    </div>
  );
};

Usage.propTypes = {
  facilityIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Usage;
