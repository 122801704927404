import React from 'react';

// Components
import CenteredForm from '../../components/Auth/CenteredForm';

// Query string helpers
import queryString from 'query-string';

// Components
import AcceptInvitationForm from './AcceptInvitationForm';

class AcceptInvitation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: ''
    };
  }

  componentWillMount() {
    const token = queryString.parse(this.props.location.search)
      .invitation_token;
    this.setState({ token: token });
  }

  render() {
    const { token } = this.state;

    return (
      <CenteredForm title="Accept Invitation">
        <AcceptInvitationForm
          form="acceptInvitation"
          initialValues={{ invitation_token: token }}
        />
      </CenteredForm>
    );
  }
}

export default AcceptInvitation;
