import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Selectors
import { getNotificationsCount } from '../../containers/Auth/selectors';

// Material UI
import { withStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';

const StyledBadge = withStyles(theme => ({
  badge: {
    lineHeight: '2rem'
  }
}))(Badge);

const Notifications = ({ numWarnings }) => (
  <StyledBadge badgeContent={numWarnings} color="primary">
    <NotificationsIcon />
  </StyledBadge>
);

Notifications.propTypes = {
  numWarnings: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  numWarnings: getNotificationsCount(state)
});

export default connect(mapStateToProps)(Notifications);
