import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Actions
import { createEnclosure } from './reducer';

// Selectors
import { isUVCAdmin } from '../../containers/Auth/selectors';

// Components
import Enclosure from './Enclosure';
import FormDialog from '../../components/FormDialog';
import EnclosureFields from './Fields';
import { showModal } from '../../components/Modal/reducer';
import Modal from '../../components/Modal';
import PaperActions from '../../components/PaperActions';

class Enclosures extends React.Component {
  modalName = `NewEnclosureModal-${this.props.facilityId}`;
  newEnclosureForm = `newEnclosureForm-${this.props.facilityId}`;

  render() {
    const { enclosureIds, facilityId, showModal, canAddEnclosure } = this.props;

    return (
      <div>
        <Modal
          name={this.modalName}
          render={() => (
            <FormDialog
              title="New Enclosure"
              buttonText="Add Enclosure"
              form={this.newEnclosureForm}
              initialValues={{
                configuration_type: 1,
                facility_id: facilityId,
                display_due_date_on_tags: true
              }}
              onSubmit={createEnclosure}
            >
              <EnclosureFields newEnclosure />
            </FormDialog>
          )}
        />
        {enclosureIds.map((enclosureId, index) => (
          <Enclosure
            key={enclosureId}
            editForm={`enclosureEditForm-${enclosureId}`}
            softwareForm={`enclosureSoftwareForm-${enclosureId}`}
            enclosureId={enclosureId.toString()}
          />
        ))}
        {canAddEnclosure && (
          <PaperActions
            buttonId="add-uve"
            primaryButtonText="Add UVE"
            handleClick={() => showModal(this.modalName)}
          />
        )}
      </div>
    );
  }
}

Enclosures.propTypes = {
  enclosureIds: ImmutablePropTypes.list.isRequired,
  facilityId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  canAddEnclosure: isUVCAdmin(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName))
});

export default connect(mapStateToProps, mapDispatchToProps)(Enclosures);
