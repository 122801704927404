import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Components
import { Field } from 'redux-form/immutable';

// Selectors
import { isUVCAdmin } from '../../containers/Auth/selectors';
import { getAvailableSoftwarePackages } from './selectors';

// Helpers
import {
  renderSelectField,
  renderCheckbox
} from '../../components/Form/helpers';

// Material UI Components
import {
  Grid,
  MenuItem,
  Typography,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@material-ui/core';

const SoftwareFields = ({ enclosure, availableSoftwarePackages }) => (
  <Grid container direction="column" spacing={2}>
    <Grid item xs={12}>
      <Typography>
        {`Currently Running: ${enclosure.get('software_version') || 'Unknown'}`}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Field
        margin="dense"
        id="enclosure_software_package_id"
        name="enclosure_software_package_id"
        label="Expected Software Version"
        disabled={!isUVCAdmin}
        required
        fullWidth
        format={(value, name) => (value == null ? ' ' : value)}
        component={renderSelectField}
      >
        <MenuItem value={' '}>None</MenuItem>
        {availableSoftwarePackages.map(software_package => (
          <MenuItem
            key={software_package.get('id')}
            value={software_package.get('id')}
          >
            {software_package.get('version')}
          </MenuItem>
        ))}
      </Field>
    </Grid>

    <Grid item xs={12}>
      <FormGroup>
        <FormLabel component="legend">Feature Toggles</FormLabel>
        <Grid container justify="center" spacing={16}>
          {[
            { label: 'Disable PIR motion sensor', field: 'disable_pir' },
            {
              label: 'Disable Door Leading Edge optical sensor',
              field: 'disable_leading_edge'
            },
            {
              label: 'Disable Door Pocket Pinch optical sensor',
              field: 'disable_pocket_pinch'
            },
            {
              label: 'Disable Bluetooth Tag Scanning/Updating',
              field: 'disable_bluetooth'
            },
            {
              label: 'Disable Lamp Module Current level checks',
              field: 'disable_current_check'
            },
            {
              label: 'Display UVE and Door state on panel',
              field: 'enable_state_display'
            },
            { label: 'Allow video camera absence', field: 'enable_no_camera' }
          ].map((toggle, index) => (
            <Grid item xs={11}>
              <FormControlLabel
                label={toggle['label']}
                control={
                  <Field
                    margin="dense"
                    id={toggle['field']}
                    name={toggle['field']}
                    component={renderCheckbox}
                    type="checkbox"
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </Grid>
  </Grid>
);

SoftwareFields.propTypes = {
  availableSoftwarePackages: ImmutablePropTypes.map.isRequired,
  enclosure: ImmutablePropTypes.map.isRequired
};

const mapStateToProps = state => ({
  availableSoftwarePackages: getAvailableSoftwarePackages(state)
});

export default connect(mapStateToProps)(SoftwareFields);
