import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles, CircularProgress } from '@material-ui/core';

const styles = theme => ({
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  },
  formActionsCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  centeredProgress: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(3)
  }
});

const FormActions = ({ submitting, children, classes, centered }) => (
  <div className={centered ? classes.formActionsCenter : classes.formActions}>
    {submitting && <CircularProgress />}
    {!submitting && children}
  </div>
);

FormActions.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  centered: PropTypes.bool
};

export default withStyles(styles)(FormActions);
