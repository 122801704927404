import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import DatePicker from '../../components/Form/DatePicker';

// Material UI
import Grid from '@material-ui/core/Grid';

const RunCycleFields = ({ form }) => (
  <React.Fragment>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DatePicker
          name="start_date"
          label="Start Date"
          form={form}
          formatDate={'YYYY-MM-DD'}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          name="end_date"
          label="End Date"
          form={form}
          formatDate={'YYYY-MM-DD'}
        />
      </Grid>
    </Grid>
  </React.Fragment>
);

RunCycleFields.propTypes = {
  form: PropTypes.string.isRequired
};

export default RunCycleFields;
