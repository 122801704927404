import Immutable from 'immutable';

const getEntities = state => state.entities;
const getFacilitiesReducer = state => state.facilitiesReducer;

export const getDepartments = state =>
  getEntities(state).getIn(['data', 'entities', 'departments']);

export const getDepartmentById = (state, id) => getDepartments(state).get(id);

export const getDepartmentReducer = state => state.departmentsReducer;
export const isDepartmentLoading = state =>
  getDepartmentReducer(state).get('loadingDepartment');
export const isDepartmentLoaded = state =>
  getDepartmentReducer(state).get('loadedDepartment');
export const errorLoadingDepartmentData = state =>
  getDepartmentReducer(state).get('errorLoadingDepartment');

export const getDepartmentsAtCurrentFacility = state => {
  let departments = getFacilitiesReducer(state).getIn([
    'entities',
    'departments'
  ]);

  if (departments === undefined) {
    departments = Immutable.List();
  }

  return departments;
};

export const getCurrentDepartmentId = state =>
  getDepartmentReducer(state).get('currentDepartmentId');

export const getCurrentDepartment = state =>
  getDepartmentReducer(state).getIn([
    'entities',
    'departments',
    getCurrentDepartmentId(state)
  ]);
