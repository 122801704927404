import React from 'react';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';

import { canViewFacility } from '../Auth/selectors';

// Actions
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

class ProtectedFacilityRoute extends React.Component {
  componentDidMount() {
    if (!this.props.canViewFacility) {
      this.props.showSnackbar({
        variant: 'error',
        message: 'You are not authorized to view this page'
      });
    }
  }

  render() {
    const { canViewFacility, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props =>
          canViewFacility ? (
            <Component {...props} />
          ) : (
            <Redirect to="/my_facilities" />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  canViewFacility: canViewFacility(state, ownProps.computedMatch.params.id)
});

const mapDispatchToProps = dispatch => ({
  showSnackbar: params => dispatch(showSnackbar(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedFacilityRoute);
