import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

// Actions
export const constants = {
  CREATE_ENCLOSURE: 'containers/Enclosures/CREATE_ENCLOSURE',
  UPDATE_ENCLOSURE: 'containers/Enclosures/UPDATE_ENCLOSURE'
};

export const createEnclosure = createFormAction(constants.CREATE_ENCLOSURE);
export const updateEnclosure = createFormAction(constants.UPDATE_ENCLOSURE);

const initialState = fromJS({});

// Reducers
const enclosuresReducer = handleActions({}, initialState);

export default enclosuresReducer;
