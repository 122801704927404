import React from 'react';
import PropTypes from 'prop-types';

// Components
import TextButton from './TextButton';

// Material UI
import { ThemeProvider } from '@material-ui/styles';
import MailIcon from '@material-ui/icons/MailOutline';

const EmailButton = ({ id, ...buttonProps }) => (
  <ThemeProvider
    theme={outerTheme => ({
      buttonHighlightColor: 'rgba(0, 0, 0, 0.05)',
      ...outerTheme
    })}
  >
    <TextButton id={id} iconLeft={<MailIcon />} {...buttonProps}></TextButton>
  </ThemeProvider>
);

EmailButton.propTypes = {
  id: PropTypes.string.isRequired,
  buttonProps: PropTypes.object
};

export default EmailButton;
