import { connect } from 'react-redux';

// Component
import CustomSnackbar from './CustomSnackbar';

// Actions
import { closeSnackbar } from './reducer';

const mapStateToProps = state => ({
  isOpen: state.snackbarReducer.get('isOpen'),
  variant: state.snackbarReducer.get('variant'),
  message: state.snackbarReducer.get('message')
});

export default connect(
  mapStateToProps,
  { closeSnackbar }
)(CustomSnackbar);
