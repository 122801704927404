import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Components
import { Redirect } from 'react-router';
import Modal from '../../components/Modal';
import FormDialog from '../../components/FormDialog';
import CenterLoadingIcon from '../../components/CenterLoadingIcon';
import Breadcrumbs from '../../components/Breadcrumbs';
import FacilityHeader from '../../components/Facilities/Header';
import FacilityFields from '../../components/Facilities/Fields';
import {
  EnclosuresSection,
  UsersSection,
  DepartmentsSection,
  OperatorsSection
} from '../../components/Facilities/Sections';

// Selectors
import {
  getCurrentFacility,
  isFacilityLoading,
  isFacilityLoaded,
  errorLoadingFacility
} from './selectors';
import { userHasSingleFacility } from '../../containers/Auth/selectors';

// Actions
import {
  fetchFacilityRequest,
  unloadFacility,
  updateFacility
} from './reducer';

// Material UI
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%'
  }
});

const Facility = ({
  match,
  fetchFacility,
  facility,
  loading,
  loaded,
  loadingError,
  classes,
  singleFacility,
  unloadFacility
}) => {
  const facilityId = match.params.id;
  const editModalName = `EditFacilityModal-${facilityId}`;

  useEffect(() => {
    fetchFacility(facilityId);
  }, [facilityId]);

  useEffect(() => {
    return () => unloadFacility();
  }, []);

  return (
    <div className={classes.root}>
      {loading && <CenterLoadingIcon />}
      {loadingError && <Redirect to="/" />}
      {loaded && facility && (
        <React.Fragment>
          <Modal
            name={editModalName}
            render={() => (
              <FormDialog
                initialValues={facility}
                title="Edit Facility"
                buttonText="Save"
                form={'editFacilityForm'}
                onSubmit={updateFacility}
              >
                <FacilityFields />
              </FormDialog>
            )}
          />
          {!singleFacility && (
            <Breadcrumbs
              items={[
                { text: 'My Facilities', to: '/my_facilities' },
                { text: facility.get('name'), to: '#' }
              ]}
            />
          )}
          <FacilityHeader facility={facility} modalName={editModalName} />
          <EnclosuresSection facility={facility} />
          <UsersSection facility={facility} />
          <DepartmentsSection facility={facility} />
          <OperatorsSection facility={facility} />
        </React.Fragment>
      )}
    </div>
  );
};

Facility.propTypes = {
  facility: ImmutablePropTypes.map,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  loadingError: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  singleFacility: PropTypes.bool
};

const mapStateToProps = state => ({
  facility: getCurrentFacility(state),
  loading: isFacilityLoading(state),
  loaded: isFacilityLoaded(state),
  loadingError: errorLoadingFacility(state),
  singleFacility: userHasSingleFacility(state)
});

const mapDispatchToProps = dispatch => ({
  fetchFacility: id => dispatch(fetchFacilityRequest(id)),
  unloadFacility: () => dispatch(unloadFacility())
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Facility)
);
