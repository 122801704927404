import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import PaperSection from '../../PaperSection';

// Containers
import Users from '../../../containers/Users';

const UsersSection = ({ facility }) => (
  <PaperSection title="Users" id="users">
    <Users facilityId={facility.get('id')} />
  </PaperSection>
);

UsersSection.propTypes = {
  facility: ImmutablePropTypes.map.isRequired
};

export default UsersSection;
