import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Redirect } from 'react-router';

// Actions
import { showModal } from '../../components/Modal/reducer';
import { createFacility, fetchFacilitiesRequest } from './reducer';

// Selectors
import { getLoadingFacilities, getLoadedFacilities } from './selectors';

import { getFacilities } from './selectors';

import {
  isUVCAdmin,
  getUserFacilityIds,
  userHasSingleFacility
} from '../Auth/selectors';

// Components
import FacilityGrid from '../../components/Facilities/FacilityGrid';
import FacilityFields from '../../components/Facilities/Fields';
import Modal from '../../components/Modal';
import FormDialog from '../../components/FormDialog';
import CenterLoadingIcon from '../../components/CenterLoadingIcon';
import UVCButton from '../../components/Buttons/UVCButton';
import SectionHeader from '../../components/SectionHeader';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {},
  headerButton: {
    marginLeft: 'auto'
  }
});

const MyFacilities = ({
  loading,
  loaded,
  facilities,
  classes,
  canAddFacility,
  singleFacility,
  singleFacilityId,
  fetchFacilities,
  showModal
}) => {
  useEffect(() => {
    fetchFacilities();
  }, []);

  return (
    <React.Fragment>
      {loaded && singleFacility && (
        <Redirect to={`/facilities/${singleFacilityId}`} />
      )}
      {!singleFacility && (
        <div className={classes.root}>
          <Modal
            name={'NewFacilityModal'}
            render={() => (
              <FormDialog
                title="New Facility"
                buttonText="Add Facility"
                form={'newFacilityForm'}
                onSubmit={createFacility}
              >
                <FacilityFields />
              </FormDialog>
            )}
          />
          <SectionHeader title="My Facilities" type="h1">
            {canAddFacility && (
              <div className={classes.headerButton}>
                <UVCButton
                  text="Add New Facility"
                  id="add-new-facility"
                  onClick={() => showModal('NewFacilityModal')}
                />
              </div>
            )}
          </SectionHeader>
          {loading && <CenterLoadingIcon />}
          {loaded && !facilities && (
            <Typography>
              When you are assigned to a facility, it will show up here.
            </Typography>
          )}
          {loaded && facilities && <FacilityGrid facilities={facilities} />}
        </div>
      )}
    </React.Fragment>
  );
};

MyFacilities.propTypes = {
  facilities: ImmutablePropTypes.map.isRequired,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  facilities: getFacilities(state),
  loading: getLoadingFacilities(state),
  loaded: getLoadedFacilities(state),
  canAddFacility: isUVCAdmin(state),
  singleFacility: userHasSingleFacility(state),
  singleFacilityId: getUserFacilityIds(state).get(0)
});

const mapDispatchToProps = dispatch => ({
  fetchFacilities: () => dispatch(fetchFacilitiesRequest()),
  showModal: modalName => dispatch(showModal(modalName))
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MyFacilities)
);
