import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { searchEquipmentItemsRequest } from './reducer';

// Components
import SearchInput from '../../components/Search/SearchInput';
import Results from './Results';

const EquipmentItemsSearch = ({ searchByTagId }) => (
  <div>
    <SearchInput
      label="Search by Indicator Tag ID or Equipment ID"
      fieldId="equipment-items-search"
      onSearch={searchByTagId}
    />
    <Results />
  </div>
);

EquipmentItemsSearch.propTypes = {
  searchByTagId: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  searchByTagId: e => dispatch(searchEquipmentItemsRequest(e.target.value))
});

export default connect(null, mapDispatchToProps)(EquipmentItemsSearch);
