import React from 'react';
import PropTypes from 'prop-types';

// Components
import TextButton from './TextButton';

// Material UI
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  cancelButtonContainer: {
    marginLeft: theme.spacing(1)
  }
});

const CancelButton = ({ id, classes, ...buttonProps }) => (
  <div className={classes.cancelButtonContainer}>
    <TextButton text="Cancel" id={id} {...buttonProps}></TextButton>
  </div>
);

CancelButton.propTypes = {
  id: PropTypes.string.isRequired,
  buttonProps: PropTypes.object
};

export default withStyles(styles)(CancelButton);
