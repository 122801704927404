import React from 'react';
import PropTypes from 'prop-types';

// State
import { connect } from 'react-redux';
import { history } from './store';

// Selectors
import {
  getLoggedIn,
  getAuthenticationAttempted
} from './containers/Auth/selectors';

// Router
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

// App components
import App from './App';
import Login from './containers/Auth/Login';
import AcceptInvitation from './containers/Auth/AcceptInvitation';
import ResetPasswordRequest from './containers/Auth/ResetPasswordRequest';
import ResetPassword from './containers/Auth/ResetPassword';
import InvalidPasswordResetToken from './containers/Auth/InvalidPasswordResetToken';
import Dashboard from './containers/Dashboard';
import MyFacilities from './containers/Facilities/MyFacilities';
import Facility from './containers/Facilities/Facility';
import Department from './containers/Departments/Department';
import EnclosureLogs from './containers/EnclosureLogs';
import CenterLoadingIcon from './components/CenterLoadingIcon';

// Route components
import ProtectedFacilityRoute from './containers/Routes/ProtectedFacilityRoute';

export class Routes extends React.Component {
  routeSwitch() {
    let { loggedIn, authenticationAttempted } = this.props;

    if (!authenticationAttempted) {
      return <CenterLoadingIcon />;
    } else {
      if (loggedIn) {
        return (
          <Switch>
            <ProtectedFacilityRoute
              exact
              path="/facilities/:id"
              component={Facility}
            />
            <Route exact path="/enclosure_logs" component={EnclosureLogs} />
            <Route exact path="/departments/:id" component={Department} />
            <Route exact path="/my_facilities" component={MyFacilities} />
            <Route path="/dashboard" component={Dashboard} />
            <Redirect to="/my_facilities" />
          </Switch>
        );
      } else {
        return (
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/accept_invitation"
              component={AcceptInvitation}
            />
            <Route
              exact
              path="/reset_password_request"
              component={ResetPasswordRequest}
            />
            <Route exact path="/reset_password" component={ResetPassword} />
            <Route
              exact
              path="/invalid_password_reset_token"
              component={InvalidPasswordResetToken}
            />
            <Redirect to="/login" />
          </Switch>
        );
      }
    }
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <App>{this.routeSwitch()}</App>
      </ConnectedRouter>
    );
  }
}

Routes.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  authenticationAttempted: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loggedIn: getLoggedIn(state),
  authenticationAttempted: getAuthenticationAttempted(state)
});

export default connect(mapStateToProps)(Routes);
