import React from 'react';
import PropTypes from 'prop-types';

// Components
import TextButton from './TextButton';

// Material UI Components
import { ThemeProvider } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';

const UVCButton = ({
  text,
  addIcon,
  classes,
  id,
  customIcon,
  children,
  ...buttonProps
}) => (
  <ThemeProvider
    theme={outerTheme => ({
      ...outerTheme,
      buttonUnderlineColor: outerTheme.palette.primary.contrastText,
      buttonHighlightColor: outerTheme.palette.secondary.main
    })}
  >
    <TextButton
      id={id}
      text={text}
      variant="contained"
      color="primary"
      iconLeft={addIcon ? <AddIcon /> : customIcon}
      {...buttonProps}
    >
      {children}
    </TextButton>
  </ThemeProvider>
);

UVCButton.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  addIcon: PropTypes.bool,
  customIcon: PropTypes.node
};

export default UVCButton;
