import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Logo
import logo from '../Images/uvc_logo.svg';

const styles = theme => ({
  formWrapper: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: '8rem'
  },
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  }
});

class CenteredForm extends React.Component {
  render() {
    const { classes, children, title } = this.props;

    return (
      <React.Fragment>
        <header className={classes.logoWrapper}>
          <img src={logo} className={classes.logo} alt="UV-Concepts" />
        </header>
        <section className={classes.formWrapper}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h2">
              {title}
            </Typography>
            {children}
          </Paper>
        </section>
      </React.Fragment>
    );
  }
}

CenteredForm.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(CenteredForm);
