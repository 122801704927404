import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* createProtocol(departmentId, data) {
  const createAttributes = transformCreateAttributes(data, departmentId);

  if (createAttributes.get('equipment_type_id') === null) {
    return { error: { equipment_type_id: 'is required' } };
  } else if (
    !createAttributes.get('tag_id') &&
    !createAttributes.get('equipment_items_upload')
  ) {
    return {
      error: { equipment_items_upload: 'Please select a file for upload' }
    };
  } else {
    const postData = convertToFormData(createAttributes);

    return yield call(fetchData, `departments/${departmentId}/protocols`, {
      method: 'POST',
      body: postData,
      isFormData: true
    });
  }
}

export function* updateProtocol(departmentId, protocolId, data) {
  const updateAttributes = transformUpdateAttributes(data);

  if (
    !updateAttributes.get('tag_id') &&
    !updateAttributes.get('equipment_items_upload') &&
    !updateAttributes.get('frequency')
  ) {
    return {
      error: {
        tag_id: 'is required',
        equipment_items_upload: 'Please select a file for upload'
      }
    };
  } else {
    const postData = convertToFormData(updateAttributes);

    return yield call(
      fetchData,
      `departments/${departmentId}/protocols/${protocolId}`,
      {
        method: 'PATCH',
        body: postData,
        isFormData: true
      }
    );
  }
}

export function* updateProtocolFrequency(departmentId, data) {
  return yield call(
    fetchData,
    `departments/${departmentId}/protocols/${data.get('id')}`,
    {
      method: 'PATCH',
      body: { protocol: data }
    }
  );
}

const transformCreateAttributes = (data, departmentId) => {
  let transformedData;

  if (data.get('equipment_type')) {
    transformedData = data
      .set('equipment_type_attributes', data.get('equipment_type'))
      .setIn(['equipment_type_attributes', 'department_id'], departmentId)
      .delete('equipment_type')
      .delete('equipment_type_id');
  } else {
    transformedData = data;
  }

  return transformedData;
};

const transformUpdateAttributes = data => {
  let transformedData = data.delete('frequency');

  return transformedData;
};

const convertToFormData = data => {
  let formData = new FormData();
  data = data.toJS();

  Object.keys(data).forEach(key => {
    let value = data[key];

    if (
      typeof value === 'object' &&
      value !== null &&
      key !== 'equipment_items_upload'
    ) {
      Object.keys(value).forEach(valueKey => {
        formData.append(`protocol[${key}][${valueKey}]`, value[valueKey]);
      });
    } else {
      formData.append(`protocol[${key}]`, value);
    }
  });

  return formData;
};

export function* deactivateEquipmentType(departmentId, equipmentTypeId) {
  let path = `departments/${departmentId}/equipment_types/${equipmentTypeId}`;

  return yield call(fetchData, path, {
    method: 'PATCH',
    body: { active: false }
  });
}
