import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  sectionHeader: {
    display: 'flex',
    paddingBottom: theme.spacing(3)
  }
});

const SectionHeader = ({ classes, title, type, children }) => (
  <div className={classes.sectionHeader}>
    {title && (
      <Typography variant={type} gutterBottom component={type}>
        {title}
      </Typography>
    )}
    {children}
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node
};

export default withStyles(styles)(SectionHeader);
