import { takeLatest, fork, select, call, put } from 'redux-saga/effects';

// Services
import { requestDisinfectionReport } from '../../services/disinfectionReports';

// Actions
import {
  downloadDisinfectionReport,
  disinfectionReportDownloaded
} from './reducer';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

// Selectors
import { getCurrentFacilityId } from '../Facilities/selectors';

// File Download
import { saveAs } from 'file-saver';

function* downloadDisinfectionReportSaga() {
  yield takeLatest(
    downloadDisinfectionReport,
    attemptDownloadDisinfectionReport
  );
}

function* attemptDownloadDisinfectionReport(action) {
  const facilityId = yield select(getCurrentFacilityId);

  try {
    const { result, error, filename } = yield call(
      requestDisinfectionReport,
      facilityId
    );

    if (error && error['empty_report']) {
      alert('Please add a department and equipment item(s)');
    } else if (error) {
      yield put(showSnackbar({ variant: 'error', message: error }));
    } else {
      yield call(saveAs, result, filename);
    }
  } finally {
    yield put(disinfectionReportDownloaded());
  }
}

function* sagas() {
  yield fork(downloadDisinfectionReportSaga);
}

export default sagas;
