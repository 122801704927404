import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  container: {
    width: 'auto',
    display: 'block'
  },
  content: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

const CenterLoadingIcon = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.content}>
      <CircularProgress size={50} />
    </div>
  </div>
);

export default withStyles(styles)(CenterLoadingIcon);
