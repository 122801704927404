import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import FacilityCard from './FacilityCard';

// Material UI
import { Grid } from '@material-ui/core';

const FacilityGrid = ({ facilities }) => (
  <Grid container spacing={2}>
    {facilities.valueSeq().map(facility => (
      <Grid item xs={12} sm={6} key={facility.get('id')}>
        <FacilityCard facility={facility} />
      </Grid>
    ))}
  </Grid>
);

FacilityGrid.propTypes = {
  facilities: ImmutablePropTypes.map.isRequired
};

export default FacilityGrid;
