import React from 'react';
import { connect } from 'react-redux';

// UI Components
import CenteredForm from '../../components/Auth/CenteredForm';
import LoginForm from './loginForm';

// Actions
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

class Login extends React.Component {
  componentWillMount() {
    const routerState = this.props.location.state;

    if (routerState && routerState.error) {
      this.props.showSnackbar({ variant: 'error', message: routerState.error });
    }
  }

  render() {
    return (
      <CenteredForm title="Log In">
        <LoginForm form="loginForm" />
      </CenteredForm>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showSnackbar: snackbarProps => dispatch(showSnackbar(snackbarProps))
});

export default connect(
  null,
  mapDispatchToProps
)(Login);
