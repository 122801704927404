import { call } from 'redux-saga/effects';
import fetchData from './apiRequest';

export function* fetchDepartment(id) {
  return yield call(fetchData, `departments/${id}`);
}

export function* createNewDepartment(data) {
  return yield call(fetchData, 'departments', {
    method: 'POST',
    body: data
  });
}

export function* updateExistingDepartment(data) {
  const departmentId = data.get('id');

  return yield call(fetchData, `departments/${departmentId}`, {
    method: 'PATCH',
    body: data
  });
}
