import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Redux form reducer
import { reducer as formReducer } from 'redux-form/immutable';

// Custom Reducers
import authReducer from './containers/Auth/reducer';
import modalReducer from './components/Modal/reducer';
import snackbarReducer from './components/CustomSnackbar/reducer';
import usersReducer from './containers/Users/reducer';
import enclosuresReducer from './containers/Enclosures/reducer';
import departmentsReducer from './containers/Departments/reducer';
import facilitiesReducer from './containers/Facilities/reducer';
import protocolsReducer from './containers/Protocols/reducer';
import equipmentItemsReducer from './containers/EquipmentItems/reducer';
import operatorsReducer from './containers/Operators/reducer';
import profilesReducer from './containers/Profiles/reducer';
import runCycleReportsReducer from './containers/RunCycleReports/reducer';
import disinfectionReportsReducer from './containers/DisinfectionReports/reducer';

// Logout action
import { constants as authConstants } from './containers/Auth/reducer';

const appReducer = history =>
  combineReducers({
    authReducer: authReducer,
    form: formReducer,
    modalReducer: modalReducer,
    snackbarReducer: snackbarReducer,
    users: usersReducer,
    enclosuresReducer: enclosuresReducer,
    departmentsReducer: departmentsReducer,
    facilitiesReducer: facilitiesReducer,
    protocolsReducer: protocolsReducer,
    equipmentItemsReducer: equipmentItemsReducer,
    operatorsReducer: operatorsReducer,
    profilesReducer: profilesReducer,
    runCycleReportsReducer: runCycleReportsReducer,
    disinfectionReportsReducer: disinfectionReportsReducer,
    router: connectRouter(history)
  });

const rootReducer = history => (state, action) => {
  if (
    action.type === authConstants.LOGOUT ||
    action.type === authConstants.LOGIN_FAILURE
  ) {
    state = undefined;
  }

  return appReducer(history)(state, action);
};

export default rootReducer;
