import {
  takeEvery,
  put,
  call,
  fork,
  select,
  cancelled
} from 'redux-saga/effects';

import {
  login,
  logout,
  unauthenticated,
  verifyToken,
  acceptInvitation
} from './reducer';

import { getLoggedIn } from './selectors';

import {
  authenticate,
  removeAuthHeaders,
  refreshToken,
  confirmInvitation,
  signOut
} from '../../services/auth';

import { SubmissionError } from 'redux-form';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';

// Router
import { history } from '../../store';

function* loginFlowSaga() {
  yield takeEvery(login.REQUEST, handleLoginSaga);
}

function* handleLoginSaga(action) {
  try {
    const { result, error } = yield call(authenticate, action.payload);

    if (error) {
      const fieldErrors = new SubmissionError({
        _error: error
      });

      yield put(login.failure(fieldErrors));
    } else {
      const roles = result.data.roles;
      if (!Array.isArray(roles) || !roles.length) {
        yield put(login.failure());
        yield put(
          showSnackbar({
            variant: 'error',
            message:
              'Your access permission has changed, please see your system administrator.'
          })
        );
      } else {
        yield put(login.success(result));
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
  }
}

function* verifyTokenFlowSaga() {
  yield takeEvery(verifyToken, handleVerifyTokenSaga);
}

function* handleVerifyTokenSaga() {
  try {
    const { result, error } = yield call(refreshToken);

    if (error) {
      yield put(login.failure(error));
    } else {
      yield put(login.success(result));
    }
  } catch (error) {
  } finally {
  }
}

function* unauthenticatedSaga() {
  yield takeEvery(unauthenticated, handleUnauthenticatedSaga);
}

function* handleUnauthenticatedSaga() {
  const isLoggedIn = yield select(getLoggedIn);

  if (isLoggedIn) {
    yield put(login.failure());
    yield put(
      showSnackbar({
        variant: 'error',
        message: 'Your session has expired. Please sign in again to continue.'
      })
    );
  }
}

function* loginFailureSaga() {
  yield takeEvery(login.FAILURE, removeAuthInfo);
}

function* logoutFlowSaga() {
  yield takeEvery(logout, handleLogoutSaga);
}

function* handleLogoutSaga() {
  yield call(signOut);
  yield call(removeAuthInfo);
}

function* removeAuthInfo() {
  yield call(removeAuthHeaders);
}

function* invitationAcceptanceFlowSaga() {
  yield takeEvery(acceptInvitation.REQUEST, invitationAcceptance);
}

function* invitationAcceptance(action) {
  try {
    const { error } = yield call(confirmInvitation, action.payload);

    if (error) {
      const cleanedErrors = castInvitationErrors(error);

      if (cleanedErrors['_error']) {
        yield put(
          showSnackbar({ variant: 'error', message: cleanedErrors['_error'] })
        );
      } else if (cleanedErrors['roles']) {
        history.push('/login');
        yield put(
          showSnackbar({ variant: 'error', message: cleanedErrors['roles'] })
        );
      }

      const fieldErrors = new SubmissionError(cleanedErrors);
      yield put(acceptInvitation.failure(fieldErrors));
    } else {
      yield put(verifyToken());
    }
  } catch (error) {
    yield put(showSnackbar({ variant: 'error', message: error }));
  } finally {
    if (yield cancelled()) {
      // ... put special cancellation handling code here
    }
  }
}

function castInvitationErrors(error) {
  for (const [key, value] of Object.entries(error)) {
    if (key.match(/.\../g)) {
      let keys = key.split('.');
      error[keys[0]] = {};
      error[keys[0]][keys[1]] = value;
    }

    if (key.match(/invitation_token/g)) {
      error[key] = null;
      error['_error'] = 'Invalid invitation token';
    }
  }

  return error;
}

function* sagas() {
  yield fork(invitationAcceptanceFlowSaga);
  yield fork(loginFlowSaga);
  yield fork(verifyTokenFlowSaga);
  yield fork(unauthenticatedSaga);
  yield fork(loginFailureSaga);
  yield fork(logoutFlowSaga);
}

export default sagas;
