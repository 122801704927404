import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import OperatorRow from './OperatorRow';

// Material UI Components
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const OperatorsTable = ({ operators }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Badge ID</TableCell>
        <TableCell>Department</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {operators.map(operator => (
        <OperatorRow key={operator.get('id')} operator={operator} />
      ))}
    </TableBody>
  </Table>
);

OperatorsTable.propTypes = {
  operators: ImmutablePropTypes.list.isRequired
};

export default OperatorsTable;
