import React from 'react';
import PropTypes from 'prop-types';

// Components
import CenteredForm from '../../components/Auth/CenteredForm';
import ResetPasswordRequestForm from './ResetPasswordRequestForm';

// Actions
import { requestPasswordReset } from './reducer';

// MUI Components
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  helperText: {
    marginTop: theme.spacing(2)
  }
});

class ResetPasswordRequest extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <CenteredForm title="Reset Password">
        <Typography className={classes.helperText}>
          Enter your email address and we'll send you a link to reset your
          password.
        </Typography>
        <ResetPasswordRequestForm onSubmit={requestPasswordReset} />
      </CenteredForm>
    );
  }
}

ResetPasswordRequest.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ResetPasswordRequest);
