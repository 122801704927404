import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';

const styles = theme => ({
  logsTableCell: {
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    hyphens: 'auto'
  }
});

const LogsTable = ({ logs, classes }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Timestamp</TableCell>
        <TableCell>Error Code</TableCell>
        <TableCell>Error Message</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {logs.map(log => (
        <TableRow key={log.id}>
          <TableCell className={classes.logsTableCell}>
            {log.performed_at}
          </TableCell>
          <TableCell className={classes.logsTableCell}>
            {log.error_code}
          </TableCell>
          <TableCell className={classes.logsTableCell}>
            {log.error_description}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

LogsTable.propTypes = {
  logs: PropTypes.array.isRequired
};

export default withStyles(styles)(LogsTable);
