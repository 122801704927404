import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { showModal } from '../Modal/reducer';

// Components
import WordWrapTooltip from '../Tooltips/WordWrapTooltip';
import SectionHeader from '../SectionHeader';

// Material UI Components
import { withStyles } from '@material-ui/core';
import { Typography, Tooltip, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
  departmentInfo: {
    maxWidth: '50%'
  }
});

const Header = ({ classes, department, modalName, showModal }) => (
  <SectionHeader>
    <div className={classes.departmentInfo}>
      <WordWrapTooltip title={department.get('title')} enterDelay={250}>
        <Typography
          variant="h1"
          component="h1"
          className={classes.truncateNoWrap}
        >
          {department.get('title')}
        </Typography>
      </WordWrapTooltip>
    </div>
    <div className={classes.editDepartmentIcon}>
      <Tooltip title="Edit Department" enterDelay={250}>
        <IconButton
          id="edit-department"
          aria-label="Edit Department"
          onClick={() => showModal(modalName)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </div>
  </SectionHeader>
);

Header.propTypes = {
  department: PropTypes.object.isRequired,
  classes: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName))
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(Header));
