import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Actions
import { changeRole } from './reducer';

// Redux Form
import { reduxForm } from 'redux-form/immutable';

// Components
import { Field } from 'redux-form/immutable';

// Helpers
import { renderSelectField } from '../../components/Form/helpers';

// Selectors
import { isUpdating, getRoleOptions } from './selectors';

// Material UI Components
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  selectField: {
    marginTop: 0,
    minWidth: 150
  }
});

class UserRoleForm extends React.Component {
  render() {
    const { classes, updateRole, updating, roles } = this.props;

    return (
      <form>
        {updating && <CircularProgress />}
        {!updating && (
          <Field
            margin="dense"
            name="role_name"
            label=""
            classes={classes}
            component={renderSelectField}
            onChange={updateRole}
          >
            {roles.map((role, index) => (
              <MenuItem key={index} value={role.value}>
                {role.name}
              </MenuItem>
            ))}
          </Field>
        )}
      </form>
    );
  }
}

UserRoleForm.propTypes = {
  initialValues: ImmutablePropTypes.map.isRequired,
  userId: PropTypes.string.isRequired,
  facilityId: PropTypes.number.isRequired,
  form: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  updating: isUpdating(state, ownProps.userId),
  roles: getRoleOptions(state, true)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateRole: event => {
    let confirmation = true;

    if (!event.target.value) {
      confirmation = window.confirm(
        'Are you sure you wish to remove this user from the facility?'
      );
    }

    if (confirmation) {
      let roleAttributes = {
        roleName: event.target.value,
        userId: ownProps.userId,
        facilityId: ownProps.facilityId
      };
      return dispatch(changeRole(roleAttributes));
    } else {
      event.preventDefault();
    }
  }
});

export default reduxForm({})(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UserRoleForm))
);
