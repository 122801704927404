import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Components
import PaperSection from '../../PaperSection';
import UVCButton from '../../Buttons/UVCButton';

// Material UI Components
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Containers
import Operators from '../../../containers/Operators';

// Actions
import { showModal } from '../../Modal/reducer';

// Selectors
import { isUVCAdmin } from '../../../containers/Auth/selectors';

const OperatorsSection = ({ facility, showModal, isUVCAdmin }) => (
  <PaperSection
    title="Operators"
    id="operators"
    headerButton={
      isUVCAdmin && (
        <UVCButton
          text="Bulk Upload"
          id="bulk-upload-operators"
          customIcon={<CloudUploadIcon />}
          onClick={() => showModal('UploadOperatorsModal')}
        />
      )
    }
  >
    <Operators
      facilityId={facility.get('id')}
      operatorsCount={facility.get('operators').count()}
    />
  </PaperSection>
);

OperatorsSection.propTypes = {
  facility: ImmutablePropTypes.map.isRequired
};

const mapStateToProps = state => ({
  isUVCAdmin: isUVCAdmin(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName))
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsSection);
