import { handleActions, createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import { fromJS } from 'immutable';

export const constants = {
  DOWNLOAD_RUN_CYCLE_REPORT:
    'containers/RunCycleReports/DOWNLOAD_RUN_CYCLE_REPORT',
  RUN_CYCLE_REPORT_DOWNLOADED:
    'containers/RunCycleReports/RUN_CYCLE_REPORT_DOWNLOADED'
};

export const downloadRunCycleReport = createFormAction(
  constants.DOWNLOAD_RUN_CYCLE_REPORT
);

export const runCycleReportDownloaded = createAction(
  constants.RUN_CYCLE_REPORT_DOWNLOADED
);

const initialState = fromJS({
  isGenerating: false
});

const runCycleReportsReducer = handleActions(
  {
    [downloadRunCycleReport]: state => state.set('isGenerating', true),

    [runCycleReportDownloaded]: state => state.set('isGenerating', false)
  },
  initialState
);

export default runCycleReportsReducer;
