import React from 'react';
import PropTypes from 'prop-types';

// Components
import SectionHeader from '../SectionHeader';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  paperSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  headerButton: {
    marginLeft: 'auto'
  }
});

const PaperSection = ({ title, id, children, classes, headerButton }) => (
  <Paper id={id} className={classes.paperSection}>
    <SectionHeader title={title} type="h2">
      {headerButton && (
        <div className={classes.headerButton}>{headerButton}</div>
      )}
    </SectionHeader>
    {children}
  </Paper>
);

PaperSection.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  headerButton: PropTypes.node
};

export default withStyles(styles)(PaperSection);
