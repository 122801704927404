import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

// Components
import PaperSection from '../../PaperSection';

// Containers
import Departments from '../../../containers/Departments';

const DepartmentsSection = ({ facility }) => (
  <PaperSection title="Departments" id="departments">
    <Departments facilityId={facility.get('id')} />
  </PaperSection>
);

DepartmentsSection.propTypes = {
  facility: ImmutablePropTypes.map.isRequired
};

export default DepartmentsSection;
