import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Selectors
import {
  getCurrentDepartment,
  isDepartmentLoading,
  isDepartmentLoaded,
  errorLoadingDepartmentData
} from './selectors';
import { userHasSingleFacility } from '../Auth/selectors';

// Actions
import {
  fetchDepartmentRequest,
  updateDepartment,
  unloadDepartment
} from './reducer';

import { searchEquipmentItemsReset } from '../EquipmentItems/reducer';

import { showModal } from '../../components/Modal/reducer';

// Components
import { Redirect } from 'react-router';
import Modal from '../../components/Modal';
import FormDialog from '../../components/FormDialog';
import DepartmentFields from './Fields';
import CenterLoadingIcon from '../../components/CenterLoadingIcon';
import EquipmentItems from '../EquipmentItems/EquipmentItems';
import PaperSection from '../../components/PaperSection';
import UVCButton from '../../components/Buttons/UVCButton';
import EquipmentSummary from '../EquipmentItems/EquipmentSummary';
import Breadcrumbs from '../../components/Breadcrumbs';
import DepartmentHeader from '../../components/Departments/Header';

// Material UI components
import { withStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const styles = theme => ({
  root: {
    width: '100%'
  },
  headerButton: {
    marginLeft: 'auto',
    maxHeight: theme.spacing(6)
  },
  paperSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  truncateNoWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

class Department extends React.Component {
  departmentId = this.props.match.params.id;
  modalName = `EditDepartmentModal=${this.departmentId}`;

  breadcrumbItems() {
    let department = this.props.department;

    let items = [];

    if (!this.props.singleFacility) {
      items.push({ text: 'My Facilities', to: '/my_facilities' });
    }

    items.push(
      {
        text: department.get('facility_name'),
        to: `/facilities/${department.get('facility_id')}`
      },
      { text: department.get('title'), to: `#` }
    );

    return items;
  }

  componentWillMount() {
    this.props.fetchDepartment(this.departmentId);
  }

  componentWillUnmount() {
    this.props.unloadDepartment();
    this.props.unloadSearchResults();
  }

  render() {
    const {
      department,
      loading,
      loaded,
      classes,
      showModal,
      loadingError
    } = this.props;

    return (
      <div className={classes.root}>
        {loading && <CenterLoadingIcon />}
        {loadingError && <Redirect to="/" />}
        {loaded && department && (
          <React.Fragment>
            <Modal
              name={this.modalName}
              render={() => (
                <FormDialog
                  title="Edit Department"
                  buttonText="Save"
                  form={'editDepartmentForm'}
                  initialValues={department}
                  onSubmit={updateDepartment}
                >
                  <DepartmentFields />
                </FormDialog>
              )}
            />
            <Breadcrumbs items={this.breadcrumbItems()} />
            <DepartmentHeader
              department={department}
              modalName={this.modalName}
            />
            <PaperSection title="Equipment Summary" id="equipment-summary">
              <EquipmentSummary />
            </PaperSection>
            <PaperSection
              title="Equipment Items"
              id="equipment-items"
              headerButton={
                <div className={classes.headerButton}>
                  <UVCButton
                    text="Bulk Upload"
                    id="bulk-upload-equipment-items"
                    customIcon={<CloudUploadIcon />}
                    onClick={() => showModal('UploadEquipmentModal')}
                  />
                </div>
              }
            >
              <EquipmentItems />
            </PaperSection>
          </React.Fragment>
        )}
      </div>
    );
  }
}

Department.propTypes = {
  department: ImmutablePropTypes.map,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  department: getCurrentDepartment(state),
  loading: isDepartmentLoading(state),
  loaded: isDepartmentLoaded(state),
  loadingError: errorLoadingDepartmentData(state),
  singleFacility: userHasSingleFacility(state)
});

const mapDispatchToProps = dispatch => ({
  showModal: modalName => dispatch(showModal(modalName)),
  fetchDepartment: departmentId =>
    dispatch(fetchDepartmentRequest(departmentId)),
  unloadDepartment: () => dispatch(unloadDepartment()),
  unloadSearchResults: () => dispatch(searchEquipmentItemsReset())
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Department)
);
