import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// Selectors
import { getProtocolIdsForCurrentDepartment } from '../Protocols/selectors';

// Components
import ProtocolSummaryRow from '../Protocols/SummaryRow';

// Material UI Components
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const EquipmentSummary = ({ protocolIds }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Equipment Type</TableCell>
        <TableCell align="center">Total Item Count</TableCell>
        <TableCell align="center">Cycle Frequency (Days)</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {protocolIds.map(protocolId => (
        <ProtocolSummaryRow
          key={protocolId}
          protocolId={protocolId.toString()}
        />
      ))}
    </TableBody>
  </Table>
);

EquipmentSummary.propTypes = {
  protocolIds: ImmutablePropTypes.list.isRequired
};

const mapStateToProps = state => ({
  protocolIds: getProtocolIdsForCurrentDepartment(state)
});

export default connect(mapStateToProps)(EquipmentSummary);
