import { fromJS } from 'immutable';
import fetchData from '../../services/apiRequest';
import { showSnackbar } from '../../components/CustomSnackbar/reducer';
import { createAction, handleActions } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';

// Actions
export const constants = {
  INVITE_USER: 'containers/Users/INVITE_USER',
  CHANGE_ROLE: 'containers/Users/CHANGE_ROLE',
  CHANGE_ROLE_SUCCESS: 'containers/Users/CHANGE_ROLE_SUCCESS'
};

export const inviteUser = createFormAction(constants.INVITE_USER);
export const changeRole = createAction(constants.CHANGE_ROLE);
export const changeRoleSuccess = createAction(constants.CHANGE_ROLE_SUCCESS);

export const resendInvitation = (userId, facilityId) => dispatch => {
  return fetchData(`resend_invitations`, {
    method: 'POST',
    body: {
      user_id: userId,
      facility_id: facilityId
    }
  }).then(({ result, error }) => {
    if (error) {
      dispatch(
        showSnackbar({
          variant: 'error',
          message: `Could not send user invitation: ${error}`
        })
      );
    } else {
      dispatch(
        showSnackbar({ variant: 'success', message: 'User invitation resent.' })
      );
    }
  });
};

const initialState = fromJS({
  updating: []
});

const usersReducer = handleActions(
  {
    [changeRole]: (state, action) => {
      let updateList = state.get('updating').push(action.payload.userId);
      let uniqueUpdates = [...new Set(updateList)];

      return state.set('updating', uniqueUpdates);
    },

    [changeRoleSuccess]: (state, action) => {
      let updateList = state.get('updating');
      updateList.splice(updateList.indexOf(action.payload.id.toString()), 1);

      return state.set('updating', fromJS(updateList));
    }
  },
  initialState
);

export default usersReducer;
