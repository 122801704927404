import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  success: {
    backgroundColor: theme.palette.success.main
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  close: {
    padding: theme.spacing(0.5)
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
});

class CustomSnackbar extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.closeSnackbar();
  };

  render() {
    const { isOpen, classes, message, variant } = this.props;
    const Icon = variantIcon[variant];

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className={clsx(classes[variant])}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        ></SnackbarContent>
      </Snackbar>
    );
  }
}

CustomSnackbar.defaultProps = {
  variant: 'info'
};

CustomSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.node,
  variant: PropTypes.oneOf(['success', 'error', 'info']).isRequired,
  closeSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(CustomSnackbar);
